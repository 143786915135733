<div class="flex items-center mb-4">
  <div class="flex items-center">
    <button
      type="button"
      (click)="toggleAuto()"
      class="relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out bg-gray-400 border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200"
      aria-pressed="false"
      [class.bg-green-500]="auto"
    >
      <span class="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        class="inline-block w-5 h-5 transition duration-200 ease-in-out transform translate-x-0 bg-white rounded-full shadow pointer-events-none ring-0"
        [class.translate-x-5]="auto"
      ></span>
    </button>
  </div>
  <span class="flex flex-col ml-3">
    <span class="text-sm text-ni-blue-600">{{
      auto ? "Automatic" : "Custom"
    }}</span>
  </span>
</div>

<div [hidden]="auto">
  <div
    class="grid grid-cols-3 grid-rows-3 text-center bg-gray-500 rounded-md bg-opacity-5"
  >
    <div class="col-span-3 text-sm">
      <label class="block w-full h-full p-4"
        ><input type="radio" name="val" [formControl]="control" value="top" />
        Above</label
      >
    </div>
    <div class="text-sm">
      <label class="block w-full h-full p-4"
        ><input type="radio" name="val" [formControl]="control" value="left" />
        Left</label
      >
    </div>
    <div class="bg-gray-500 rounded-md bg-opacity-10"></div>
    <div class="text-sm">
      <label class="block w-full h-full p-4"
        ><input type="radio" name="val" [formControl]="control" value="right" />
        Right</label
      >
    </div>
    <div class="col-span-3 text-sm">
      <label class="block w-full h-full p-4"
        ><input
          type="radio"
          name="val"
          [formControl]="control"
          value="bottom"
        />
        Below</label
      >
    </div>
  </div>
</div>
