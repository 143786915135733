import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "app-dropdown",
  templateUrl: "./dropdown.component.html",
  animations: [
    trigger("openClose", [
      transition("void => *", [
        animate(
          250,
          keyframes([
            style({ opacity: 0, transform: "scale(0.8)", offset: 0 }),
            style({ opacity: 0.5, transform: "scale(1.05)", offset: 0.5 }),
            style({ opacity: 1, transform: "scale(1)", offset: 1 }),
          ])
        ),
      ]),
      transition("* => void", [
        animate(
          100,
          keyframes([
            style({ opacity: 1, offset: 0 }),
            style({ opacity: 0, offset: 1 }),
          ])
        ),
      ]),
    ]),
  ],
})
export class DropdownComponent implements OnInit {
  expanded = false;
  @Input() expandDirection = "left";
  @ViewChild("trigger") trigger: ElementRef | null = null;

  constructor() {}

  ngOnInit(): void {}

  toggle() {
    this.expanded = !this.expanded;
  }

  @HostListener("document:click", ["$event.target"])
  public onClick(targetElement: HTMLElement) {
    if (!this.trigger) return;
    const clickedTrigger = this.trigger.nativeElement.contains(targetElement);
    if (!clickedTrigger) {
      this.expanded = false;
    }
  }
}
