import { Injectable } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { filter, map, startWith } from "rxjs/operators";
import { LogoService } from "./logo.service";

@Injectable({
  providedIn: "root",
})
export class PageDetailsService {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private logoService: LogoService
  ) {}

  isOmniplexGuide = this.logoService.isOmniplexGuide();

  details$ = new BehaviorSubject<any>({
    currentUrl: ["/"],
    breadcrumbs: [],
    tabs: [],
    title: "",
  });

  pathFromRoot$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    startWith(true),
    map(() => {
      let route = this.route;

      while (route.firstChild) {
        route = route.firstChild;
      }

      return route.pathFromRoot;
    })
  );

  updateDataSubscription = this.pathFromRoot$
    .pipe(
      map((activatedRoutes: any) => {
        return activatedRoutes.reduce(
          (acc, route) => {
            let data = { ...acc };

            const url = route.url?.value;
            const title = route.data?.value?.["title"];
            const tab = route.data?.value?.["tab"];

            if (url) {
              const segments = url
                .map((segment) => segment.path)
                .filter((x) => !!x);
              data.currentUrl.push(...segments);
            }

            if (title) {
              data.title = title;
            }

            if (title && !tab) {
              data.breadcrumbs.push({
                title: title,
                routerLink: [...data.currentUrl],
              });
            }

            if (route?.routeConfig?.children?.length) {
              const tabs = route.routeConfig.children
                .filter(
                  (child) => child?.data?.["tab"] && child?.data?.["title"]
                )
                .filter(
                  (child) => {
                    const showForGuide = child?.data?.["showForOmniplexGuide"]??null;
                    if (showForGuide === null) return true;
                    return showForGuide === this.isOmniplexGuide;
                  }
                )
                .map((child) => ({
                  title: child.data["title"],
                  routerLink: [...data.currentUrl, child.path],
                }));
              data.tabs.push(...tabs);
            }

            return data;
          },
          {
            currentUrl: ["/"],
            breadcrumbs: [],
            tabs: [],
            title: "",
          }
        );
      })
    )
    .subscribe((data) => {
      this.details$.next(data);
    });
}
