<div class="mt-1 -space-y-px bg-white rounded-md shadow-sm">
  <div>
    <select
      [(ngModel)]="mode"
      class="relative block w-full bg-transparent rounded-none rounded-t-md focus:z-10 sm:text-sm focus:outline-none focus:ring-ni2-orange-400 focus:border-ni2-orange-400"
      [class]="getInputStyles()"
    >
      <option value="text">Look for text</option>
      <option value="text-pattern">Look for text pattern</option>
      <option value="css">CSS (advanced)</option>
    </select>
  </div>
  <div [formGroup]="formGroup">
    <div [hidden]="mode !== 'text'">
      <input
        type="text"
        formControlName="textContains"
        class="relative block w-full -mt-px bg-transparent rounded-none rounded-b-md focus:z-10 sm:text-sm focus:outline-none focus:ring-ni2-orange-400 focus:border-ni2-orange-400"
        [class]="getInputStyles()"
        placeholder="Text to match"
      />
    </div>
    <div [hidden]="mode !== 'text-pattern'">
      <input
        type="text"
        formControlName="textPatternBefore"
        class="relative block w-full -mt-px bg-transparent rounded-none focus:z-10 sm:text-sm focus:outline-none focus:ring-ni2-orange-400 focus:border-ni2-orange-400"
        [class]="getInputStyles()"
        placeholder="Text before"
      />
      <input
        type="text"
        formControlName="textPatternContains"
        class="relative block w-full -mt-px bg-transparent rounded-none focus:z-10 sm:text-sm focus:outline-none focus:ring-ni2-orange-400 focus:border-ni2-orange-400"
        [class]="getInputStyles()"
        placeholder="Text to match"
      />
      <input
        type="text"
        formControlName="textPatternAfter"
        class="relative block w-full -mt-px bg-transparent rounded-none rounded-b-md focus:z-10 sm:text-sm focus:outline-none focus:ring-ni2-orange-400 focus:border-ni2-orange-400"
        [class]="getInputStyles()"
        placeholder="Text after"
      />
    </div>
    <div [hidden]="mode !== 'css'">
      <input
        type="text"
        formControlName="cssSelector"
        class="relative block w-full -mt-px bg-transparent rounded-none rounded-b-md focus:z-10 sm:text-sm focus:outline-none focus:ring-ni2-orange-400 focus:border-ni2-orange-400"
        [class]="getInputStyles()"
        placeholder="Selector"
      />
    </div>
  </div>
</div>
<div *ngIf="mode === 'css'">
  <p class="mt-2 text-sm text-ni-blue-700">
    Learn how to use CSS selectors in our
    <a
      href="https://help.nickelled.com/en/articles/5045483-using-css-selectors"
      class="font-medium"
      target="_BLANK"
      >Help Center</a
    >.
  </p>
</div>
