import { portalPageChanged } from "@actions/portal.actions";
import {
  AfterViewInit,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import {
  selectActiveFlow,
  selectActiveTemplatePreview,
  selectPortalHasAuthentication,
} from "@selectors/flow.selectors";
import { BehaviorSubject, combineLatest, Subject } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { AppState } from "src/app/store/reducers";
import { environment } from "../../../../environments/environment";
import { PortalService } from "../../services/portal.service";

type BasicAuthCredentials = { username: string; password: string } | null;

declare var Portal: any;

@Component({
  selector: "app-portal",
  templateUrl: "./portal.component.html",
})
export class PortalComponent implements AfterViewInit, OnDestroy {
  @ViewChild("el") el: ElementRef;
  portal: any = null;
  destroy = new Subject();
  loading$ = new BehaviorSubject<boolean>(true);
  hasAuthentication$ = this.store.select(selectPortalHasAuthentication);
  panel$ = combineLatest([this.loading$, this.hasAuthentication$]).pipe(
    map(([loading, auth]) => {
      if (!auth) return "auth";
      if (loading) return "loading";
      return null;
    })
  );
  private currentUrl: string | null = null;
  private steps: any = [];
  private position: number = -1;

  constructor(
    public zone: NgZone,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private portalService: PortalService
  ) {}

  ngAfterViewInit() {
    combineLatest([this.store.select(selectActiveFlow)])
      .pipe(
        map(([flow]) => flow?.startingUrl),
        distinctUntilChanged()
      )
      .subscribe((url) => {
        if (url?.includes("*")) {
          url = prompt(
            "Please confirm the URL where the preview should start",
            ""
          );
        }

        const customUrl = this.route.snapshot.queryParams["url"];
        this.navigate(customUrl || url, null);
      });

    this.store.select(selectActiveTemplatePreview).subscribe((step) => {
      if (step?.preview) {
        this.preview([step.preview], 0);
      } else {
        this.preview();
      }
    });

    // this.portalService.requestPageSave
    //   .pipe(concatMap(() => this.save()))
    //   .subscribe((page) => this.portalService.gotPageSave.next(page));
  }

  init(basicAuth: BasicAuthCredentials = null): void {
    const portalId = `portal_${Math.random().toString(36).substr(2, 9)}`;
    this.el.nativeElement.id = portalId;

    if (this.portal !== null) {
      this.el.nativeElement.innerHTML = "";
      this.portal?.close();
    }

    if (typeof Portal === "undefined") {
      console.error("Unable to initialise portal (global object missing)");
      return null;
    }

    this.portal = new Portal({
      target: `#${portalId}`,
      selectable: false,
      editable: false,
      proxy: true,
      proxyCookies: true,
      background: true,
      assetPrefix: 1,
      fastSave: true,
      forceNewSocket: true,
      interactiveComponents: [
        "data-nickelled-control",
        "data-nickelled-component",
      ],
      onError: (err) => {
        console.error(err);
      },
      authentication: basicAuth ?? undefined,
    });

    this.portalService.init(this.portal);

    this.portal?.pageReady(() => {
      this.zone.run(() => {
        this.loading$.next(false);
        this.portal?.pageDetails(({ url, title }) =>
          this.handlePageChange(url, title)
        );
      });
    });

    this.portal?.pageChanging(() => {
      this.zone.run(() => {
        this.portal?.pageDetails(({ url, title }) =>
          this.handlePageChange(url, title)
        );
      });
    });

    this.portal?.beforeChange((changes) => {
      this.zone.run(() => {
        // this.store.dispatch(portalDomMutation({ changes }));
        this.portal?.pageDetails(({ url, title }) =>
          this.handlePageChange(url, title)
        );
      });
    });
  }

  navigate(url: string = null, basicAuth: BasicAuthCredentials = null) {
    this.loading$.next(true);

    if (!this.portal) {
      this.init(basicAuth);
      this.portal?.open(url);
    } else {
      this.portal?.goTo(url);
    }

    this.portal?.set("NickelledLaunchers", {
      userData: { appId: environment.portal.appId },
    });
    this.portal?.addJSFile(environment.launchers.js);
  }

  preview(steps: any[] = [], position = 0) {
    this.steps = steps;
    this.position = position;
    // this.store.dispatch(portalPositionChanged({ position }));
    this.injectState();
  }

  async save() {
    return new Promise((resolve, reject) => {
      this.portal?.save(
        (page) => resolve(page),
        (err) => reject(err)
      );
    });
  }

  private handlePageChange(url: string, title: string) {
    if (this.currentUrl === url) return;
    this.portalService.handlePageChange({ url, title });
    this.currentUrl = url;

    this.zone.run(() => {
      this.store.dispatch(portalPageChanged({ url, title }));
      this.injectState();
    });
  }

  private injectState() {
    const launchers = this.portal?.el?.contentWindow?.NickelledLaunchers;

    if (!launchers || typeof launchers.loadForDebugging !== "function") {
      return;
    }

    launchers.loadForDebugging({
      themes: {},
      userData: { pageUrl: this.currentUrl },
      flows: { myFlow: { steps: this.steps } },
      runState:
        this.position < this.steps.length
          ? { myFlow: { position: this.position } }
          : {},
      clear: true,
    });

    if (!this.portal?.listening) {
      if (this.portal) {
        this.portal.listening = true;
      }
    }
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
    this.store.dispatch(portalPageChanged({ url: null, title: null }));
    this.portalService.destroy();
    this.portal = null;
  }
}
