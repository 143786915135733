import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PreviewFlowStepComponent } from "./components/preview-flow-step/preview-flow-step.component";
import { PreviewStepComponent } from "./components/preview-step/preview-step.component";

@NgModule({
  declarations: [PreviewStepComponent, PreviewFlowStepComponent],
  imports: [CommonModule],
  exports: [PreviewStepComponent, PreviewFlowStepComponent],
})
export class PreviewModule {}
