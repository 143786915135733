import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "@reducers/.";
import { selectCategoryHasMultipleResources } from "@selectors/flows.selectors";
import { map } from "rxjs/operators";

@Component({
  selector: "app-resource-layout",
  templateUrl: "./resource-layout.component.html",
  styleUrls: ["./resource-layout.component.scss"],
})
export class ResourceLayoutComponent implements OnInit {
  constructor(private store: Store<AppState>) {}

  data$ = this.store
    .select(selectCategoryHasMultipleResources)
    .pipe(map((showResourceList) => ({ showResourceList })));

  ngOnInit(): void {}
}
