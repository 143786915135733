import { Component, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-toggle",
  templateUrl: "./toggle.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleComponent),
      multi: true,
    },
  ],
})
export class ToggleComponent implements ControlValueAccessor {
  public isChecked: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  public onChange: any = () => {};
  public onTouch: any = () => {};

  public writeValue(value: any): void {
    if (value !== this.isChecked) {
      this.isChecked = value;
      this.onChange(value);
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public toggleChecked() {
    this.writeValue(!this.isChecked);
  }
}
