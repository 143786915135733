import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";

export const selectExtension = (state: AppState) => state.extension;

export const selectExtensionData = createSelector(
  selectExtension,
  (state) => state
);

export const selectExtensionDetectedStatus = createSelector(
  selectExtension,
  (state) => state.detectedStatus
);
