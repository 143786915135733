import { checkStatus } from "@actions/extension.actions";
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { AppState } from "@reducers/.";
import { EventTrackingService } from "./shared/service/event-tracking.service";
import { LogoService } from "./shared/service/logo.service";
import { PageDetailsService } from "./shared/service/page-details.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    private eventTrackingService: EventTrackingService,
    private store: Store<AppState>,
    private pageDetailsService: PageDetailsService,
    private title: Title,
    private logoService: LogoService
  ) {}

  updateTitleSubscription = this.pageDetailsService.details$.subscribe(
    ({ title }) => {
      const tenantName = this.logoService.getTenantName();

      if (title) {
        this.title.setTitle(`${title} | ${tenantName} Dashboard`);
      } else {
        this.title.setTitle(`${tenantName} Dashboard`);
      }
    }
  );

  ngOnInit(): void {
    this.eventTrackingService.init();
    this.store.dispatch(checkStatus());
  }
}
