import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { FlowService } from "./flow.service";
import { HttpService } from "./http.service";

export type ResourceSummary = {
  id: string;
  name: string;
  isPublished: boolean;
  templateName: string;
};

@Injectable({
  providedIn: "root",
})
export class ResourceService {
  constructor(
    private httpService: HttpService,
    private flowService: FlowService
  ) {}

  list(siteId: number = null, type: string = null, includePublic = false) {
    const params = siteId
      ? { siteId, type, includePublic: includePublic.toString() }
      : {};

    const url = `${environment.apiHost}/v1/resource`;
    return this.httpService.get(url, {
      withCredentials: true,
      params,
    });
  }

  listByCategory(
    category: string,
    siteId = null
  ): Observable<ResourceSummary[]> {
    const params = !!siteId ? { siteId } : {};

    const url = `${environment.apiHost}/v1/resource/category/${category}`;
    return this.httpService.get(url, {
      withCredentials: true,
      params,
    });
  }

  get(id: string) {
    const url = `${environment.apiHost}/v1/resource/${id}`;
    return this.httpService.get(url, {
      withCredentials: true,
    });
  }

  update(id: string, data: any) {
    const url = `${environment.apiHost}/v1/resource/${id}`;
    return this.httpService.put(url, data, {
      withCredentials: true,
    });
  }

  create(data: any) {
    const url = `${environment.apiHost}/v1/resource`;
    return this.httpService.post(url, data, {
      withCredentials: true,
    });
  }

  delete(id: string) {
    const idAsNumber = Number(id);

    if (!Number.isNaN(idAsNumber)) {
      return this.flowService.delete(id as any);
    }

    const url = `${environment.apiHost}/v1/resource/${id}`;
    return this.httpService.del(url, {
      withCredentials: true,
    });
  }

  duplicate(id: string) {
    const idAsNumber = Number(id);

    if (!Number.isNaN(idAsNumber)) {
      return this.flowService.copy(id as any);
    }

    const url = `${environment.apiHost}/v1/resource/clone`;
    return this.httpService.post(
      url,
      { id },
      {
        withCredentials: true,
      }
    );
  }
}
