import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { concatLatestFrom } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { AppState } from "@reducers/.";
import { selectExtensionDetectedStatus } from "@selectors/extension.selectors";
import { selectFlag } from "@selectors/flags.selectors";
import { of, race, Subject } from "rxjs";
import { concatMap, delay, filter, map, take, tap } from "rxjs/operators";
import { ExtensionBusService } from "src/app/shared/service/extension-bus.service";
import { FlashService } from "src/app/shared/service/flash.service";
import { MessageChannelService } from "src/app/shared/services/message-channel.service";

@Injectable({
  providedIn: "root",
})
export class GuideLauncherService {
  constructor(
    private messageChannelService: MessageChannelService,
    private extensionBusService: ExtensionBusService,
    private flashService: FlashService,
    private store: Store<AppState>,
    private router: Router
  ) {}

  childWindowReady$ = this.messageChannelService.rx$.pipe(
    filter((data: any) => data.event === "NICKELLED_READY")
  );

  hasExtensionPreview$ = this.store.select(
    selectFlag("extension-based-preview")
  );

  triggerPreview$ = new Subject<{
    guideId: number;
    appId: string;
    url: string;
  }>();

  triggerPreviewWithMode$ = this.triggerPreview$.pipe(
    concatLatestFrom(() => this.hasExtensionPreview$),
    map(([data, hasExtensionPreview]) => ({ ...data, hasExtensionPreview }))
  );

  launchPreviewWithoutExtension$ = this.triggerPreviewWithMode$
    .pipe(filter((data) => !data.hasExtensionPreview))
    .subscribe(({ guideId, url }) => {
      let winRef: Window = null;

      const sentMessage$ = this.childWindowReady$.pipe(
        take(1),
        tap(() => {
          winRef.postMessage(
            {
              event: "NICKELLED_LAUNCH_FLOW",
              payload: `${guideId}_preview`,
            },
            "*"
          );
        }),
        map(() => true)
      );

      race(sentMessage$, of(false).pipe(delay(5000))).subscribe((success) => {
        if (!success) {
          winRef?.close();
          this.flashService.set({
            title: "Failed to play guide",
            description:
              "Please ensure the destination site has the JavaScript Snippet installed.",
            status: "failure",
          });
        }
      });

      winRef = window.open(url, "NICKELLED_PREVIEW");
      winRef.focus();
    });

  launchPreviewWithExtension$ = this.triggerPreviewWithMode$
    .pipe(
      filter((data) => data.hasExtensionPreview),
      concatLatestFrom(() => this.store.select(selectExtensionDetectedStatus)),
      tap(([data, status]) => {
        if (status.reachable) return;

        this.router.navigate(["/setup"], {
          queryParams: { redirectTo: "guides", id: data.guideId },
        });
      }),
      concatMap(([data, status]) => {
        if (!status.reachable) return of(null);

        return this.extensionBusService.launchGuide(data as any);
      })
    )
    .subscribe();

  previewFlow(guideId: number, appId: string, url: string) {
    this.triggerPreview$.next({ guideId, appId, url });
  }
}
