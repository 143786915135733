import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class UserPreferenceService {
  constructor() {}

  getSiteId() {
    const storageValue = window.localStorage.getItem("siteId");

    if (storageValue) {
      return Number(storageValue);
    }

    return null;
  }

  setSiteId(id: number) {
    window.localStorage.setItem("siteId", id.toString());
  }

  getLastUrl() {
    return window.localStorage.getItem("lastUrl");
  }

  setLastUrl(url: string) {
    window.localStorage.setItem("lastUrl", url);
  }
}
