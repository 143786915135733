<div class="flex items-center">
  <button
    type="button"
    (click)="toggleChecked()"
    class="relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out bg-gray-400 border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200"
    aria-pressed="false"
    [class.bg-green-500]="isChecked"
  >
    <span class="sr-only">Use setting</span>
    <span
      aria-hidden="true"
      class="inline-block w-5 h-5 transition duration-200 ease-in-out transform translate-x-0 bg-white rounded-full shadow pointer-events-none ring-0"
      [class.translate-x-5]="isChecked"
    ></span>
  </button>
</div>
