import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SafeUrl } from "@angular/platform-browser";

export type FieldGalleryComponentOption = {
  id: string;
  label: string;
  imageUrl?: string | SafeUrl;
  badge?: string;
  description?: string;
  hideRadioControl?: true;
};

@Component({
  selector: "app-field-gallery",
  templateUrl: "./field-gallery.component.html",
  styleUrls: ["./field-gallery.component.scss"],
})
export class FieldGalleryComponent implements OnInit {
  @Input() value = "";
  @Input() options: FieldGalleryComponentOption[] = [];
  @Input() cols = 5;
  @Input() name = "no-name";
  @Input() showRadioControls = true;
  @Output() update = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  handleChange(id: string) {
    this.value = id;
    this.update.emit(id);
  }
}
