import * as AdminActions from "@actions/admin.actions";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { AppState } from "@reducers/.";
import {
  selectPaginatedCompanies,
  selectPaginatedUsers,
} from "@selectors/admin.selectors";
import { concatMap, exhaustMap, map, tap } from "rxjs/operators";
import { AdminService } from "src/app/shared/service/admin.service";

@Injectable()
export class AdminEffects {
  loadCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.loadCompanies),
      concatMap(() => this.store.select(selectPaginatedCompanies)),
      exhaustMap(({ searchQuery, offset }) =>
        this.adminService.getCompanies(searchQuery, offset).pipe(
          map(({ paginatedRows, totalResults, fromPosition, toPosition }) => {
            return AdminActions.loadCompaniesSuccess({
              paginatedRows,
              totalResults,
              fromPosition,
              toPosition,
            });
          })
        )
      )
    )
  );

  loadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.loadUsers),
      concatMap(() => this.store.select(selectPaginatedUsers)),
      exhaustMap(({ searchQuery, offset }) =>
        this.adminService.getUsers(searchQuery, offset).pipe(
          map(({ paginatedRows, totalResults, fromPosition, toPosition }) => {
            return AdminActions.loadUsersSuccess({
              paginatedRows,
              totalResults,
              fromPosition,
              toPosition,
            });
          })
        )
      )
    )
  );

  impersonateCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.impersonateCompany),
      exhaustMap(({ companyId }) =>
        this.adminService.impersonateCompany(companyId).pipe(
          map(() => {
            return AdminActions.impersonateCompanySuccess();
          })
        )
      ),
      tap(() => window.location.reload())
    )
  );

  cancelSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.cancelSubscription),
      exhaustMap(({ companyId }) =>
        this.adminService.cancelSubscription(companyId).pipe(
          map(() => {
            return AdminActions.cancelSubscriptionSuccess();
          })
        )
      )
    )
  );

  loadCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.loadCompany, AdminActions.saveCompanySuccess),
      exhaustMap(({ companyId }) =>
        this.adminService.getCompany(companyId).pipe(
          map((data) => {
            return AdminActions.loadCompanySuccess({ data });
          })
        )
      )
    )
  );

  saveCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.saveCompany),
      exhaustMap(({ companyId, data }) =>
        this.adminService.updateCompany(companyId, data).pipe(
          map((data) => {
            return AdminActions.saveCompanySuccess({ companyId, data });
          })
        )
      )
    )
  );

  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.loadUser, AdminActions.saveUserSuccess),
      exhaustMap(({ userId }) =>
        this.adminService.getUser(userId).pipe(
          map((data) => {
            return AdminActions.loadUserSuccess({ data });
          })
        )
      )
    )
  );

  saveUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.saveUser),
      exhaustMap(({ userId, data }) =>
        this.adminService.updateUser(userId, data).pipe(
          map((data) => {
            return AdminActions.saveUserSuccess({ userId, data });
          })
        )
      )
    )
  );

  loadGuide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.loadGuide, AdminActions.saveGuideSuccess),
      exhaustMap(({ guideId }) =>
        this.adminService.getGuide(guideId).pipe(
          map((data) => {
            return AdminActions.loadGuideSuccess({ data });
          })
        )
      )
    )
  );

  saveGuide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.saveGuide),
      exhaustMap(({ guideId, data }) =>
        this.adminService.updateGuide(guideId, data).pipe(
          map((data) => {
            return AdminActions.saveGuideSuccess({ guideId, data });
          })
        )
      )
    )
  );

  loadSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.loadSite, AdminActions.saveSiteSuccess),
      exhaustMap(({ siteId }) =>
        this.adminService.getSite(siteId).pipe(
          map((data) => {
            return AdminActions.loadSiteSuccess({ data });
          })
        )
      )
    )
  );

  saveSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.saveSite),
      exhaustMap(({ siteId, data }) =>
        this.adminService.updateSite(siteId, data).pipe(
          map((data) => {
            return AdminActions.saveSiteSuccess({ siteId, data });
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private adminService: AdminService,
    private store: Store<AppState>
  ) {}
}
