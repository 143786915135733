import * as AuthApiActions from "@actions/auth-api.actions";
import * as ExtensionActions from "@actions/extension.actions";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, concatMap, map, take, tap, timeout } from "rxjs/operators";
import { ExtensionBusService } from "src/app/shared/service/extension-bus.service";
import { FlashService } from "src/app/shared/service/flash.service";

@Injectable()
export class ExtensionEffects {
  checkStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExtensionActions.checkStatus),
      concatMap(
        (): Observable<Action> =>
          this.extensionBusService.getStatus().pipe(
            map((status) =>
              ExtensionActions.checkStatusSuccess({
                version: status.version,
              })
            ),
            timeout(2500),
            catchError(() => of(ExtensionActions.checkStatusTimeout())),
            take(1)
          )
      )
    )
  );

  forceSync$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.userLoadedSuccess, ExtensionActions.forceSync),
      concatMap(
        (): Observable<Action> =>
          this.extensionBusService.forceSync().pipe(
            map(() => ExtensionActions.forceSyncSuccess()),
            timeout(2500),
            catchError(() => of(ExtensionActions.forceSyncTimeout())),
            take(1)
          )
      )
    )
  );

  launchGuide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExtensionActions.launchGuide),
      concatMap(({ id, edit }): Observable<Action> => {
        return this.extensionBusService.launchGuide({ guideId: id, edit }).pipe(
          map(() => ExtensionActions.launchGuideSuccess()),
          timeout(2500),
          catchError(() => {
            this.flashService.set({
              title: "Failed to launch guide",
              description: "Please try again...",
              status: "failure",
            });

            return of(ExtensionActions.launchGuideTimeout());
          }),
          take(1)
        );
      })
    )
  );

  launchGuideTimeout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ExtensionActions.launchGuideTimeout),
        tap(() => this.router.navigate(["/setup"]))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private extensionBusService: ExtensionBusService,
    private flashService: FlashService,
    private router: Router
  ) {}
}
