import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SelectMenuItem } from "src/app/shared/components/select-menu/select-menu.component";

type FieldEnumComponentOption = { id: string; label: string };

@Component({
  selector: "app-field-enum",
  templateUrl: "./field-enum.component.html",
  styleUrls: ["./field-enum.component.scss"],
})
export class FieldEnumComponent implements OnInit {
  @Input() value = "";
  @Input() set options(value: FieldEnumComponentOption[]) {
    this.selectMenuOptions = value.map(({ id, label }) => ({
      id,
      title: label,
    }));
  }
  @Output() update = new EventEmitter<string>();

  selectMenuOptions: SelectMenuItem[] = [];

  constructor() {}

  ngOnInit(): void {}

  handleChange(id: any) {
    this.update.emit(id);
  }
}
