import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-tags",
  templateUrl: "./tags.component.html",
  styleUrls: ["./tags.component.scss"],
})
export class TagsComponent implements OnInit {
  @Input() value: string[] = [];
  @Input() list: string[] = [];
  @Output() update = new EventEmitter<string[]>();

  inputOpen = false;

  get tags() {
    return Array.isArray(this.value) ? this.value : [];
  }

  get tagsForDatalist() {
    return this.list.filter((tag) => this.value.indexOf(tag) === -1);
  }

  constructor() {}

  ngOnInit(): void {}

  setInputOpenState(inputOpen: boolean) {
    this.inputOpen = inputOpen;
  }

  handleAdd(value: string) {
    if (value) {
      this.updateValue([...this.tags, value]);
    }
    this.setInputOpenState(false);
  }

  handleRemove(value: string) {
    const newValue = this.value.filter((tag) => tag !== value);
    this.updateValue(newValue);
    this.setInputOpenState(false);
  }

  private updateValue(value: string[]) {
    const uniqueItems = [...new Set(value)];
    this.value = uniqueItems;
    this.update.emit(uniqueItems);
  }
}
