import * as SettingsApiActions from "@actions/settings-api.actions";
import { createReducer, on } from "@ngrx/store";

export type State = {
  teamMembers: {
    data: any[];
    loading: boolean;
    error: string | null;
  };
  invitations: {
    data: any[];
    loading: boolean;
    error: string | null;
  };
  payment: {
    loading: boolean;
    error: string | null;
    ccLast4: string;
    ccExpiryMonth: string;
    ccExpiryYear: string;
  };
  invoices: {
    loading: boolean;
    error: string | null;
    data: any[];
  };
};

export const initialState: State = {
  teamMembers: {
    data: null,
    loading: true,
    error: null,
  },
  invitations: {
    data: null,
    loading: true,
    error: null,
  },
  payment: {
    loading: true,
    error: null,
    ccLast4: null,
    ccExpiryMonth: null,
    ccExpiryYear: null,
  },
  invoices: {
    loading: true,
    error: null,
    data: [],
  },
};

const _settinsReducer = createReducer(
  initialState,
  on(
    SettingsApiActions.teamMembersLoadedSuccess,
    (state, { teamMembers, invitations }): State => {
      return {
        ...state,
        teamMembers: {
          data: teamMembers,
          loading: false,
          error: null,
        },
        invitations: {
          data: invitations,
          loading: false,
          error: null,
        },
      };
    }
  ),
  on(
    SettingsApiActions.updatePaymentSettingsSuccess,
    SettingsApiActions.loadPaymentSettingsSuccess,
    (state, { ccLast4, ccExpiryMonth, ccExpiryYear }): State => {
      return {
        ...state,
        payment: {
          ccLast4,
          ccExpiryMonth,
          ccExpiryYear,
          loading: false,
          error: null,
        },
      };
    }
  ),
  on(
    SettingsApiActions.loadInvoiceListSuccess,
    (state, { invoices }): State => {
      return {
        ...state,
        invoices: {
          loading: false,
          error: null,
          data: invoices,
        },
      };
    }
  ),
  on(SettingsApiActions.setRolesSuccess, (state, { id, roleNames }): State => {
    const data = state.teamMembers.data.slice();
    const idx = data.findIndex((member) => member.id === id);

    if (idx >= 0) data[idx] = { ...data[idx], roles: roleNames };

    return {
      ...state,
      teamMembers: { ...state.teamMembers, data },
    };
  })
);

export function reducer(state: any, action: any) {
  return _settinsReducer(state, action);
}
