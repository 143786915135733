import { createSelector } from "@ngrx/store";
import { AppState } from "@reducers/.";

export const selectAdmin = (state: AppState) => state.admin;

export const selectPaginatedCompanies = createSelector(
  selectAdmin,
  (state) => state?.companies
);

export const selectPaginatedUsers = createSelector(
  selectAdmin,
  (state) => state?.users
);

export const selectCompanyData = createSelector(
  selectAdmin,
  (state) => state?.company?.data
);

export const selectUserData = createSelector(
  selectAdmin,
  (state) => state?.user?.data
);

export const selectGuideData = createSelector(
  selectAdmin,
  (state) => state?.guide?.data
);

export const selectSiteData = createSelector(
  selectAdmin,
  (state) => state?.site?.data
);
