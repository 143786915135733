<nav class="pl-8 pr-4 bg-ni-blue-700">
  <div class="flex items-center h-16 space-x-4">
    <div
      *ngIf="isOmniplexGuide"
      [routerLink]="['/']"
      class="flex space-x-2 items-center select-none cursor-pointer"
    >
      <img class="h-8" src="/assets/images/brand/x-logo.svg" alt="Logo" />
      <h1 class="text-white font-bold text-lg">Guide</h1>
    </div>
    <div
      *ngIf="!isOmniplexGuide"
      [routerLink]="['/']"
      class="flex space-x-2 items-center select-none cursor-pointer"
    >
      <img class="h-8" [src]="logoPath" alt="Logo" />
    </div>
    <div *ngIf="hasSearchBar$ | async" class="w-64">
      <label for="search" class="sr-only">Search</label>
      <div class="relative">
        <div
          class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
        >
          <svg
            class="w-5 h-5 text-ni-blue-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <input
          id="search"
          name="search"
          (input)="setSearchQuery($event.target.value)"
          [value]="searchQuery$ | async"
          class="block w-full py-2 pl-10 pr-3 leading-5 border border-transparent rounded-md text-ni-blue-500 placeholder-ni-blue-500 bg-ni-blue-600 focus:outline-none focus:bg-white focus:border-white focus:ring-white focus:text-gray-900 sm:text-sm"
          placeholder="Search"
          type="search"
        />
      </div>
    </div>
    <div class="flex-grow"></div>
    <div>
      <app-payment-prompt></app-payment-prompt>
    </div>
    <div>
      <app-dropdown>
        <app-button
          trigger
          iconName="user-circle"
          variant="darkSecondary"
          background="dark"
        ></app-button>
        <div menu class="divide-y divide-gray-100">
          <div *ngIf="email$ | async as email" class="px-4 py-3" role="none">
            <p class="text-sm" role="none">Signed in as</p>
            <p class="text-sm font-medium text-gray-900 truncate" role="none">
              {{ email }}
            </p>
          </div>
          <div class="py-1" role="none">
            <button
              *ngIf="isSuperUser$ | async"
              [routerLink]="['/admin']"
              class="flex items-center w-full px-4 py-2 text-sm text-gray-700"
              role="menuitem"
              tabindex="-1"
            >
              Admin console
            </button>
            <button
              (click)="logOut()"
              class="flex items-center w-full px-4 py-2 text-sm text-gray-700"
              role="menuitem"
              tabindex="-1"
            >
              Sign out
            </button>
          </div>
        </div>
      </app-dropdown>
    </div>
  </div>
</nav>
