import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

type SelectedMenuItemId = string | number;
export type SelectMenuItem = {
  id: SelectedMenuItemId;
  title: string;
  icon?: string;
};

@Component({
  selector: "app-select-menu",
  templateUrl: "./select-menu.component.html",
  animations: [
    trigger("openClose", [
      transition("void => *", [
        animate(
          250,
          keyframes([
            style({ opacity: 0, transform: "scaleY(0.8)", offset: 0 }),
            style({ opacity: 0.5, transform: "scaleY(1.05)", offset: 0.5 }),
            style({ opacity: 1, transform: "scaleY(1)", offset: 1 }),
          ])
        ),
      ]),
      transition("* => void", [
        animate(
          100,
          keyframes([
            style({ opacity: 1, offset: 0 }),
            style({ opacity: 0, offset: 1 }),
          ])
        ),
      ]),
    ]),
  ],
})
export class SelectMenuComponent implements OnInit {
  @Input() items: SelectMenuItem[] = [];
  @Input() expanded: boolean = false;
  @Input() icon: string = "";
  @Input() buttonIcon: string = "";
  @Input() background: "light" | "dark" = "light";
  @Input() selectedItemId: SelectedMenuItemId = "";
  @Input() placeholder = "";
  @Input() disabled = false;
  @Output() change = new EventEmitter<SelectedMenuItemId>();
  @Output() buttonClick = new EventEmitter<SelectedMenuItemId>();
  @ViewChild("menu") menu: ElementRef<HTMLDivElement> | null = null;

  get selectedItem(): SelectMenuItem | null {
    return this.items?.find((item) => item.id === this.selectedItemId) ?? null;
  }

  get selectedIcon() {
    return this.selectedItem?.icon || this.icon;
  }

  constructor() {}

  ngOnInit(): void {}

  toggleExpanded(expanded = !this.expanded) {
    this.expanded = this.disabled ? false : expanded;
  }

  isSelected(item: SelectMenuItem) {
    return this.selectedItem?.id === item.id;
  }

  selectItem(item: SelectMenuItem) {
    this.selectedItemId = item.id;
    this.expanded = false;
    this.change.emit(item.id);
  }

  handleButtonClick() {
    if (this.selectedItemId) this.buttonClick.emit(this.selectedItemId);
  }

  @HostListener("document:mousedown", ["$event"])
  handleDocumentClick(event: MouseEvent) {
    if (!this.menu) return;
    const clickedInsideMenu = this.menu.nativeElement.contains(
      event.target as HTMLElement
    );
    if (!clickedInsideMenu) {
      this.expanded = false;
    }
  }
}
