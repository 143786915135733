import { createSelector } from "@ngrx/store";
import { get } from "object-path-immutable";
import { AppState } from "../reducers";

export const selectFlow = (state: AppState) => state.flow;

export const selectActiveFlow = createSelector(
  selectFlow,
  (state) => state.data
);

export const selectActiveFlowId = createSelector(
  selectFlow,
  (state) => state.data?.id
);

export const selectActiveFlowIsLoading = createSelector(
  selectFlow,
  (state) => state.loading
);

export const selectActiveFlowName = createSelector(
  selectFlow,
  (state) => state.data?.name
);

export const selectActiveFlowIsDirty = createSelector(
  selectFlow,
  (state) => state.isDirty
);

export const selectCurrentUrl = createSelector(
  selectFlow,
  (state) => state.currentPage.url
);

export const selectPortalAuthentication = createSelector(
  selectFlow,
  (state) => state.basicAuth
);

export const selectPortalHasAuthentication = createSelector(
  selectFlow,
  (state) => !state.data?.site?.useBasicAuth || state.basicAuth !== null
);

export const selectActiveFlowIsNew = createSelector(
  selectFlow,
  (state) => state.data?.id === null
);

export const selectActiveFlowSteps = createSelector(
  selectFlow,
  (state) => state.data?.steps ?? []
);

export const selectActiveFlowSiteId = createSelector(
  selectFlow,
  (state) => state.data?.siteId ?? null
);

export const selectActiveTemplatePreview = createSelector(selectFlow, (state) =>
  state.editorPath || state.schema?.template?.id !== "tour-1"
    ? state.preview
    : null
);

export const selectActiveTemplateData = createSelector(selectFlow, (state) =>
  state.editorPath ? get(state.data, state.editorPath) : state.data
);

export const selectPreviewData = createSelector(selectFlow, (state) => ({
  data:
    state.editorPath && state.schema?.previewMode !== "none"
      ? get(state.data, state.editorPath)
      : state.data,
  language: state.data?.language ?? "en",
  themeId: state.data?.themeId ?? null,
}));

export const selectActiveTemplatePath = createSelector(
  selectFlow,
  (state) => state.editorPath
);

export const selectActiveTemplateSchema = createSelector(
  selectFlow,
  (state) => state.schema
);

export const selectActiveTemplateSchemaPanels = createSelector(
  selectFlow,
  (state) => state.panels ?? []
);

export const selectActivePanel = createSelector(selectFlow, (state) =>
  state.panels?.find((panel) => panel.id === state.activePanelId)
);

export const selectLivePreview = createSelector(
  selectActiveTemplateSchema,
  (schema) => !schema?.previewMode || schema.previewMode === "site"
);

export const selectActiveFlowUrl = createSelector(
  (state: AppState) => state,
  (state) => {
    const company: any = state.auth.data?.Company;
    const slug = state.flow.data?.slug;

    if (company?.domain && slug) {
      return `https://${company.domain}/${slug}`;
    }

    if (company?.subdomain && slug) {
      return `https://${company.subdomain}.nickelled.com/${slug}`;
    }

    return null;
  }
);
