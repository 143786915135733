import * as CollectionsActions from "@actions/collections.actions";
import { createReducer, on } from "@ngrx/store";
import { Collection } from "src/app/shared/service/collection.service";

export type State = {
  data: Collection[];
  loading: boolean;
  error: Error | null;
};

export const initialState: State = {
  data: null,
  loading: true,
  error: null,
};

const _collectionsReducer = createReducer(
  initialState,
  on(
    CollectionsActions.loadSuccess,
    (state, { data }): State => ({
      ...state,
      data,
      loading: false,
      error: null,
    })
  )
);

export function reducer(state: any, action: any) {
  return _collectionsReducer(state, action);
}
