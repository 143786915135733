import { launchGuide } from "@actions/extension.actions";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "@reducers/.";

@Component({
  selector: "app-launch",
  templateUrl: "./launch.component.html",
  styleUrls: ["./launch.component.scss"],
})
export class LaunchComponent implements OnInit {
  constructor(private store: Store<AppState>, private route: ActivatedRoute) {}

  ngOnInit() {}

  launchGuide() {
    if (this.route.snapshot.params["mode"] === "edit") {
      const id = parseInt(this.route.snapshot.parent.params["id"]);
      this.store.dispatch(launchGuide({ id, edit: true }));
    } else {
      const id = parseInt(this.route.snapshot.params["id"]);
      this.store.dispatch(launchGuide({ id, edit: false }));
    }
  }
}
