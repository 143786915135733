<div class="p-4 m-16 rounded-lg bg-ni-blue-400 w-96">
  <div class="m-4">
    <p class="mb-2 text-base font-medium text-ni-blue-700">
      Your website requires authentication
    </p>
    <p class="text-sm text-ni-blue-600">
      These details are only required if your site is using HTTP Basic
      Authentication. Please contact support if this is not the case.
    </p>
  </div>
  <div class="mx-4 my-6 text-ni-blue-700" [formGroup]="formGroup">
    <app-input label="Username" formControlName="username"></app-input>
    <app-input
      label="Password"
      formControlName="password"
      accept="password"
    ></app-input>
  </div>
  <div class="m-4">
    <app-button
      title="Proceed"
      class="block"
      variant="secondary"
      [disabled]="!formGroup.valid"
      (click)="done()"
    >
    </app-button>
  </div>
</div>
