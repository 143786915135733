import { selectSite } from "@actions/nav-bar.actions";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import {
  selectActiveSite,
  selectAllSites,
  selectSitesLoading,
} from "@selectors/sites.selectors";
import { map } from "rxjs/operators";
import { AppState } from "src/app/store/reducers";
import { LogoService } from "../../service/logo.service";

@Component({
  selector: "app-site-select",
  templateUrl: "./site-select.component.html",
  styleUrls: ["./site-select.component.scss"],
})
export class SiteSelectComponent implements OnInit {
  expanded = false;
  isOmniplexGuide = this.logoService.isOmniplexGuide();

  loading$ = this.store.select(selectSitesLoading);
  site$ = this.store.select(selectActiveSite);
  siteName$ = this.site$.pipe(map((site) => site?.name));
  sites$ = this.store.select(selectAllSites);

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private logoService: LogoService
  ) {}

  ngOnInit(): void {}

  selectSite(id: number) {
    this.expanded = false;
    this.store.dispatch(selectSite({ siteId: id }));
    this.router.navigate(["/"]);
  }

  toggleExpanded() {
    this.expanded = !this.expanded;
  }
}
