import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "./http.service";
import { StepEntity } from "./step.service";

export type StepCategoryId = string;

export type StepTemplate = {
  id: string;
  name: string;
  icon: string;
  description: string;
  schema: any;
};

export type FlowTemplate = {
  id: string;
  name: string;
  pluralId: string;
  pluralName: string;
  image: string;
  icon: string;
  description: string;
  createNewLabel?: string;
  template: { category: StepCategoryId; title?: string }[];
  multiple: { category: StepCategoryId; title?: string };
  group: string;
};

@Injectable({
  providedIn: "root",
})
export class StepTemplateService {
  constructor(private httpService: HttpService) {}

  list(stepCategory: string | null = null): Observable<StepTemplate[]> {
    return this.httpService
      .get(`/step-templates`, { withCredentials: true })
      .pipe(
        map((templates) =>
          templates.filter(
            (tpl: any) => stepCategory === null || tpl.category === stepCategory
          )
        )
      );
  }

  get(id: string): Observable<StepTemplate> {
    return this.httpService.get(`/step-templates/${id}`, {
      withCredentials: true,
    });
  }

  getAsStep(id: string) {
    return this.get(id).pipe(map((tpl) => ({ template: tpl, data: {} })));
  }

  preview(
    templateId: string,
    data: StepEntity["data"],
    language = "en",
    themeId: string = null
  ) {
    const params: any = {};
    if (language) params.language = language;
    if (themeId) params.themeId = themeId;

    return this.httpService.post(
      `/step-templates/${templateId}/preview`,
      data,
      {
        params,
        withCredentials: true,
      }
    );
  }
}
