import { HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FlashService } from "src/app/shared/service/flash.service";
import { HttpService } from "src/app/shared/service/http.service";
import { UserService } from "src/app/shared/service/user.service";

@Component({
  selector: "app-invitation",
  templateUrl: "./invitation.component.html",
  styleUrls: ["./invitation.component.scss"],
})
export class InvitationComponent implements OnInit {
  private id: number | null = null;
  public loading: boolean = false;
  private headers = new HttpHeaders();
  private options: {
    headers: HttpHeaders;
    withCredentials: boolean;
  };

  constructor(
    private userService: UserService,
    private flashService: FlashService,
    private router: Router,
    private route: ActivatedRoute,
    private httpService: HttpService
  ) {
    this.headers = new HttpHeaders({ "Content-Type": "application/json" });
    this.options = {
      headers: this.headers,
      withCredentials: true,
    };

    this.route.queryParams.subscribe((params) => {
      this.id = params["id"];
    });
  }

  ngOnInit() {
    this.userService.getUser().subscribe((user) => {
      if (user.id) {
        this.router.navigateByUrl(`/`);
      }
    });
  }

  signup(signupForm: any) {
    const user = signupForm.value;
    this.loading = true;
    this.httpService
      .post(`/invitations/${this.id}`, user, this.options)
      .subscribe(
        (newUser) => {
          this.flashService.set("Welcome to Nickelled. You're now logged in.");
          this.userService.setUserAndRedirect(newUser);
          this.loading = false;
        },
        (err) => {
          this.loading = false;
          this.flashService.set(
            "Signup failed. Please make sure you use the same email."
          );
        }
      );
  }
}
