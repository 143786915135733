import { createAction, props } from "@ngrx/store";

export const loadThemes = createAction("[Configuration] Load Themes");

export const loadThemesSuccess = createAction(
  "[Configuration] Load Themes Success",
  props<{ data: any[] }>()
);

export const loadThemesFailure = createAction(
  "[Configuration] Load Themes Failure"
);
