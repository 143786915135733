import * as AuthApiActions from "@actions/auth-api.actions";
import * as FlagsActions from "@actions/flags.actions";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as ld from "launchdarkly-js-client-sdk";
import { Observable } from "rxjs";
import { exhaustMap, map, take } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable()
export class FlagsEffects {
  client: ld.LDClient;

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.userLoadedSuccess),
      take(1),
      exhaustMap(
        ({ data }) =>
          new Observable<any>((subscriber) => {
            const client = ld.initialize(environment.launchDarklyClientId, {
              email: data.email,
              key: data.CompanyId,
              secondary: data.id.toString(),
              name: data.Company?.name,
            });

            const updateFlags = () => {
              const flags = client.allFlags();
              subscriber.next(flags);
            };

            client.on("ready", updateFlags);
            client.on("change", updateFlags);
          })
      ),
      map((data) => FlagsActions.flagsLoaded({ data }))
    )
  );

  constructor(private actions$: Actions) {}
}
