import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  constructor(private httpService: HttpService) {}

  getCompanies(searchQuery: string, offset: number) {
    const params = new HttpParams({
      fromObject: { searchQuery, offset: offset.toString() },
    });

    return this.httpService.get(`/admin/company`, {
      withCredentials: true,
      params: params,
    });
  }

  getUsers(searchQuery: string, offset: number) {
    const params = new HttpParams({
      fromObject: { searchQuery, offset: offset.toString() },
    });

    return this.httpService.get(`/admin/user`, {
      withCredentials: true,
      params: params,
    });
  }

  getCompany(id: number) {
    return this.httpService.get(`/admin/company/${id}`, {
      withCredentials: true,
    });
  }

  getUser(id: number) {
    return this.httpService.get(`/admin/user/${id}`, {
      withCredentials: true,
    });
  }

  getGuide(id: number) {
    return this.httpService.get(`/admin/guide/${id}`, {
      withCredentials: true,
    });
  }

  getSite(id: number) {
    return this.httpService.get(`/admin/site/${id}`, {
      withCredentials: true,
    });
  }

  updateCompany(id: number, data: any) {
    return this.httpService.put(`/admin/company/${id}`, data, {
      withCredentials: true,
    });
  }

  updateUser(id: number, data: any) {
    return this.httpService.put(`/admin/user/${id}`, data, {
      withCredentials: true,
    });
  }

  updateGuide(id: number, data: any) {
    return this.httpService.put(`/admin/guide/${id}`, data, {
      withCredentials: true,
    });
  }

  updateSite(id: number, data: any) {
    return this.httpService.put(`/admin/site/${id}`, data, {
      withCredentials: true,
    });
  }

  impersonateCompany(companyId: number) {
    return this.httpService.post(
      `/admin/impersonate`,
      { companyId },
      {
        withCredentials: true,
      }
    );
  }

  cancelSubscription(companyId: number) {
    return this.httpService.post(
      `/admin/cancel-subscription`,
      { companyId },
      {
        withCredentials: true,
      }
    );
  }
}
