import { createAction, props } from "@ngrx/store";

export const loadPaymentSettings = createAction(
  "[Settings] Load Payment Details"
);

export const updatePaymentSettings = createAction(
  "[Settings] Update Payment Details",
  props<{
    stripeToken: string;
    stripeEmail: string;
  }>()
);

export const loadInvoiceList = createAction("[Settings] Load Invoice List");

export const updateContactDetails = createAction(
  "[Settings] Update Contact Details",
  props<{ firstName: string; secondName: string; email: string }>()
);

export const updatePassword = createAction(
  "[Settings] Update Password",
  props<{ oldPassword: string; newPassword: string }>()
);

export const updateEmailSubscriptions = createAction(
  "[Settings] Update Email Subscriptions",
  props<{ weeklyEmail: boolean }>()
);

export const removeTeamMember = createAction(
  "[Settings] Remove Team Member",
  props<{ id: number }>()
);

export const cancelInvitation = createAction(
  "[Settings] Cancel Invitation",
  props<{ id: number }>()
);

export const resendInvitation = createAction(
  "[Settings] Resend Invitation",
  props<{ id: number }>()
);

export const createInvitation = createAction(
  "[Settings] Create Invitation",
  props<{ email: string }>()
);

export const updateInvoiceNotificationEmails = createAction(
  "[Settings] Update Invoice Notification Emails",
  props<{ emails: string }>()
);

export const loadTeamMembers = createAction("[Settings] Load Team Members");

export const setRoles = createAction(
  "[Settings] Set Roles",
  props<{ id: number; roleNames: string[] }>()
);
