import logger from "@redux-beacon/logger";
import Segment from "@redux-beacon/segment";
import { createMetaReducer } from "redux-beacon";
import { environment } from "src/environments/environment";
import { EVENTS_MAP } from "./map";

const segment = Segment();
export const eventsMetaReducer = environment.production
  ? createMetaReducer(EVENTS_MAP, segment)
  : createMetaReducer(EVENTS_MAP, segment, { logger });
