<div
  class="grid h-full bg-gradient-to-br from-ni-blue-450 to-ni-blue-500 place-items-center"
>
  <app-panel-generic class="block w-full max-w-lg">
    <div class="p-4 bg-gray-50">
      <h3 class="text-lg font-medium leading-6 text-gray-900">Browser setup</h3>
    </div>

    <ng-container [ngSwitch]="stage$ | async">
      <div
        *ngSwitchCase="'start'"
        class="flex flex-col justify-center p-12 space-y-8 h-96"
      >
        <h1 class="text-2xl font-medium text-center text-gray-900">
          {{ (redirect$ | async)?.message }}
        </h1>
        <div class="text-sm text-center text-gray-500">
          You'll only need to do this once.
        </div>
      </div>
      <div
        *ngSwitchCase="'install'"
        class="flex flex-col justify-center p-12 space-y-8 h-96"
      >
        <h1 class="text-2xl font-medium text-center text-gray-900">
          Install the Browser Extension to continue
        </h1>
        <div class="flex justify-center">
          <app-button
            (click)="openStore()"
            iconName="globe-alt"
            title="Open Chrome Web Store"
            variant="plain"
          >
          </app-button>
        </div>
      </div>
      <div
        *ngSwitchCase="'done'"
        class="flex flex-col justify-center p-12 space-y-8 h-96"
      >
        <div
          class="flex items-center justify-center w-16 h-16 mx-auto text-green-600 bg-green-100 rounded-full"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-8 h-8"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </div>
        <h1 class="text-2xl font-medium text-center text-gray-900">
          Your browser is ready to run {{ tenantName }} Guides
        </h1>
      </div>
      <div *ngSwitchDefault class="grid h-96 place-items-center">
        <div class="text-gray-500 h-14">
          <app-loading-spinner></app-loading-spinner>
        </div>
      </div>
    </ng-container>

    <div
      *ngIf="redirect$ | async as redirect"
      [ngSwitch]="stage$ | async"
      class="flex items-center justify-end h-16 px-4 bg-gray-50"
    >
      <app-button
        *ngSwitchCase="'start'"
        title="Continue"
        (click)="runStatusCheck()"
      >
      </app-button>
      <app-button
        *ngSwitchCase="'install'"
        title="Continue"
        (click)="runStatusCheck()"
      >
      </app-button>
      <ng-container *ngSwitchCase="'done'">
        <app-button
          *ngIf="redirect.doneButtonText"
          [title]="redirect.doneButtonText"
          [routerLink]="redirect.routerLink"
        >
        </app-button>
      </ng-container>
      <app-button *ngSwitchDefault title="Continue" [disabled]="true">
      </app-button>
    </div>
  </app-panel-generic>
</div>
