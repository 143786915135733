import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import { selectAllSites } from "@selectors/sites.selectors";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SelectMenuItem } from "src/app/shared/components/select-menu/select-menu.component";
import { AppState } from "src/app/store/reducers";

@Component({
  selector: "app-field-site-select",
  templateUrl: "./field-site-select.component.html",
  styleUrls: ["./field-site-select.component.scss"],
})
export class FieldSiteSelectComponent implements OnInit {
  @Input() value = "";
  @Input() placeholder = "";
  @Output() update = new EventEmitter<any>();

  items$: Observable<SelectMenuItem[]> = this.store.select(selectAllSites).pipe(
    map((sites) =>
      sites.map((site) => ({
        id: site.id,
        title: site.name,
      }))
    )
  );

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {}

  handleChange(id: any) {
    this.update.emit(id);
  }
}
