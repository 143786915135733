import { createAction, props } from "@ngrx/store";
import { SiteEntity } from "src/app/shared/service/site.service";

export const sitesLoadedSuccess = createAction(
  "[Sites API] Sites Loaded Success",
  props<{ sites: SiteEntity[]; siteId?: number }>()
);

export const sitesLoadedFailure = createAction(
  "[Sites API] Sites Loaded Failure",
  props<{ error: any }>()
);

export const firstSiteCreatedSuccess = createAction(
  "[Sites API] First Site Created Success",
  props<{ sites: SiteEntity[] }>()
);

export const firstSiteCreatedFailure = createAction(
  "[Sites API] First Site Created Failure",
  props<{ error: any }>()
);

export const firstSiteUpdatedSuccess = createAction(
  "[Sites API] First Site Updated Success",
  props<{ site: SiteEntity }>()
);

export const firstSiteUpdatedFailure = createAction(
  "[Sites API] First Site Updated Failure",
  props<{ error: any }>()
);

export const siteUpdatedSuccess = createAction(
  "[Sites API] Site Updated Success",
  props<{ site: SiteEntity }>()
);

export const siteUpdatedFailure = createAction(
  "[Sites API] Site Updated Failure",
  props<{ error: any }>()
);

export const siteDeletedFailure = createAction(
  "[Sites API] Site Deleted Failure",
  props<{ error: any }>()
);

export const siteDeletedSuccess = createAction(
  "[Sites API] Site Deleted Success",
  props<{ siteId?: number }>()
);

export const siteCreatedSuccess = createAction(
  "[Sites API] Site Created Success",
  props<{ site: SiteEntity }>()
);

export const siteCreatedFailure = createAction(
  "[Sites API] Site Created Failure",
  props<{ error: any }>()
);
