import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SchemaField } from "src/app/shared/models/schema";

@Component({
  selector: "app-schema-field",
  templateUrl: "./schema-field.component.html",
  styleUrls: ["./schema-field.component.scss"],
})
export class SchemaFieldComponent implements OnInit {
  @Input() def: SchemaField = null;
  @Input() value: any = null;
  @Output() update = new EventEmitter<any>();
  @Output() action = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  handleUpdate(value: any) {
    this.update.emit(value);
  }

  handleAction(value: any) {
    this.action.emit(value);
  }
}
