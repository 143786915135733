import { setPostLoginRedirectUrl } from "@actions/auth.actions";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "@reducers/.";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { EventTrackingService } from "../shared/service/event-tracking.service";
import { UserService } from "../shared/service/user.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    public eventTrackingService: EventTrackingService,
    private store: Store<AppState>
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.userService.user) return true;

    this.store.dispatch(setPostLoginRedirectUrl({ url: state.url }));

    return this.userService.getUser().pipe(
      map((user) => {
        this.eventTrackingService.identify(user);
        this.userService.setUserAndRedirect(user);

        if (!user) {
          this.router.navigate(["/account"]);
          return false;
        } else {
          return true;
        }
      }),
      catchError((err, caught) => {
        this.router.navigate(["/account"]);
        return of(false);
      })
    );
  }
}
