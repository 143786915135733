<div class="Loading">
  <div
    class="LoadingSpinner__Box"
    title="Please wait while the site loads"
    alt="Please wait while the site loads"
  >
    <span class="LoadingSpinner__Tri"></span>
    <div class="LoadingSpinner__Icon">Ni</div>
  </div>
</div>
