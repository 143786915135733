import { DatePipe } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AdminEffects } from "@effects/admin.effects";
import { AuthEffects } from "@effects/auth.effects";
import { ConfigurationEffects } from "@effects/configuration.effects";
import { ExtensionEffects } from "@effects/extension.effects";
import { FlagsEffects } from "@effects/flags.effects";
import { FlowEffects } from "@effects/flow.effects";
import { FlowsEffects } from "@effects/flows.effects";
import { SettingsEffects } from "@effects/settings.effects";
import { SitesEffects } from "@effects/sites.effects";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { SortablejsModule } from "ngx-sortablejs";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { EditorModule } from "./editor/editor.module";
import { errorHandlerFactory } from "./error-handler";
import { ExtensionSetupComponent } from "./pages/extension-setup/extension-setup.component";
import { LaunchComponent } from "./pages/launch/launch.component";
import { ConfirmationDialogService } from "./shared/components/confirmation-dialog/confirmation-dialog.service";
import { HttpService } from "./shared/service/http.service";
import { LogoService } from "./shared/service/logo.service";
import { TokenInterceptor } from "./shared/service/token-interceptor";
import { UserService } from "./shared/service/user.service";
import { SharedModule } from "./shared/shared.module";
import { metaReducers, ROOT_REDUCERS } from "./store/reducers";
import { AuthGuard } from "./user/auth.guard";
import { InvitationComponent } from "./user/invitation/invitation.component";
import { TrialOrCustomerGuardService } from "./user/trial-or-customer-guard.service";

@NgModule({
  declarations: [
    AppComponent,
    InvitationComponent,
    ExtensionSetupComponent,
    LaunchComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    SortablejsModule.forRoot({ animation: 150 }),
    FontAwesomeModule,
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        // strictStateImmutability and strictActionImmutability are enabled by default
        strictStateSerializability: true,
        strictActionSerializability: true,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 50,
    }),
    EffectsModule.forRoot([
      FlowEffects,
      FlowsEffects,
      SitesEffects,
      AuthEffects,
      SettingsEffects,
      ExtensionEffects,
      FlagsEffects,
      ConfigurationEffects,
      AdminEffects,
    ]),
    StoreRouterConnectingModule.forRoot(),
    EditorModule,
  ],
  providers: [
    HttpService,
    UserService,
    LogoService,
    ConfirmationDialogService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useFactory: errorHandlerFactory,
    },
    AuthGuard,
    TrialOrCustomerGuardService,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
