import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-field-tags",
  templateUrl: "./field-tags.component.html",
  styleUrls: ["./field-tags.component.scss"],
})
export class FieldTagsComponent implements OnInit {
  @Input() value = "";
  @Output() update = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  handleUpdate(value) {
    this.update.emit(value);
  }
}
