<div [formGroup]="form">
  <div class="my-1 mb-4">
    <label class="block text-sm font-medium text-ni-blue-600">
      If all of the following conditions are met&hellip;
    </label>
  </div>

  <ng-container [formArrayName]="'formArray'">
    <div
      class="mb-2"
      *ngFor="
        let control of formArray.controls;
        index as i;
        let isFirst = first;
        let isLast = last
      "
      [class.mb-4]="isLast"
    >
      <div
        [formGroupName]="i"
        class="grid grid-cols-2 gap-1 p-1 overflow-hidden bg-gray-500 rounded-lg bg-opacity-10"
      >
        <div>
          <div class="relative rounded-md shadow-sm">
            <div
              class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
            >
              <hero-icon name="variable"></hero-icon>
            </div>
            <input
              type="text"
              formControlName="variable"
              [value]="control.variable"
              list="attribute-datalist"
              class="block w-full pl-10 text-sm border-gray-300 rounded-md focus:ring-ni2-orange-400 focus:border-ni2-orange-400"
              placeholder="Variable"
            />
          </div>
        </div>
        <div>
          <select
            formControlName="comparison"
            #comparison
            (input)="handleComparisonUpdate(i, comparison.value)"
            class="block w-full py-2 pl-3 pr-10 text-sm border-gray-300 rounded-md focus:outline-none focus:ring-ni2-orange-400 focus:border-ni2-orange-400"
          >
            <option
              *ngFor="let opt of comparators"
              [value]="opt.id"
              [disabled]="!!opt.disabled"
              [selected]="opt.id === control.comparison"
            >
              {{ opt.name }}
            </option>
          </select>
        </div>
        <div
          class="col-span-2"
          *ngIf="getValueFieldType(comparison.value) !== null"
        >
          <input
            formControlName="value"
            [type]="getValueFieldType(comparison.value)"
            class="block w-full text-sm border-gray-300 rounded-md shadow-sm focus:ring-ni2-orange-400 focus:border-ni2-orange-400"
            placeholder="Value"
          />
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div>
  <app-button title="Add rule" variant="darkSecondary" (click)="addCondition()">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="w-5 h-5"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fill-rule="evenodd"
        d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
        clip-rule="evenodd"
      />
    </svg>
  </app-button>
</div>
<datalist id="attribute-datalist">
  <option *ngFor="let attr of attributes$ | async" [value]="attr.name"></option>
</datalist>
<p class="mt-2 text-xs font-medium text-ni-blue-600 ng-star-inserted">
  Learn how to pass variables to Nickelled in our
  <a
    href="https://help.nickelled.com/en/articles/3843209-audience-segmentation"
    class="font-semibold"
    target="_BLANK"
    >Help Center</a
  >.
</p>
