<div class="fixed inset-0 flex flex-col items-stretch h-screen overflow-hidden">
  <div class="flex-shrink-0">
    <app-site-header></app-site-header>
  </div>
  <div class="flex items-stretch flex-grow overflow-hidden">
    <div class="flex-shrink-0 w-64 border-r bg-ni-blue-475 border-ni-blue-500">
      <app-side-nav></app-side-nav>
    </div>
    <div class="flex-grow overflow-y-auto">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<app-message-channel></app-message-channel>
