import { setBasicAuthCredentials } from "@actions/editor.actions";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/store/reducers";

@Component({
  selector: "app-portal-auth",
  templateUrl: "./portal-auth.component.html",
  styleUrls: ["./portal-auth.component.scss"],
})
export class PortalAuthComponent implements OnInit {
  formGroup = new FormGroup({
    username: new FormControl("", [Validators.required]),
    password: new FormControl("", [Validators.required]),
  });

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {}

  done() {
    if (!this.formGroup.valid) return;
    const { username, password } = this.formGroup.getRawValue();
    this.store.dispatch(setBasicAuthCredentials({ username, password }));
  }
}
