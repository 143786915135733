import { Injectable } from "@angular/core";

export type Category = {
  id: string;
  urlParam: string;
  title: string;
  singularLabel: string;
  pluralLabel: string;
  type?: string;
  description: string;
  imageUrl?: string;
  types?: {
    id: string;
    label: string;
    description?: string;
    badge?: string;
    imageUrl?: string;
    initialPanelId?: string;
  }[];
  hasSingleResource: boolean;
};

const RESOURCE_CATEGORIES: Category[] = [
  {
    id: "guides",
    urlParam: "guides",
    title: "Guides",
    singularLabel: "guide",
    pluralLabel: "guides",
    type: "tour",
    description:
      "Create step-by-step guides that run inside of your website. No coding required.",
    imageUrl: "/assets/images/flows/screenshots/guides.png",
    types: [
      {
        id: "customerService",
        initialPanelId: "wizard",
        label: "Snapshot guide",
        description:
          "A snapshot of each page is captured as you create your guide. You can share your guide via URL.",
        badge: "Basic",
        imageUrl: "/assets/images/launchers/mission-dock.svg",
      },
      {
        id: "tour",
        initialPanelId: "wizard",
        label: "Interactive guide",
        description:
          "Create guides that run inside of any website. Users can complete steps as they follow your guide.",
        badge: "Advanced",
        imageUrl: "/assets/images/launchers/mission-dock.svg",
      },
    ],
    hasSingleResource: false,
  },
  {
    id: "academies",
    urlParam: "knowledge-base",
    title: "Knowledge Base",
    singularLabel: "knowledge base",
    pluralLabel: "knowledge bases",
    description: "The easiest way to publish your training content.",
    imageUrl: "/assets/images/flows/screenshots/collections.png",
    type: "academy-1",
    types: [
      {
        id: "academy-1",
        initialPanelId: "details",
        label: "Academy",
        imageUrl: "/assets/images/flows/screenshots/articles.png",
        description: "Publish your content to the web.",
      },
    ],
    hasSingleResource: true,
  },
  {
    id: "assistant",
    urlParam: "assistant",
    title: "Help Assistant",
    singularLabel: "assistant",
    pluralLabel: "assistants",
    type: "page-assistant-1",
    description:
      "The Help Assistant allows users to launch your guides no matter where they are on your site.",
    imageUrl: "/assets/images/flows/screenshots/assistants.png",
    types: [
      {
        id: "page-assistant-1",
        initialPanelId: "widget",
        label: "Help assistant",
        description:
          "Publish groups of content to your site or knowledge base with Academies.",
        imageUrl: "/assets/images/launchers/custom-button.svg",
      },
    ],
    hasSingleResource: true,
  },
  {
    id: "widgets",
    urlParam: "widgets",
    title: "Widgets",
    singularLabel: "widget",
    pluralLabel: "widgets",
    type: "flow-tooltip-1",
    description:
      "Use Widgets to overlay interactive training material on any site.",
    imageUrl: "/assets/images/flows/screenshots/assistants.png",
    types: [
      {
        id: "flow-tooltip-1",
        initialPanelId: "wizard",
        label: "Tooltip",
        description:
          "Publish groups of content to your site or knowledge base with Academies.",
        imageUrl: "/assets/images/launchers/custom-button.svg",
      },
    ],
    hasSingleResource: false,
  },
];

@Injectable({
  providedIn: "root",
})
export class ResourceCategoriesService {
  constructor() {}

  list() {
    return RESOURCE_CATEGORIES;
  }

  getByUrlParam(val: string | null) {
    if (!val) val = null;
    return RESOURCE_CATEGORIES.find((cat) => cat.urlParam === val);
  }

  getByTemplateId(val: string | null) {
    return RESOURCE_CATEGORIES.find((cat) => cat.id === val);
  }
}
