import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpService } from "./http.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class BillingService {
  constructor(
    private userService: UserService,
    private httpService: HttpService
  ) {}

  private defaultHttpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true,
  };

  private updateCompany(data: any) {
    const companyId = this.userService.user.CompanyId;
    const body = JSON.stringify(data);

    return this.httpService.post(
      `/company/${companyId}.json`,
      body,
      this.defaultHttpOptions
    );
  }

  updateInvoiceNotificationAddress(emails: string) {
    return this.updateCompany({
      emailInvoice: !!emails,
      invoiceEmailAddress: emails,
    });
  }

  updateCardDetails(stripeToken, stripeEmail) {
    const body = JSON.stringify({ stripeToken, stripeEmail });

    return this.httpService
      .post(`/update-card`, body, this.defaultHttpOptions)
      .pipe(
        map((card) => ({
          paymentFailure: false,
          errorMessage: null,
          ccExpiryMonth: card.ccExpiryMonth,
          ccExpiryYear: card.ccExpiryYear,
          ccLast4: card.ccLast4,
        })),
        catchError((err) => {
          return of({
            paymentFailure: true,
            errorMessage: err,
            ccExpiryMonth: null,
            ccExpiryYear: null,
            ccLast4: null,
          });
        })
      );
  }

  getInvoices() {
    const companyId = this.userService.user.CompanyId;

    return this.httpService.get(`/company/${companyId}/invoices.json`, {
      withCredentials: true,
    });
  }
}
