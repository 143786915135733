<ng-container *ngIf="category$ | async as category">
  <ng-container *ngIf="items$ | async as items">
    <div class="flex flex-col h-full">
      <div class="flex-grow overflow-y-auto">
        <ul *ngIf="items.length" class="divide-y divide-ni-blue-450">
          <app-resource-list-item
            *ngFor="let item of items"
            [name]="item.name"
            [updatedAt]="item.createdAt"
            [type]="item.templateName"
            [autoStart]="item.isPublished"
            [link]="['/', category.urlParam, item.id]"
            class="block"
          >
          </app-resource-list-item>
        </ul>

        <div *ngIf="!items.length" class="h-full p-2">
          <app-placeholder *ngIf="showSearchBar">
            <p class="text-sm text-ni-blue-600">No search results found.</p>
          </app-placeholder>
          <app-placeholder *ngIf="!showSearchBar">
            <p class="text-sm text-ni-blue-600">
              Once you've made your first {{ category.singularLabel }} it will
              appear here.
            </p>
          </app-placeholder>
        </div>
      </div>
      <div
        *ngIf="!isOmniplexGuide"
        class="flex-shrink-0 p-6 border-t border-ni-blue-475"
      >
        <app-button
          [title]="'Make a ' + category.singularLabel"
          variant="secondary"
          (click)="triggerNew(category.type)"
        >
        </app-button>
      </div>
    </div>
  </ng-container>
</ng-container>
