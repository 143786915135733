import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-field-list",
  templateUrl: "./field-list.component.html",
  styleUrls: ["./field-list.component.scss"],
})
export class FieldListComponent implements OnInit {
  @Input() value = [];
  @Input() help = "";
  @Output() update = new EventEmitter<any>();
  @Output() action = new EventEmitter<{ type: string; key: string }>();

  get items() {
    const value = Array.isArray(this.value) ? this.value : [];
    return value.map((item) => ({
      id: item.id.toString(),
      title: item.name || item.fullName || item.title || `#${item.id}`,
    }));
  }

  constructor() {}

  ngOnInit(): void {}

  handleClick(id: string) {
    this.action.emit({ type: "click", key: id });
  }

  trackById(idx, item) {
    return item.id;
  }
}
