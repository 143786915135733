import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "@reducers/.";
import { selectActiveTemplatePreview } from "@selectors/flow.selectors";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "app-preview-flow-step",
  templateUrl: "./preview-flow-step.component.html",
  styleUrls: ["./preview-flow-step.component.scss"],
})
export class PreviewFlowStepComponent implements OnInit {
  step$ = new BehaviorSubject<any>(null);

  updateStep$ = this.store
    .select(selectActiveTemplatePreview)
    .subscribe((step) => this.step$.next(step?.preview));

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {}
}
