import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-field-boolean",
  templateUrl: "./field-boolean.component.html",
  styleUrls: ["./field-boolean.component.scss"],
})
export class FieldBooleanComponent implements OnInit, OnDestroy {
  @Input() set value(enabled: boolean) {
    this.enabled = enabled;
    this.control.setValue(enabled, { emitEvent: false });
  }
  @Output() update = new EventEmitter<boolean>();

  control = new FormControl(this.value);
  enabled = false;

  controlUpdateSubscription = this.control.valueChanges.subscribe((value) => {
    if (this.enabled === value) return;
    this.enabled = value;
    this.update.emit(value);
  });

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.controlUpdateSubscription.unsubscribe();
  }
}
