<div class="fixed inset-0 flex flex-col items-stretch h-screen overflow-hidden">
  <div class="flex-shrink-0">
    <app-site-header></app-site-header>
  </div>
  <div class="flex-grow overflow-hidden">
    <router-outlet></router-outlet>
  </div>
</div>

<app-message-channel></app-message-channel>
