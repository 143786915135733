import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import { selectActiveFlow } from "@selectors/flow.selectors";
import { concatMap } from "rxjs/operators";
import { AppState } from "src/app/store/reducers";
import { AttributeService } from "../../../shared/service/attribute.service";

const COMPARATORS = [
  { id: "any", name: "Select...", input: null, disabled: true },
  { id: "matches", name: "Equals", input: "text" },
  { id: "less", name: "Less than", input: "number" },
  { id: "greater", name: "Greater than", input: "number" },
  // { id: 'after', name: 'After', input: 'date' },
  // { id: 'before', name: 'Before', value: 'date' },
  { id: "date-past-less", name: "< X days ago", input: "number" },
  { id: "date-past-greater", name: "> X days ago", input: "number" },
  { id: "date-future-less", name: "< X days from now", input: "number" },
  { id: "date-future-greater", name: "> X days from now", input: "number" },
  { id: "true", name: "True", input: null },
  { id: "false", name: "False", input: null },
  { id: "!remove", name: "(Remove rule)", input: null },
];

@Component({
  selector: "app-field-criteria-builder",
  templateUrl: "./field-criteria-builder.component.html",
})
export class FieldCriteriaBuilderComponent implements OnInit, OnChanges {
  @Input() value = [];
  @Output() update = new EventEmitter<string>();
  formArray = new FormArray([]);
  form = new FormGroup({ formArray: this.formArray });
  comparators = COMPARATORS;
  activeFlow$ = this.store.select(selectActiveFlow);
  attributes$ = this.activeFlow$.pipe(
    concatMap((flow) => {
      return this.attributeService.list(flow.siteId);
    })
  );

  emitUpdates$ = this.formArray.valueChanges.subscribe((value) =>
    this.update.emit(value)
  );

  constructor(
    private attributeService: AttributeService,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.updateFormArray();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes["value"]) return;
    if (!this.valueHasChanged(changes["value"])) return;
    this.updateFormArray();
  }

  valueHasChanged(change: SimpleChange) {
    const { currentValue, previousValue } = change;
    return JSON.stringify(currentValue) !== JSON.stringify(previousValue);
  }

  updateFormArray() {
    if (this.value.length !== this.formArray.controls.length) {
      this.formArray.clear();

      this.value
        .map((record) => {
          let data: { [key: string]: FormControl } = {};

          Object.entries(record).forEach(([key, val]) => {
            data[key] = new FormControl(val);
          });

          return new FormGroup(data);
        })
        .forEach((formGroup) => {
          this.formArray.push(formGroup);
        });
    } else {
      this.formArray.setValue(this.value);
    }
  }

  addCondition() {
    const group = new FormGroup({
      variable: new FormControl(),
      comparison: new FormControl("any"),
      value: new FormControl(),
    });

    this.formArray.push(group);
  }

  removeCondition(index: number) {
    this.formArray.removeAt(index);
    this.formArray.markAsDirty();
  }

  getValueFieldType(comparison: string) {
    const comparator = this.comparators.find((c) => c.id === comparison);
    return comparator?.input ?? null;
  }

  handleComparisonUpdate(index: number, value: string) {
    if (value === "!remove") {
      this.removeCondition(index);
    }
  }
}
