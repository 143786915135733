import * as FlagsActions from "@actions/flags.actions";
import { createReducer, on } from "@ngrx/store";

export type State = {
  updatedAt: number | null;
  data: {
    [key: string]: any;
  };
};

export const initialState: State = {
  updatedAt: null,
  data: {},
};

const _flagsReducer = createReducer(
  initialState,
  on(
    FlagsActions.flagsLoaded,
    (state, { data }): State => ({
      updatedAt: Date.now(),
      data,
    })
  )
);

export function reducer(state: any, action: any) {
  return _flagsReducer(state, action);
}
