import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-panel-generic",
  templateUrl: "./panel-generic.component.html",
  styleUrls: ["./panel-generic.component.scss"],
})
export class PanelGenericComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
