import * as EditorActions from "@actions/editor.actions";
import * as FlowsApiActions from "@actions/flows-api.actions";
import * as PortalActions from "@actions/portal.actions";
import { createReducer, on } from "@ngrx/store";
import { del, get, push, set } from "object-path-immutable";
import { FlowEntity } from "src/app/shared/service/flow.service";

export type State = {
  data: FlowEntity | null;
  loading: boolean;
  error: Error | null;
  isDirty: boolean;
  currentPage: { url: string | null; title: string | null };
  basicAuth: { username: string; password: string } | null;
  editorPath: string;
  preview: any;
  schema: any;
  panels: any[];
  activePanelId: string | null;
};

export const initialState: State = {
  data: null,
  loading: false,
  error: null,
  isDirty: false,
  currentPage: { url: null, title: null },
  basicAuth: null,
  editorPath: "",
  preview: null,
  schema: null,
  panels: [],
  activePanelId: null,
};

const _flowReducer = createReducer(
  initialState,
  on(EditorActions.newFlow, EditorActions.clearFlow, (state) => ({
    data: null,
    loading: false,
    error: null,
    isDirty: true,
    currentPage: { url: null, title: null },
    basicAuth: null,
    editorPath: "",
    preview: null,
    schema: null,
    panels: [],
    activePanelId: null,
  })),
  on(EditorActions.loadFlow, EditorActions.loadSingletonFlow, (state) => ({
    data: null,
    loading: true,
    error: null,
    isDirty: false,
    currentPage: { url: null, title: null },
    basicAuth: null,
    editorPath: "",
    preview: null,
    schema: null,
    panels: [],
    activePanelId: null,
  })),
  on(
    FlowsApiActions.flowLoadedSuccess,
    (
      state,
      { flow, template, editorPath = "", panelId = null, isDirty = false }
    ): State => ({
      ...state,
      editorPath,
      data: flow,
      loading: false,
      error: null,
      isDirty,
      currentPage: { url: null, title: null },
      schema: template,
      panels: template?.panels,
      preview: null,
      activePanelId: panelId,
    })
  ),
  on(FlowsApiActions.stepTemplateLoadedSuccess, (state, { step }): State => {
    const steps = state.data.steps.slice(0);
    steps.push(step);
    return {
      ...state,
      data: { ...state.data, steps },
    };
  }),
  on(
    EditorActions.updateFlow,
    (state, { flow }): State => ({
      ...state,
      data: { ...state.data, ...flow },
      isDirty: true,
    })
  ),
  on(
    EditorActions.previewLoadedSuccess,
    (state, { preview }): State => ({
      ...state,
      preview,
    })
  ),
  on(
    PortalActions.portalPageChanged,
    (state, { url, title = null }): State => ({
      ...state,
      currentPage: { url, title },
    })
  ),
  on(
    FlowsApiActions.firstStepTemplateLoadedSuccess,
    (state, { step }): State => {
      if (!state.currentPage?.url) return state;
      const steps = state.data.steps.slice(0);
      steps.unshift({ ...step, data: { url: state.currentPage.url } });
      return {
        ...state,
        data: { ...state.data, steps },
      };
    }
  ),
  on(
    EditorActions.setBasicAuthCredentials,
    (state, { username, password }) => ({
      ...state,
      basicAuth: { username, password },
    })
  ),
  on(
    FlowsApiActions.templateLoadedSuccess,
    (state, { path, template, insert = false, initialData = {} }) => {
      const data = insert
        ? push(state.data, path, { $templateId: template.id, ...initialData })
        : state.data;
      const value: any = get(state.data, path);
      const editorPath = insert ? [path, value?.length ?? 0].join(".") : path;

      return {
        ...state,
        data,
        editorPath,
        schema: template,
      };
    }
  ),
  on(
    EditorActions.updateValue,
    (state, { path = "", value, extend = false }) => {
      const completePath = [state.editorPath, path]
        .filter((x) => !!x)
        .join(".");

      if (extend) {
        const current = get(state.data, completePath, {});
        return {
          ...state,
          data: set(state.data, completePath, { ...current, ...value }),
        };
      }

      return {
        ...state,
        isDirty: true,
        data: set(state.data, completePath, value),
      };
    }
  ),
  on(EditorActions.removeCurrentItem, (state) => {
    return {
      ...state,
      data: del(state.data, state.editorPath),
      editorPath: "",
    };
  }),
  on(FlowsApiActions.flowSavedSuccess, (state) => {
    return { ...state, isDirty: false, activePanelId: null };
  }),
  on(EditorActions.launchPanel, (state, { panelId }) => ({
    ...state,
    activePanelId: panelId,
  })),
  on(EditorActions.nextPanel, (state) => {
    const flow: any = state.data;
    if (flow.guideType === "snapshot") return state;

    let activePanelId = null;
    const panels = state.panels ?? [];

    if (!state.data?.id) {
      activePanelId =
        panels.find((panel) => panel.id === state.activePanelId)?.nextPanelId ??
        null;
    }

    return {
      ...state,
      activePanelId,
    };
  })
);

export function reducer(state: any, action: any) {
  return _flowReducer(state, action);
}
