import { createAction, props } from "@ngrx/store";

export const load = createAction(
  "[Collection] Load",
  props<{ refresh?: boolean }>()
);

export const loadSuccess = createAction(
  "[Collection] Load Success",
  props<{ data: any[] }>()
);

export const loadFailure = createAction("[Collection] Load Failure");
