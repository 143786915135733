import { Component, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-input",
  templateUrl: "./input.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputComponent,
    },
  ],
})
export class InputComponent implements ControlValueAccessor {
  @Input() label = "";
  @Input() placeholder = "";
  @Input() help = "";
  @Input() accept = "text";
  @Input() disabled = false;
  value: string = "";

  constructor() {}

  ngOnInit(): void {}

  public onChange: any = () => {};
  public onTouch: any = () => {};

  public writeValue(value: any): void {
    this.value = value;
    this.onChange(value);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}
