import { createAction, props } from "@ngrx/store";
import { StepTemplate } from "src/app/shared/service/flow-template.service";
import { Category } from "src/app/shared/service/resource-categories.service";
import { ResourceSummary } from "src/app/shared/service/resource.service";
import { FlowEntity } from "../../shared/service/flow.service";
import { StepEntity } from "../../shared/service/step.service";

export const flowLoadedSuccess = createAction(
  "[Flows API] Flow Loaded Success",
  props<{
    flow: any;
    template: any;
    editorPath?: string;
    panelId?: string;
    isDirty?: boolean;
  }>()
);

export const flowLoadedFailure = createAction(
  "[Flows API] Flow Loaded Failure",
  props<{ error: any }>()
);

export const stepTemplateLoadedSuccess = createAction(
  "[Flows API] Step Template Loaded Success",
  props<{ step: StepEntity }>()
);

export const stepTemplateLoadedFailure = createAction(
  "[Flows API] Step Template Loaded Failure",
  props<{ error: any }>()
);

export const stepPreviewLoadedSuccess = createAction(
  "[Flows API] Step Preview Loaded Success",
  props<{ data: StepEntity }>()
);

export const stepPreviewLoadedFailure = createAction(
  "[Flows API] Step Preview Loaded Failure",
  props<{ error: any }>()
);

export const flowSavedSuccess = createAction(
  "[Flows API] Flow Saved Success",
  props<{ flow: FlowEntity }>()
);

export const flowSavedFailure = createAction(
  "[Flows API] Flow Saved Failure",
  props<{ error: any }>()
);

export const flowDuplicatedSuccess = createAction(
  "[Flows API] Flow Duplicated Success"
);

export const flowDuplicatedFailure = createAction(
  "[Flows API] Flow Duplicated Failure",
  props<{ error: any }>()
);

export const flowDeletedSuccess = createAction(
  "[Flows API] Flow Deleted Success"
);

export const flowDeletedFailure = createAction(
  "[Flows API] Flow Deleted Failure",
  props<{ error: any }>()
);

export const firstStepTemplateLoadedSuccess = createAction(
  "[Flows API] First Step Template Loaded Success",
  props<{ step: StepEntity }>()
);

export const firstStepTemplateLoadedFailure = createAction(
  "[Flows API] First Step Template Loaded Failure",
  props<{ error: any }>()
);

export const templateLoadedSuccess = createAction(
  "[Flows API] Template Loaded Success",
  props<{
    template: StepTemplate;
    path: string;
    insert?: true;
    initialData?: any;
  }>()
);

export const templateLoadedFailure = createAction(
  "[Flows API] Template Loaded Failure",
  props<{ error: any }>()
);

export const flowsLoadedSuccess = createAction(
  "[Flows API] Flows Loaded Success",
  props<{ category: Category; flows: ResourceSummary[] }>()
);

export const flowsLoadedFailure = createAction(
  "[Flows API] Flows Loaded Failure",
  props<{ error: any }>()
);
