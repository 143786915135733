import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LogoService {
  constructor() {}

  get(variant: "dark" | "white" = "dark"): string {
    if (variant === "white") {
      return "/assets/images/brand/nickelled-logo-white.svg";
    }

    return "https://cdn.nickelled.com/colours-and-shapes/0.5.2/images/logo.svg";
  }

  isOmniplexGuide() {
    return false;
  }

  getTenantName() {
    return "Nickelled";
  }
}
