<div class="flex flex-col w-full h-full shadow-inner">
  <div class="flex-shrink-0 p-4">
    <app-site-select></app-site-select>
  </div>

  <div class="flex flex-col flex-1 space-y-8">
    <div class="space-y-1">
      <app-vertical-nav-link
        *ngIf="isOnSearchPage$ | async"
        class="block"
        title="Search Results"
        [link]="['/search']"
        icon="search"
      >
      </app-vertical-nav-link>
      <app-vertical-nav-link
        class="block"
        title="Guides"
        [link]="['/guides']"
        icon="annotation"
      >
      </app-vertical-nav-link>
      <app-vertical-nav-link
        *ngIf="!isOmniplexGuide && hasLaunchersTab$ | async"
        class="block"
        title="Launchers"
        [link]="['/launchers']"
        icon="question-mark-circle"
      >
      </app-vertical-nav-link>
      <app-vertical-nav-link
        *ngIf="!isOmniplexGuide && hasWidgetsBeta$ | async"
        class="block"
        title="Widgets"
        [link]="['/widgets']"
        icon="sparkles"
      >
      </app-vertical-nav-link>
      <app-vertical-nav-link
        *ngIf="!isOmniplexGuide && hasAcademiesTab$ | async"
        class="block"
        title="Academies"
        [link]="['/academies']"
        icon="library"
      >
      </app-vertical-nav-link>
      <app-vertical-nav-link
        *ngIf="!isOmniplexGuide"
        class="block"
        title="Help Assistant"
        [link]="['/assistant']"
        icon="support"
      >
      </app-vertical-nav-link>
      <app-vertical-nav-link
        *ngIf="!isOmniplexGuide && hasThemeBeta$ | async"
        class="block"
        title="Theme"
        [link]="['/theme']"
        icon="color-swatch"
      >
      </app-vertical-nav-link>
      <app-vertical-nav-link
        class="block"
        title="Analytics"
        [link]="['/analytics']"
        icon="chart-pie"
      >
      </app-vertical-nav-link>
      <app-vertical-nav-link
        *ngIf="!isOmniplexGuide"
        class="block"
        title="Distribution"
        [link]="['/distribution']"
        icon="share"
      >
      </app-vertical-nav-link>
    </div>
  </div>

  <div class="flex flex-col flex-shrink-0 mt-6 mb-8">
    <app-vertical-nav-link
      *ngIf="!isOmniplexGuide || (isAdmin$ | async)"
      class="block"
      title="Settings"
      [link]="['/settings']"
      icon="cog"
    >
    </app-vertical-nav-link>
  </div>
</div>
