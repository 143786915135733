import { createAction, props } from "@ngrx/store";

export const updateCurrentSite = createAction(
  "[Site Integration] Update Current Site",
  props<{ name?: string; url?: string; themeResourceId?: string }>()
);

export const updateSite = createAction(
  "[Site Integration] Update Site",
  props<{ id: number; name?: string; url?: string; themeResourceId?: string }>()
);

export const createSite = createAction(
  "[Site Integration] Create Site",
  props<{ name?: string }>()
);

export const renameSite = createAction(
  "[Site Integration] Rename Site",
  props<{ id: number; name: string }>()
);
export const deleteSite = createAction(
  "[Site Integration] Delete Site",
  props<{ id: number }>()
);
