<div
  [routerLink]="link"
  routerLinkActive
  #rla="routerLinkActive"
  class="ml-4 -mr-px cursor-pointer"
>
  <ng-container *ngIf="link.length === 0 || !rla.isActive; else active">
    <span
      class="flex items-center px-5 py-3 pr-4 text-sm font-medium text-gray-600 border border-r-0 border-transparent hover:text-ni-blue-700 rounded-l-xl group"
    >
      <span class="grid flex-shrink-0 w-6 h-6 mr-3 -ml-1 place-items-center">
        <hero-icon *ngIf="icon" [name]="icon" size="1.5rem"></hero-icon>
      </span>
      <span class="truncate">{{ title }}</span>
    </span>
  </ng-container>
  <ng-template #active>
    <span
      class="flex items-center px-5 py-3 pr-4 text-sm font-medium border border-r-0 shadow-sm border-ni-blue-500 text-ni-blue-700 rounded-l-xl bg-ni-blue-450 group"
    >
      <span class="grid flex-shrink-0 w-6 h-6 mr-3 -ml-1 place-items-center">
        <hero-icon *ngIf="icon" [name]="icon" size="1.5rem"></hero-icon>
        <ng-content selector="svg, [icon]"></ng-content>
      </span>
      <span class="truncate">{{ title }}</span>
    </span>
  </ng-template>
</div>
