import {
  animate,
  group,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SchemaField } from "src/app/shared/models/schema";

@Component({
  selector: "app-accordion-group",
  templateUrl: "./accordion-group.component.html",
  styleUrls: ["./accordion-group.component.scss"],
  animations: [
    trigger("openClose", [
      state("closed", style({ height: "0px", opacity: 0, overflow: "hidden" })),
      state("open", style({ height: "*", opacity: 1, overflow: "visible" })),
      transition(":enter", [animate(0)]),
      transition(
        "* => closed",
        group([
          animate("200ms", style({ height: "0px", opacity: 0 })),
          animate("200ms steps(1,start)", style({ overflow: "hidden" })),
        ])
      ),
      transition("* => open", [
        animate("200ms", style({ height: "*", opacity: 1 })),
        animate("200ms steps(1,end)", style({ overflow: "visible" })),
      ]),
    ]),
  ],
})
export class AccordionGroupComponent implements OnInit {
  @Input() label = "";
  @Input() controls: SchemaField[] = [];
  @Input() opened = false;
  @Input() hidden = false;
  @Output() toggle = new EventEmitter<null>();
  @Output() advance = new EventEmitter<null>();
  @Output() drilldown = new EventEmitter<[string, number]>();
  @Output() moveItems = new EventEmitter<[string, number, number]>();
  @Output() addItem = new EventEmitter<[string, string]>();
  @Output() action = new EventEmitter<string>();

  get _opened() {
    return !this.label || this.opened;
  }

  constructor() {}

  ngOnInit(): void {}

  toggleFieldset() {
    this.toggle.emit();
  }

  handleDrilldown(data: [string, number]) {
    this.drilldown.emit(data);
  }

  handleMoveItems(key: string, [fromIndex, toIndex]: [number, number]) {
    this.moveItems.emit([key, fromIndex, toIndex]);
  }

  handleAddItem(key: string, templateId: string) {
    this.addItem.emit([key, templateId]);
  }

  handleAction(data: string) {
    this.action.emit(data);
  }
}
