import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { HttpService } from "./http.service";

export type SiteEntity = {
  id: number;
  appId: string;
  companyId: number;
  createdAt: string;
  deletedAt: string | null;
  lastSeenAt: string;
  liveAt: string;
  name: string;
  type: "default" | "salesforce";
  updatedAt: string;
  url: string;
  useBasicAuth: boolean;
  userId: number;
  themeResourceId: string;
};

export type SiteCreateParams = {
  name: string;
  useBasicAuth: boolean;
  url: string;
};

@Injectable({
  providedIn: "root",
})
export class SiteService {
  constructor(private httpService: HttpService) {}

  list(): Observable<SiteEntity[]> {
    const url = `${environment.apiHost}/v2/sites`;
    return this.httpService.get(url, { withCredentials: true });
  }

  get(id: number): Observable<SiteEntity> {
    const url = `${environment.apiHost}/sites`;
    return this.httpService.get(url, { withCredentials: true }).pipe(
      map((sites) => {
        return sites.find((site: SiteEntity) => site.id === id);
      })
    );
  }

  create(site: SiteCreateParams) {
    const url = `${environment.apiHost}/v2/sites`;
    return this.httpService.post(url, site, { withCredentials: true });
  }

  update(id: number, site: any) {
    const data = {
      ...site,
      id: undefined,
      appId: undefined,
    };

    const url = `${environment.apiHost}/sites/${id}`;
    return this.httpService.post(url, data, { withCredentials: true });
  }

  delete(id: number) {
    const url = `${environment.apiHost}/sites/${id}`;
    return this.httpService.del(url, { withCredentials: true });
  }

  getBlankSite(data: Partial<SiteEntity> = {}): any {
    return {
      id: null,
      appId: null,
      companyId: null,
      createdAt: null,
      deletedAt: null,
      lastSeenAt: null,
      liveAt: null,
      name: "",
      type: "default",
      updatedAt: null,
      url: "",
      useBasicAuth: false,
      userId: null,
      ...data,
    };
  }
}
