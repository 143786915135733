import * as ExtensionActions from "@actions/extension.actions";
import { createReducer, on } from "@ngrx/store";

export type State = {
  isSearching: boolean;
  detectedStatus:
    | null
    | {
        reachable: false;
      }
    | {
        reachable: true;
        version: string;
      };
};

export const initialState: State = {
  isSearching: false,
  detectedStatus: null,
};

const _extensionReducer = createReducer(
  initialState,
  on(
    ExtensionActions.checkStatus,
    (state): State => ({
      ...state,
      isSearching: true,
    })
  ),
  on(
    ExtensionActions.checkStatusSuccess,
    (state, { version }): State => ({
      ...state,
      detectedStatus: {
        reachable: true,
        version,
      },
    })
  ),
  on(
    ExtensionActions.checkStatusTimeout,
    (state): State => ({
      ...state,
      detectedStatus: {
        reachable: false,
      },
    })
  ),
  on(
    ExtensionActions.checkStatusSuccess,
    ExtensionActions.checkStatusTimeout,
    (state): State => ({
      ...state,
      isSearching: false,
    })
  )
);

export function reducer(state: any, action: any) {
  return _extensionReducer(state, action);
}
