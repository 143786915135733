import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { ROUTER_NAVIGATED } from "@ngrx/router-store";
import { Store } from "@ngrx/store";
import {
  selectRouteCategoryIdParam,
  selectRouteFlowIdParam,
} from "@selectors/router.selectors";
import {
  selectActiveSite,
  selectActiveSiteId,
} from "@selectors/sites.selectors";
import { get } from "object-path-immutable";
import { of } from "rxjs";
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  exhaustMap,
  filter,
  map,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { GuideLauncherService } from "src/app/playback/services/guide-launcher.service";
import { TemplateService } from "src/app/schema-form/service/template.service";
import { isNonNull } from "src/app/shared/helpers/type-guards";
import { FlashService } from "src/app/shared/service/flash.service";
import { FlowService } from "src/app/shared/service/flow.service";
import { ResourceService } from "src/app/shared/service/resource.service";
import { StepTemplateService } from "src/app/shared/service/step-template.service";
import { environment } from "src/environments/environment";
import * as EditorActions from "../actions/editor.actions";
import * as FlowsApiActions from "../actions/flows-api.actions";
import { AppState } from "../reducers";
import {
  selectActiveFlow,
  selectActiveFlowId,
  selectActiveFlowIsDirty,
  selectActiveFlowUrl,
  selectPreviewData,
} from "../selectors/flow.selectors";

@Injectable()
export class FlowEffects {
  newFlow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditorActions.newFlow),
      exhaustMap(({ siteId, flowType, panelId }) => {
        return this.templateService.get(flowType).pipe(
          map((template) => {
            return FlowsApiActions.flowLoadedSuccess({
              flow: { ...template.defaults, $templateId: flowType, siteId },
              template,
              editorPath: "",
              panelId,
              isDirty: true,
            });
          })
        );
      }),

      catchError((error) =>
        of(FlowsApiActions.flowLoadedFailure({ error: error.message }))
      )
    )
  );

  loadFlowFromUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      concatMap(() => this.store.select(selectRouteFlowIdParam)),
      distinctUntilChanged(),
      map((id) => {
        if (!id || id === "new") return EditorActions.clearFlow();
        return EditorActions.loadFlow({ id });
      })
    )
  );

  loadFlowFromCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FlowsApiActions.flowsLoadedSuccess),
      concatLatestFrom(() => this.store.select(selectRouteFlowIdParam)),
      map(([{ category, flows }, flowId]) => {
        if (flowId) return;

        if (category.hasSingleResource && flows.length === 1) {
          return EditorActions.loadFlow({ id: flows[0].id });
        }

        if (!category.hasSingleResource || flows.length === 0) {
          return EditorActions.clearFlow();
        }

        return null;
      }),
      filter(isNonNull)
    )
  );

  reloadOnAbandon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditorActions.abandonFlow),
      withLatestFrom(
        this.store.select(selectActiveFlowId),
        this.store.select(selectActiveFlowIsDirty)
      ),
      filter(([, , isDirty]) => {
        if (!isDirty) return true;

        const ABANDON_MESSAGE =
          "You will lose any changes you've made to this flow. Are you sure you want to continue?";

        return confirm(ABANDON_MESSAGE);
      }),
      map(([, id]) => {
        if (id) {
          return EditorActions.loadFlow({ id });
        }

        return EditorActions.clearFlow();
      })
    )
  );

  loadFlow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditorActions.loadFlow),
      filter(({ id }) => !!id),
      exhaustMap(({ id }) => {
        return this.resourceService.get(id).pipe(
          map(({ data, template }) =>
            FlowsApiActions.flowLoadedSuccess({
              flow: { ...data, $templateId: template.id },
              template: template,
            })
          )
        );
      }),

      catchError((error) =>
        of(FlowsApiActions.flowLoadedFailure({ error: error.message }))
      )
    )
  );

  saveFlow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditorActions.saveFlow),
      withLatestFrom(
        this.store.select(selectActiveFlow),
        this.store.select(selectActiveSiteId)
      ),
      exhaustMap(([, flow, siteId]) => {
        if (
          flow.$templateId !== "page-assistant-1" &&
          flow.$templateId !== "theme-1"
        ) {
          return this.flowService.saveOrUpdate(flow);
        } else if (flow.id) {
          return this.resourceService.update(flow.id.toString(), flow);
        }

        return this.resourceService.create({ ...flow, siteId });
      }),
      map((flow) => FlowsApiActions.flowSavedSuccess({ flow })),
      catchError((error) =>
        of(FlowsApiActions.flowSavedFailure({ error: error.message }))
      )
    )
  );

  updatePreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        FlowsApiActions.templateLoadedSuccess,
        EditorActions.updateValue,
        FlowsApiActions.flowLoadedSuccess
      ),
      concatMap(() => this.store.select(selectPreviewData)),
      concatLatestFrom(() => this.store.select(selectActiveSite)),
      filter(([{ data }]) => !!data?.$templateId),
      exhaustMap(([{ data, language, themeId }, { themeResourceId }]) => {
        return this.stepTemplateService
          .preview(data.$templateId, data, language, themeId || themeResourceId)
          .pipe(
            map((preview) => EditorActions.previewLoadedSuccess({ preview })),
            catchError((error) =>
              of(EditorActions.previewLoadedFailure({ error: error.message }))
            )
          );
      })
    )
  );

  updateEditorPosition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        EditorActions.updateEditorPosition,
        EditorActions.removeCurrentItem
      ),
      withLatestFrom(this.store.select(selectActiveFlow)),
      exhaustMap(([props, data]) => {
        const path = "path" in props ? props.path : "";

        const templateId = path
          ? get(data, path)?.$templateId
          : data.$templateId;

        return this.stepTemplateService.get(templateId).pipe(
          map((template) =>
            FlowsApiActions.templateLoadedSuccess({ template, path })
          ),
          catchError((error) =>
            of(FlowsApiActions.templateLoadedFailure({ error: error.message }))
          )
        );
      })
    )
  );

  returnToFlows$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          FlowsApiActions.flowSavedSuccess,
          FlowsApiActions.flowDeletedSuccess
        ),
        withLatestFrom(
          this.store.select(selectActiveFlow),
          this.store.select(selectRouteCategoryIdParam)
        ),
        map(([action, flow, categoryId]) => {
          if (!categoryId) return;
          const flowId = flow?.id;
          const isDelete = action.type === "[Flows API] Flow Deleted Success";
          if (!categoryId) return ["/"];
          if (!flowId || isDelete) return ["/", categoryId];
          return ["/", categoryId, flowId];
        }),
        tap((commands) => this.router.navigate(commands))
      ),
    { dispatch: false }
  );

  deleteFlow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditorActions.deleteFlow),
      withLatestFrom(this.store.select(selectActiveFlowId)),
      exhaustMap(([, id]) =>
        this.resourceService.delete(id.toString()).pipe(
          map(() => FlowsApiActions.flowDeletedSuccess()),
          catchError((error) =>
            of(FlowsApiActions.flowDeletedFailure({ error: error.message }))
          )
        )
      )
    )
  );

  duplicateFlow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditorActions.duplicateFlow),
      withLatestFrom(this.store.select(selectActiveFlowId)),
      exhaustMap(([, id]) =>
        this.resourceService.duplicate(id.toString()).pipe(
          map(() => FlowsApiActions.flowDuplicatedSuccess()),
          catchError((error) =>
            of(FlowsApiActions.flowDuplicatedFailure({ error: error.message }))
          )
        )
      )
    )
  );

  openMake$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EditorActions.nextPanel),
        withLatestFrom(this.store.select(selectActiveFlow)),
        tap(([, data]: [never, any]) => {
          const {
            startingUrl = "",
            guideType = "",
            deviceType = "",
            siteId = null,
          } = data;

          if (
            guideType !== "snapshot" ||
            !startingUrl ||
            !deviceType ||
            !siteId
          ) {
            return;
          }

          const url = new URL(environment.makeHost);
          url.pathname = `/device/${deviceType}/create`;
          url.searchParams.append("address", startingUrl);
          url.searchParams.append("redirectTo", "app");
          url.searchParams.append("siteId", siteId);
          window.location.href = url.href;
        })
      ),
    { dispatch: false }
  );

  copyLink$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EditorActions.copyLink),
        withLatestFrom(this.store.select(selectActiveFlowUrl)),
        tap(async ([, url]) => {
          if (!url) return;

          try {
            await navigator.clipboard.writeText(url);
            this.flashService.set({
              title: "Link copied",
              description:
                "A shareable link for this guide has been copied to your clipboard.",
              status: "success",
            });
          } catch (err) {
            prompt("Copy the text below to your clipboard", url);
          }
        })
      ),
    { dispatch: false }
  );

  preview$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EditorActions.previewFlow),
        withLatestFrom(
          this.store.select(selectActiveFlow),
          this.store.select(selectActiveFlowUrl)
        ),
        tap(([, flow, url]) => {
          if (flow.$templateId === "customerService") {
            window.open(url);
            return;
          }

          let urlToOpen = flow?.startingUrl ?? "";

          if (this.urlNeedsConfirmation(urlToOpen)) {
            urlToOpen = prompt(
              "Please confirm the URL where the preview should start",
              urlToOpen
            );
          }

          if (!urlToOpen) return;

          this.guideLauncherService.previewFlow(
            Number(flow.id),
            flow["Site"].appId,
            urlToOpen
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private flowService: FlowService,
    private stepTemplateService: StepTemplateService,
    private store: Store<AppState>,
    private router: Router,
    private resourceService: ResourceService,
    private templateService: TemplateService,
    private flashService: FlashService,
    private guideLauncherService: GuideLauncherService
  ) {}

  private urlNeedsConfirmation(urlToOpen: string) {
    return !urlToOpen || urlToOpen.includes("*") || urlToOpen.includes("?");
  }
}
