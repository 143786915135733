import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Schema } from "../models/schema";
import { HttpService } from "./http.service";

export type StepEntity = {
  template: {
    id?: string;
    name?: string;
    schema?: Schema;
    category?: string;
  };
  data: {
    [key: string]: any;
  };
  preview?: {
    [key: string]: any;
  };
  summary?: string;
  pageId?: number;
};

@Injectable({
  providedIn: "root",
})
export class StepService {
  constructor(private httpService: HttpService) {}

  list(flowId: number): Observable<StepEntity[]> {
    const url = `${environment.apiHost}/v2/flows/${flowId}/steps`;
    return this.httpService.get(url, { withCredentials: true });
  }

  updateAll(flowId: number, steps: StepEntity[]) {
    const url = `${environment.apiHost}/v2/flows/${flowId}/steps`;
    return this.httpService.put(url, steps, { withCredentials: true });
  }

  loadTemplate(flowId: number, id: string) {
    const url = `${environment.apiHost}/flows/${flowId}/steps/templates/${id}`;
    return this.httpService.get(url, { withCredentials: true });
  }

  preview(flowId: number, stepId: number, template: string, data: any) {
    const url = `${environment.apiHost}/flows/${flowId}/steps/${stepId}/preview?template=${template}`;

    return this.httpService.post(url, data, {
      withCredentials: true,
    });
  }
}
