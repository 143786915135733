import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class HttpService {
  public authFail = false;

  constructor(private http: HttpClient) {}

  public get(url: string, options?: any): Observable<any> {
    return this.http.get(url, options);
  }

  public post(url: string, data: any = null, options?: any): Observable<any> {
    return this.http.post(url, data, options);
  }

  public postFile(url: string, data: any, options = {}): Observable<any> {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");

    return this.http.post(url, data, { ...options, headers });
  }

  public put(url: string, data: any = null, options?: any): Observable<any> {
    return this.http.put(url, data, options);
  }

  public del(url: string, options?: any): Observable<any> {
    return this.http.delete(url, options);
  }
}
