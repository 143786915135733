import { userLoggedOut } from "@actions/auth.actions";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "@reducers/.";
import { selectFlag } from "@selectors/flags.selectors";
import { selectSearchQuery } from "@selectors/router.selectors";
import { selectActiveUser, selectIsSuperUser } from "@selectors/user.selectors";
import { map } from "rxjs/operators";
import { LogoService } from "../../service/logo.service";

@Component({
  selector: "app-site-header",
  templateUrl: "./site-header.component.html",
  styleUrls: ["./site-header.component.scss"],
})
export class SiteHeaderComponent implements OnInit {
  searchQuery$ = this.store.select(selectSearchQuery);
  email$ = this.store.select(selectActiveUser).pipe(map((user) => user?.email));
  isSuperUser$ = this.store.select(selectIsSuperUser);
  hasSearchBar$ = this.store.select(selectFlag("search-bar"));
  public logoPath = "/assets/images/brand/nickelled-logo-white.svg";
  
  isOmniplexGuide = this.logoService.isOmniplexGuide();

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private logoService: LogoService
  ) {}

  ngOnInit(): void {
    this.logoPath = this.logoService.get("white");
  }

  newGuide() {
    window.open("https://make.nickelled.com");
  }

  setSearchQuery(searchQuery: string) {
    if (searchQuery) {
      this.router.navigate(["/search"], { queryParams: { q: searchQuery } });
    } else {
      this.router.navigate(["/"]);
    }
  }

  logOut() {
    this.store.dispatch(userLoggedOut());
  }
}
