import * as SitesApiActions from "@actions/sites-api.actions";
import { Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { ROUTER_NAVIGATED } from "@ngrx/router-store";
import { Store } from "@ngrx/store";
import { selectActiveSiteId } from "@selectors/sites.selectors";
import { of } from "rxjs";
import {
  catchError,
  distinctUntilChanged,
  exhaustMap,
  filter,
  map,
} from "rxjs/operators";
import { ResourceCategoriesService } from "src/app/shared/service/resource-categories.service";
import { ResourceService } from "src/app/shared/service/resource.service";
import * as FlowsApiActions from "../actions/flows-api.actions";
import * as FlowsIndexPageActions from "../actions/flows-index-page.actions";
import * as NavBarActions from "../actions/nav-bar.actions";
import { AppState } from "../reducers";
import { selectRouteFlowTypeParam } from "../selectors/router.selectors";

@Injectable()
export class FlowsEffects {
  loadNewCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      concatLatestFrom(() => this.store.select(selectRouteFlowTypeParam)),
      distinctUntilChanged(),
      map(() => FlowsIndexPageActions.loadFlows()),
      filter((category) => !!category)
    )
  );

  loadFlows$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        FlowsIndexPageActions.loadFlows,
        FlowsApiActions.flowDuplicatedSuccess,
        FlowsApiActions.flowDeletedSuccess,
        FlowsApiActions.flowSavedSuccess,
        NavBarActions.selectSite,
        SitesApiActions.sitesLoadedSuccess
      ),
      concatLatestFrom(() => this.store.select(selectRouteFlowTypeParam)),
      concatLatestFrom(() => this.store.select(selectActiveSiteId)),
      map(([[, flowTypeUrlParam], siteId]) => ({
        flowTypeUrlParam,
        siteId,
      })),
      filter(({ flowTypeUrlParam, siteId }) => !!flowTypeUrlParam && !!siteId),
      exhaustMap(({ flowTypeUrlParam, siteId }) => {
        const category =
          this.resourceCategoriesService.getByUrlParam(flowTypeUrlParam);

        const id = category?.id;

        return this.resourceService.listByCategory(id, siteId).pipe(
          map((resources) =>
            FlowsApiActions.flowsLoadedSuccess({
              category,
              flows: resources,
            })
          ),
          catchError((error) =>
            of(FlowsApiActions.flowsLoadedFailure({ error }))
          )
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private resourceService: ResourceService,
    private resourceCategoriesService: ResourceCategoriesService
  ) {}
}
