import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-field-textarea",
  templateUrl: "./field-textarea.component.html",
  styleUrls: ["./field-textarea.component.scss"],
})
export class FieldTextareaComponent implements OnInit {
  @Input() value = "";
  @Output() change = new EventEmitter<string>();
  control = new FormControl(this.value);

  constructor() {}

  ngOnInit(): void {}
}
