<div>
  <div
    class="flex items-center flex-shrink p-3 border-b cursor-pointer border-ni-blue-475"
    (click)="toggleFieldset()"
    *ngIf="label"
  >
    <div
      class="grid w-8 font-bold text-center text-ni-blue-700 place-items-center"
    >
      <hero-icon
        [name]="opened ? 'chevron-down-solid' : 'chevron-right-solid'"
        size="1.2rem"
      >
      </hero-icon>
    </div>
    <h3 class="flex-grow text-sm font-medium text-ni-blue-700">
      {{ label }}
    </h3>
  </div>

  <div
    class="flex-grow"
    [class]="label ? 'border-b border-ni-blue-475 bg-ni-blue-375' : ''"
    [@openClose]="_opened ? 'open' : 'closed'"
  >
    <div class="grid grid-cols-2 p-5 pb-0">
      <ng-content></ng-content>
    </div>
  </div>
</div>
