<div class="relative h-full">
  <div class="h-full" #el [hidden]="(panel$ | async) === 'loading'"></div>
  <ng-container [ngSwitch]="panel$ | async">
    <div *ngSwitchCase="'auth'">
      <div class="absolute inset-0 grid place-items-center">
        <app-portal-auth></app-portal-auth>
      </div>
    </div>
    <div
      *ngSwitchCase="'loading'"
      class="absolute inset-0 flex items-center justify-center"
    >
      <div class="flex flex-col items-center">
        <svg
          class="block w-32 h-auto text-white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
          width="32"
          height="32"
          fill="currentColor"
        >
          <circle transform="translate(8 0)" cx="0" cy="16" r="0">
            <animate
              attributeName="r"
              values="0; 4; 0; 0"
              dur="1.2s"
              repeatCount="indefinite"
              begin="0"
              keytimes="0;0.2;0.7;1"
              keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
              calcMode="spline"
            />
          </circle>
          <circle transform="translate(16 0)" cx="0" cy="16" r="0">
            <animate
              attributeName="r"
              values="0; 4; 0; 0"
              dur="1.2s"
              repeatCount="indefinite"
              begin="0.3"
              keytimes="0;0.2;0.7;1"
              keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
              calcMode="spline"
            />
          </circle>
          <circle transform="translate(24 0)" cx="0" cy="16" r="0">
            <animate
              attributeName="r"
              values="0; 4; 0; 0"
              dur="1.2s"
              repeatCount="indefinite"
              begin="0.6"
              keytimes="0;0.2;0.7;1"
              keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
              calcMode="spline"
            />
          </circle>
        </svg>
      </div>
    </div>
  </ng-container>
</div>
