<div
  *ngIf="(activeRules$ | async)?.length; else noValue"
  class="-space-y-px bg-white rounded-md shadow-sm isolate"
>
  <label
    *ngFor="
      let rule of activeRules$ | async;
      let first = first;
      let last = last;
      trackBy: trackByFn
    "
    [class.rounded-t-md]="first"
    class="relative block px-3 py-2 border border-gray-300 focus-within:z-10 focus-within:ring-1 focus-within:ring-ni2-orange-500 focus-within:border-ni2-orange-500"
  >
    <div class="text-sm font-medium text-gray-700">{{ rule.label }}</div>
    <input
      type="text"
      placeholder="Enter a value"
      [value]="rule.value"
      (input)="updateValue(rule.id, $event.target.value)"
      class="block p-0 text-gray-900 placeholder-gray-500 border-0 focus:ring-0 sm:text-sm"
    />
  </label>
  <app-dropdown class="block" expandDirection="right">
    <div
      trigger
      class="flex items-center justify-center px-4 py-2 space-x-2 border border-gray-300 cursor-pointer bg-gray-50 hover:bg-gray-100 rounded-b-md"
    >
      <span class="text-sm font-medium text-gray-600"
        >Refine position&hellip;</span
      >
    </div>
    <div menu class="divide-y divide-gray-100">
      <div
        *ngFor="let category of inactiveRules$ | async"
        class="py-1"
        role="none"
      >
        <div
          class="px-4 py-2 text-xs font-semibold tracking-wider text-gray-500 uppercase"
        >
          {{ category.title }}
        </div>
        <button
          *ngFor="let rule of category.rules"
          (click)="toggleRule(rule.id, !rule.enabled)"
          class="flex items-center justify-between w-full px-4 py-2 text-sm"
          role="menuitem"
          tabindex="-1"
          [ngSwitch]="rule.enabled"
        >
          <ng-container *ngSwitchCase="true">
            <span class="text-gray-400">{{ rule.label }}</span>
            <hero-icon name="minus-sm-solid" class="text-red-400"></hero-icon>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <span class="text-gray-700">{{ rule.label }}</span>
            <hero-icon name="plus-solid" class="text-green-400"></hero-icon>
          </ng-container>
        </button>
      </div>
      <div class="py-1" role="none">
        <button
          class="flex items-center justify-between w-full px-4 py-2 space-x-2 text-sm text-gray-700"
          role="menuitem"
          tabindex="-1"
          (click)="activateElementSelection()"
        >
          <span class="text-ni2-orange-500">Select a new element</span>
          <hero-icon
            name="cursor-click-solid"
            class="text-ni2-orange-400"
          ></hero-icon>
        </button>
      </div>
    </div>
  </app-dropdown>
</div>

<ng-template #noValue>
  <app-button
    title="Select an element"
    icon="above"
    iconName="cursor-click"
    (click)="activateElementSelection()"
  >
  </app-button>
</ng-template>
