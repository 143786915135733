<button
  [type]="type"
  [class]="buttonCssClasses"
  [attr.title]="tooltip || null"
  (click)="handleClick($event)"
>
  <ng-container *ngIf="status === 'enabled'">
    <span
      class="flex items-center justify-center w-4 h-4 bg-green-100 rounded-full"
      aria-hidden="true"
    >
      <span class="w-2 h-2 bg-green-400 rounded-full"></span>
    </span>
  </ng-container>
  <ng-container *ngIf="status === 'disabled'">
    <span
      class="flex items-center justify-center w-4 h-4 bg-gray-100 rounded-full"
      aria-hidden="true"
    >
      <span class="w-2 h-2 bg-gray-400 rounded-full"></span>
    </span>
  </ng-container>
  <ng-container *ngIf="icon === 'before'">
    <hero-icon
      *ngIf="iconName"
      [name]="iconName"
      [class.text-gray-400]="variant === 'plain'"
      size="1.25rem"
    >
    </hero-icon>
    <span *ngIf="title">{{ title }}</span>
  </ng-container>
  <ng-content selector="svg"></ng-content>
  <ng-container *ngIf="icon === 'after' || icon === 'above'">
    <hero-icon
      *ngIf="iconName"
      [name]="iconName"
      [class.text-gray-400]="variant === 'plain'"
      size="1.25rem"
    >
    </hero-icon>
    <span *ngIf="title">{{ title }}</span>
  </ng-container>
</button>
