import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SafeUrlPipe } from "./safe-url.pipe";
import { UserNameFormatPipe } from "./user-name-format.pipe";

@NgModule({
  declarations: [UserNameFormatPipe, SafeUrlPipe],
  imports: [CommonModule],
  exports: [UserNameFormatPipe, SafeUrlPipe],
})
export class PipeModule {}
