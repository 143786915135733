import { Component, HostListener, NgZone, OnInit } from "@angular/core";
import { MessageChannelService } from "../../services/message-channel.service";

@Component({
  selector: "app-message-channel",
  templateUrl: "./message-channel.component.html",
  styleUrls: ["./message-channel.component.scss"],
})
export class MessageChannelComponent implements OnInit {
  constructor(
    private messageChannelService: MessageChannelService,
    private zone: NgZone
  ) {}

  ngOnInit(): void {}

  @HostListener("window:message", ["$event"])
  onMessage(event: MessageEvent) {
    if (!event?.data?.event?.startsWith("NICKELLED_")) {
      return;
    }

    this.zone.run(() => {
      this.messageChannelService.rx$.next(event.data);
    });
  }
}
