<datalist id="tag-datalist">
  <option *ngFor="let tag of tagsForDatalist" [value]="tag"></option>
</datalist>

<div class="flex flex-wrap">
  <span
    *ngFor="let tag of tags"
    class="inline-flex rounded-full h-5 items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-ni-blue-600 text-white mr-1 mb-1"
  >
    {{ tag }}
    <button
      type="button"
      (click)="handleRemove(tag)"
      class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-white hover:bg-ni-blue-700 hover:text-white focus:outline-none focus:bg-ni-blue-700 focus:text-white"
    >
      <span class="sr-only">Remove tag</span>
      <svg class="w-2 h-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
        <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
      </svg>
    </button>
  </span>

  <form *ngIf="inputOpen" class="flex" (submit)="handleAdd(tagInput.value)">
    <span
      class="inline-flex items-center h-5 p-0.5 text-sm font-medium bg-ni-blue-600 text-white mr-1 mb-1 rounded-full"
    >
      <input
        type="text"
        list="tag-datalist"
        #tagInput
        class="box-border block h-4 px-2 py-0 text-sm font-medium text-white bg-transparent border-none rounded-full focus:ring-0"
        [style.caret-color]="'white'"
      />
      <button
        type="submit"
        class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-white hover:bg-ni-blue-700 hover:text-white focus:outline-none focus:bg-ni-blue-700 focus:text-white"
      >
        <span class="sr-only">Add tag</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-3 h-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </span>
  </form>

  <span
    *ngIf="!inputOpen && !tags.length"
    class="inline-flex rounded-full items-center h-5 py-0.5 px-0.5 pl-2.5 text-sm font-medium bg-ni-blue-600 text-white mr-1 mb-1"
  >
    Add tag
    <button
      type="button"
      (click)="setInputOpenState(true)"
      class="inline-flex ml-0.5 items-center justify-center flex-shrink-0 w-4 h-4 text-white rounded-full hover:bg-ni-blue-700 hover:text-white focus:outline-none focus:bg-ni-blue-700 focus:text-white"
    >
      <span class="sr-only">Add tag</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-3 h-3"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
  </span>

  <span
    *ngIf="!inputOpen && tags.length"
    class="inline-grid rounded-full place-items-center h-5 w-5 p-0.5 text-sm font-medium bg-ni-blue-600 text-white mr-1 mb-1"
  >
    <button
      type="button"
      (click)="setInputOpenState(true)"
      class="grid flex-shrink-0 w-4 h-4 text-white rounded-full place-items-center hover:bg-ni-blue-700 hover:text-white focus:outline-none focus:bg-ni-blue-700 focus:text-white"
    >
      <span class="sr-only">Add tag</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-3 h-3"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
  </span>
</div>
