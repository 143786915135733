import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl } from "@angular/forms";

const QUILL_MODULES = {
  toolbar: [
    [
      "bold",
      "italic",
      "underline",
      { list: "ordered" },
      { list: "bullet" },
      { align: [] },
    ],
    ["link", "image", "video", { header: [1, 2, 3, false] }],
  ],
};

@Component({
  selector: "app-field-html",
  templateUrl: "./field-html.component.html",
  styleUrls: ["./field-html.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FieldHtmlComponent implements OnInit {
  @Input() value = "";
  @Output() update = new EventEmitter<string>();
  control = new FormControl(this.value);
  quillModules = QUILL_MODULES;

  emitUpdates$ = this.control.valueChanges.subscribe((value) =>
    this.update.emit(value)
  );

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes["value"]) return;

    const { currentValue } = changes["value"];

    if (this.control.value !== currentValue) {
      this.control.setValue(currentValue, {
        emitEvent: false,
      });
    }
  }
}
