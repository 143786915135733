<label *ngIf="label" class="block mb-1 text-sm font-medium">{{ label }}</label>
<input
  [type]="accept"
  [value]="value"
  (input)="writeValue($event.target.value)"
  class="block w-full mb-2 text-gray-900 border-gray-300 rounded-md shadow-sm focus:ring-ni-blue-600 focus:border-ni-blue-600 sm:text-sm"
  [class.text-gray-600]="disabled"
  [class.bg-gray-50]="disabled"
  [class.cursor-not-allowed]="disabled"
  [placeholder]="placeholder"
  [disabled]="disabled"
/>
<span class="text-sm text-gray-500">
  <ng-content select="[help]"></ng-content>
</span>
