import * as AuthApiActions from "@actions/auth-api.actions";
import { createReducer, on } from "@ngrx/store";
import { User } from "../../shared/models/user";

export type State = {
  data: User;
  loading: boolean;
  error: Error | null;
};

export const initialState: State = {
  data: null,
  loading: true,
  error: null,
};

const _authReducer = createReducer(
  initialState,
  on(
    AuthApiActions.userLoadedSuccess,
    (state, { data }): State => ({
      ...state,
      data,
      loading: false,
      error: null,
    })
  )
);

export function reducer(state: any, action: any) {
  return _authReducer(state, action);
}
