import { APP_BASE_HREF, CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ComponentsModule } from "./components/components.module";
import { FullPageComponent } from "./layouts/full-page/full-page.component";
import { PipeModule } from "./pipe/pipe.module";

@NgModule({
  declarations: [FullPageComponent],
  imports: [CommonModule, ComponentsModule],
  exports: [
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ComponentsModule,
    PipeModule,
    FullPageComponent,
    // BrowserAnimationsModule
  ],
  providers: [{ provide: APP_BASE_HREF, useValue: "/" }],
})
export class SharedModule {}
