import { createAction, props } from "@ngrx/store";

export const loadInvoiceListSuccess = createAction(
  "[Settings API] Load Invoice List Success",
  props<{ invoices: any[] }>()
);

export const loadInvoiceListFailure = createAction(
  "[Settings API] Load Invoice List Failure"
);

export const loadPaymentSettingsSuccess = createAction(
  "[Settings API] Load Payment Settings Success",
  props<{ ccExpiryMonth: string; ccExpiryYear: string; ccLast4: string }>()
);

export const loadPaymentSettingsFailure = createAction(
  "[Settings API] Load Payment Settings Failure"
);

export const updatePaymentSettingsSuccess = createAction(
  "[Settings API] Update Payment Settings Success",
  props<{ ccExpiryMonth: string; ccExpiryYear: string; ccLast4: string }>()
);

export const updatePaymentSettingsFailure = createAction(
  "[Settings API] Update Payment Settings Failure"
);

export const updateContactDetailsSuccess = createAction(
  "[Settings API] Update Contact Details Success",
  props<{ firstName: string; secondName: string; email: string }>()
);

export const updateContactDetailsFailure = createAction(
  "[Settings API] Update Contact Details Failure"
);

export const updatePasswordSuccess = createAction(
  "[Settings API] Update Password Success",
  props<{ oldPassword: string; newPassword: string }>()
);

export const updatePasswordFailure = createAction(
  "[Settings API] Update Password Failure"
);

export const updateEmailSubscriptionsSuccess = createAction(
  "[Settings API] Update Email Subscriptions Success",
  props<{ weeklyEmail: boolean }>()
);

export const updateEmailSubscriptionsFailure = createAction(
  "[Settings API] Update Email Subscriptions Failure"
);

export const removeTeamMemberSuccess = createAction(
  "[Settings API] Remove Team Member Success",
  props<{ id: number }>()
);

export const removeTeamMemberFailure = createAction(
  "[Settings API] Remove Team Member Failure"
);

export const addTeamMemberSuccess = createAction(
  "[Settings API] Add Team Member Success",
  props<{ email: string }>()
);

export const addTeamMemberFailure = createAction(
  "[Settings API] Add Team Member Failure"
);

export const cancelInvitationSuccess = createAction(
  "[Settings API] Cancel Invitation Success",
  props<{ id: number }>()
);

export const cancelInvitationFailure = createAction(
  "[Settings API] Cancel Invitation Failure"
);

export const resendInvitationSuccess = createAction(
  "[Settings API] Resend Invitation Success",
  props<{ id: number }>()
);

export const resendInvitationFailure = createAction(
  "[Settings API] Resend Invitation Failure"
);

export const createInvitationSuccess = createAction(
  "[Settings API] Create Invitation Success",
  props<{ email: string }>()
);

export const createInvitationFailure = createAction(
  "[Settings API] Create Invitation Failure"
);

export const updateInvoiceNotificationEmailsSuccess = createAction(
  "[Settings API] Update Invoice Notification Emails Success",
  props<{ emails: string }>()
);

export const updateInvoiceNotificationEmailsFailure = createAction(
  "[Settings API] Update Invoice Notification Emails Failure"
);

export const teamMembersLoadedSuccess = createAction(
  "[Settings API] Team Members Loaded Success",
  props<{ teamMembers: any[]; invitations: any[] }>()
);

export const teamMembersLoadedFailure = createAction(
  "[Settings API] Team Members Loaded Failure"
);

export const setRolesSuccess = createAction(
  "[Settings API] Set Roles Success",
  props<{ id: number; roleNames: string[] }>()
);

export const setRolesFailure = createAction("[Settings API] Set Roles Failure");
