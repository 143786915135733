import { createAction, props } from "@ngrx/store";

export const loadFlowTemplates = createAction(
  "[Flow Index] Load Flow Templates"
);

export const loadFlows = createAction("[Flow Index] Load Flows");

export const createFirstSite = createAction(
  "[Flow Index] Create First Site",
  props<{ url: string }>()
);

export const navigateToFirstFlow = createAction(
  "[Flow Index] Navigate To First Flow"
);

export const setFlowsSearchQuery = createAction(
  "[Flows Index] Set Search Query",
  props<{ searchQuery: string }>()
);

export const changeFlowsPage = createAction(
  "[Flows Index] Change Flows Page",
  props<{ offset: number }>()
);
