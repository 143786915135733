import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { selectActiveSiteId } from "@selectors/sites.selectors";
import { BehaviorSubject, Observable } from "rxjs";
import { concatMap, distinctUntilChanged, filter, map } from "rxjs/operators";
import { SelectMenuItem } from "src/app/shared/components/select-menu/select-menu.component";
import { ResourceService } from "src/app/shared/service/resource.service";
import { AppState } from "src/app/store/reducers";

@Component({
  selector: "app-field-resource-select",
  templateUrl: "./field-resource-select.component.html",
  styleUrls: ["./field-resource-select.component.scss"],
})
export class FieldResourceSelectComponent implements OnInit, OnChanges {
  @Input() value = "";
  @Input() placeholder = "";
  @Input() resourceType = null;

  siteId$ = this.store.select(selectActiveSiteId);
  resourceType$ = new BehaviorSubject<string>(null);
  items$: Observable<SelectMenuItem[]> = this.resourceType$.pipe(
    distinctUntilChanged(),
    filter((type) => type !== null),
    concatMap((type) =>
      this.resourceService.listByCategory(type).pipe(
        map((items) => {
          const out = items.map((item) => ({ id: item.id, title: item.name }));
          if (type === "theme") {
            return [{ id: null, title: "Site default" }, ...out];
          }
          return out;
        })
      )
    )
  );

  @Output() update = new EventEmitter<any>();

  constructor(
    private store: Store<AppState>,
    private resourceService: ResourceService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    const resourceType = changes["resourceType"];

    if (resourceType) {
      this.resourceType$.next(resourceType.currentValue);
    }
  }

  handleChange(id: any) {
    this.update.emit(id);
  }
}
