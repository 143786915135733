import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from "@angular/core";
import { get, set } from "object-path-immutable";
import { BehaviorSubject, combineLatest } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";

type ControlGroup = { label: null | string; controls: any[] };

@Component({
  selector: "app-schema-form",
  templateUrl: "./schema-form.component.html",
})
export class SchemaFormComponent implements OnDestroy {
  @Input() set formName(value: string) {
    this.formName$.next(value);
  }

  @Input() set value(value: any) {
    this.value$.next(value);
  }

  @Input() set schema(value: any[]) {
    this.schema$.next(value);
  }

  @Output() valid = new EventEmitter<any>();
  @Output() update = new EventEmitter<any>();
  @Output() action = new EventEmitter<{
    type: string;
    key: string;
  }>();

  formName$ = new BehaviorSubject("");
  value$ = new BehaviorSubject<any>({});
  schema$ = new BehaviorSubject<any[]>([]);
  filteredSchema$ = combineLatest([
    this.schema$,
    this.formName$,
    this.value$,
  ]).pipe(
    map(([schema, formName, value]) =>
      this.getFilteredSchema(schema, formName, value)
    ),
    distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y))
  );
  groups$ = this.filteredSchema$.pipe(
    map((filteredSchema) =>
      filteredSchema.reduce(this.reduceSchemaToControlGroupArray, [])
    )
  );
  validSubscription = combineLatest([
    this.value$,
    this.filteredSchema$,
  ]).subscribe(([value, filteredSchema]) => {
    const valid = this.isValueValid(value, filteredSchema);
    this.valid.emit(valid);
  });

  getValue$(key: string) {
    return this.value$.pipe(map((value) => get(value, key, null)));
  }

  handleUpdate(key: string, valueForKey: any) {
    if (!key) return;
    const value = set(this.value$.value, key, valueForKey);
    this.update.emit(value);
  }

  handleAction(key: string, action: { key: string; type: string }) {
    this.action.emit({
      key: [key, action.key].filter((x) => !!x).join("."),
      type: action.type,
    });
  }

  ngOnDestroy(): void {
    this.validSubscription.unsubscribe();
  }

  private isValueValid(value: any, filteredSchema: any[]) {
    return filteredSchema
      .filter((field) => field.key && field.required)
      .every((field) => !!get(value, field.key));
  }

  private reduceSchemaToControlGroupArray(
    acc: ControlGroup[],
    cur: any
  ): ControlGroup[] {
    const { type = null, label = null, controls = [] } = cur;

    if (type === "group" || type === "heading") {
      acc.push({ label, controls });
    } else if (acc.length) {
      acc[acc.length - 1].controls = [...acc[acc.length - 1].controls, cur];
    } else {
      acc.push({ label: null, controls: [cur] });
    }

    return acc;
  }

  private getFilteredSchema(schema: any[], formName: string, value: any) {
    if (!Array.isArray(schema)) return [];

    return schema
      .filter(this.isOfForm(formName))
      .filter(this.isIfConditionMet(value));
  }

  private isOfForm(formName: string) {
    return (cur) => (!cur.form && !formName) || cur.form === formName;
  }

  private isIfConditionMet(data) {
    return (def) => {
      if (!def?.if) return true;

      let show = Object.entries(def.if).every(([key, expected]: any) => {
        const val = get(data, key);
        const str = new String(val ?? "");

        if (str && expected.indexOf("*") !== -1) {
          return true;
        }

        return expected.indexOf(str.valueOf()) !== -1;
      });

      return show;
    };
  }
}
