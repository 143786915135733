import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { concatMap, filter, withLatestFrom } from "rxjs/operators";
import { TemplateService } from "../../service/template.service";

@Component({
  selector: "app-field-child-items",
  templateUrl: "./field-child-items.component.html",
})
export class FieldChildItemsComponent implements OnInit, OnChanges {
  @Input() value = [];
  @Input() templates: any[] = [];
  @Input() maxItems = 100;
  @Input() help = "Add a step...";
  @Output() update = new EventEmitter<any>();
  @Output() action = new EventEmitter<{ type: string; key: string }>();

  drop$ = new Subject<[number, number]>();
  value$ = new Subject<any[]>();
  list$ = new BehaviorSubject<any[]>([]);

  updateList$ = this.value$
    .pipe(concatMap((items: any[]) => this.templateService.runAll(items)))
    .subscribe((list) => this.list$.next(list));

  fireDroppedEvent$ = this.drop$
    .pipe(
      withLatestFrom(this.list$),
      filter(([[fromIndex, toIndex]]) => fromIndex !== toIndex)
    )
    .subscribe(([[fromIndex, toIndex], list]: [[number, number], any[]]) => {
      const indexOfLastFixedItem = list
        .map((item) => item.isFixedStep)
        .lastIndexOf(true);

      if (
        fromIndex <= indexOfLastFixedItem ||
        toIndex <= indexOfLastFixedItem
      ) {
        alert(
          `The first ${indexOfLastFixedItem + 1} item(s) can not be moved.`
        );
      } else if (Array.isArray(this.value)) {
        const array = [...this.value];
        moveItemInArray(array, fromIndex, toIndex);
        this.update.emit(array);
      }
    });

  constructor(private templateService: TemplateService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["value"]) {
      this.value$.next(changes["value"].currentValue);
    }
  }

  handleClick(index: number) {
    this.action.emit({ type: "edit", key: index.toString() });
  }

  handleDrop(event: CdkDragDrop<string[]>) {
    this.drop$.next([event.previousIndex, event.currentIndex]);
  }

  handleAdd(templateId: string) {
    const value = this.value ?? [];
    const changedValue = [...value, { $templateId: templateId }];
    const key = (changedValue.length - 1).toString();
    this.update.emit(changedValue);
    this.action.emit({ type: "edit", key });
  }
}
