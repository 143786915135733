import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-vertical-nav-link",
  templateUrl: "./vertical-nav-link.component.html",
})
export class VerticalNavLinkComponent implements OnInit {
  @Input() title = "";
  @Input() icon = "";
  @Input() link: string[] = [];

  constructor() {}

  ngOnInit(): void {}
}
