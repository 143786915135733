<div
  class="grid h-full bg-gradient-to-br from-ni-blue-450 to-ni-blue-500 place-items-center"
>
  <app-panel-generic class="block w-full max-w-lg">
    <div class="flex flex-col justify-center p-12 space-y-8 h-96">
      <h1 class="text-2xl font-medium text-center text-gray-900">
        Please click continue to open this guide&hellip;
      </h1>
    </div>

    <div class="flex items-center justify-end h-16 px-4 bg-gray-50">
      <app-button title="Continue" (click)="launchGuide()"> </app-button>
    </div>
  </app-panel-generic>
</div>
