import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import {
  adjustmentsSolid,
  annotation,
  arrowCircleLeft,
  arrowCircleRight,
  arrowDownSolid,
  arrowsExpand,
  badgeCheckSolid,
  check,
  chevronDoubleLeft,
  chevronDoubleLeftSolid,
  chevronDown,
  chevronDownSolid,
  chevronRightSolid,
  chevronUp,
  clipboardListSolid,
  clock,
  cursorClick,
  cursorClickSolid,
  documentText,
  documentTextSolid,
  dotsVerticalSolid,
  exclamationCircle,
  globeAlt,
  HeroIconsModule,
  informationCircle,
  lightBulbSolid,
  lightningBolt,
  minus,
  minusSmSolid,
  pencil,
  play,
  playSolid,
  plus,
  plusCircleSolid,
  plusSolid,
  questionMarkCircle,
  questionMarkCircleSolid,
  searchSolid,
  speakerphoneSolid,
  starSolid,
  supportSolid,
  trashSolid,
  userGroupSolid,
  variable,
  viewList,
  x,
  xCircleSolid,
} from "ng-heroicons";
import { QuillModule } from "ngx-quill";
import { ComponentsModule } from "../shared/components/components.module";
import { SharedModule } from "../shared/shared.module";
import { AccordionGroupComponent } from "./components/accordion-group/accordion-group.component";
import { AccordionComponent } from "./components/accordion/accordion.component";
import { FieldArrayComponent } from "./components/field-array/field-array.component";
import { FieldBooleanComponent } from "./components/field-boolean/field-boolean.component";
import { FieldChildItemsComponent } from "./components/field-child-items/field-child-items.component";
import { FieldColorComponent } from "./components/field-color/field-color.component";
import { FieldCriteriaBuilderComponent } from "./components/field-criteria-builder/field-criteria-builder.component";
import { FieldEnumComponent } from "./components/field-enum/field-enum.component";
import { FieldGalleryComponent } from "./components/field-gallery/field-gallery.component";
import { FieldHtmlComponent } from "./components/field-html/field-html.component";
import { FieldInfoComponent } from "./components/field-info/field-info.component";
import { FieldInputComponent } from "./components/field-input/field-input.component";
import { FieldInstallPromptComponent } from "./components/field-install-prompt/field-install-prompt.component";
import { FieldListComponent } from "./components/field-list/field-list.component";
import { FieldPageSelectorComponent } from "./components/field-page-selector/field-page-selector.component";
import { FieldRelativePositionComponent } from "./components/field-relative-position/field-relative-position.component";
import { FieldResourceSelectComponent } from "./components/field-resource-select/field-resource-select.component";
import { FieldSiteSelectComponent } from "./components/field-site-select/field-site-select.component";
import { FieldTagsComponent } from "./components/field-tags/field-tags.component";
import { FieldTargetV2Component } from "./components/field-target-v2/field-target-v2.component";
import { FieldTargetComponent } from "./components/field-target/field-target.component";
import { FieldTextareaComponent } from "./components/field-textarea/field-textarea.component";
import { FieldZoomComponent } from "./components/field-zoom/field-zoom.component";
import { SchemaFieldComponent } from "./components/schema-field/schema-field.component";
import { SchemaFormComponent } from "./components/schema-form/schema-form.component";

@NgModule({
  declarations: [
    SchemaFormComponent,
    FieldCriteriaBuilderComponent,
    FieldTargetComponent,
    AccordionComponent,
    AccordionGroupComponent,
    FieldPageSelectorComponent,
    FieldChildItemsComponent,
    FieldBooleanComponent,
    FieldInfoComponent,
    FieldEnumComponent,
    FieldHtmlComponent,
    FieldZoomComponent,
    FieldTextareaComponent,
    FieldInputComponent,
    SchemaFieldComponent,
    FieldRelativePositionComponent,
    FieldResourceSelectComponent,
    FieldColorComponent,
    FieldArrayComponent,
    FieldGalleryComponent,
    FieldTagsComponent,
    FieldSiteSelectComponent,
    FieldInstallPromptComponent,
    FieldTargetV2Component,
    FieldListComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ComponentsModule,
    DragDropModule,
    QuillModule.forRoot(),
    HeroIconsModule.withIcons({
      annotation,
      arrowsExpand,
      check,
      chevronDown,
      chevronDoubleLeft,
      chevronUp,
      cursorClick,
      globeAlt,
      informationCircle,
      lightningBolt,
      pencil,
      play,
      plus,
      questionMarkCircle,
      x,
      dotsVerticalSolid,
      xCircleSolid,
      chevronDoubleLeftSolid,
      trashSolid,
      documentText,
      arrowDownSolid,
      userGroupSolid,
      adjustmentsSolid,
      starSolid,
      variable,
      playSolid,
      documentTextSolid,
      clipboardListSolid,
      speakerphoneSolid,
      lightBulbSolid,
      supportSolid,
      badgeCheckSolid,
      questionMarkCircleSolid,
      plusSolid,
      chevronDownSolid,
      chevronRightSolid,
      clock,
      viewList,
      arrowCircleRight,
      arrowCircleLeft,
      minus,
      exclamationCircle,
      cursorClickSolid,
      plusCircleSolid,
      searchSolid,
      minusSmSolid,
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [SchemaFormComponent, FieldGalleryComponent, FieldInputComponent],
})
export class SchemaFormModule {}
