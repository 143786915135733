import { checkStatus } from "@actions/extension.actions";
import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "@reducers/.";
import { selectExtensionData } from "@selectors/extension.selectors";
import { selectQueryParams } from "@selectors/router.selectors";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { LogoService } from "src/app/shared/service/logo.service";

const WEB_STORE_URL = {
  default:
    "https://chrome.google.com/webstore/detail/nickelled-flows-player/hdnceiiiecbijdidkkbfeoomfnagfagj",
};

type WizardStage = "start" | "install" | "done" | "loading";

type WizardRedirect = {
  message: string;
  doneButtonText: string | null;
  routerLink: string[];
};

@Component({
  selector: "app-extension-setup",
  templateUrl: "./extension-setup.component.html",
  styleUrls: ["./extension-setup.component.scss"],
})
export class ExtensionSetupComponent implements OnInit {
  constructor(
    private store: Store<AppState>,
    private logoService: LogoService
  ) {}

  status$ = this.store.select(selectExtensionData);
  tenantName = this.logoService.getTenantName();

  redirect$: Observable<WizardRedirect> = this.store
    .select(selectQueryParams)
    .pipe(
      map((params = {}) => {
        const { redirectTo = null, id = null } = params;

        if (redirectTo === "guides" && id) {
          return {
            message: "Complete this wizard to preview your guide",
            doneButtonText: "Done",
            routerLink: ["/guides", id],
          };
        }

        return {
          message: `Complete this wizard to prepare your browser for use with ${this.tenantName}`,
          doneButtonText: redirectTo === "none" ? null : "Done",
          routerLink: ["/"],
        };
      })
    );

  stage$ = this.status$.pipe(
    map((extension): WizardStage => {
      if (extension.isSearching) return "loading";
      if (extension.detectedStatus === null) return "start";
      if (!extension.detectedStatus.reachable) return "install";
      if (extension.detectedStatus.version) return "done";
      return "start";
    })
  );

  ngOnInit() {}

  runStatusCheck() {
    this.store.dispatch(checkStatus());
  }

  openStore() {
    const url = WEB_STORE_URL["default"];
    window.open(url);
  }
}
