<ng-container *ngIf="editMode; else firstRunMode">
  <div class="relative rounded-md">
    <input
      type="text"
      [value]="value"
      (input)="setValue($event.target.value)"
      class="block w-full border-gray-300 rounded-md sm:text-sm focus:outline-none focus:ring-ni2-orange-400 focus:border-ni2-orange-400"
    />
  </div>
  <div class="mt-2">
    <app-button
      variant="plain"
      iconName="refresh"
      title="Select another page..."
      (click)="resetValue()"
    >
    </app-button>
  </div>
  <p class="mt-2 text-xs font-medium text-ni-blue-600">
    The wildcard characters * and ? are supported in this field.
  </p>
</ng-container>
<ng-template #firstRunMode>
  <div class="my-1 mb-4" *ngIf="help">
    <div
      class="flex flex-shrink p-3 space-x-3 text-sm font-medium bg-gray-500 rounded-lg bg-opacity-10"
    >
      <hero-icon name="information-circle" size="1.25rem"></hero-icon>
      <p>{{ help }}</p>
    </div>
  </div>
  <div class="grid gap-4">
    <app-button
      iconName="arrow-left"
      variant="primary"
      icon="above"
      title="Use currently displayed page"
      [disabled]="!currentUrl"
      (click)="useCurrentPage()"
    >
    </app-button>
  </div>
</ng-template>
