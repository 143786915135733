<div
  *ngIf="message"
  [routerLink]="['/settings/plan']"
  class="px-3 py-1 text-sm font-medium text-center text-white rounded-full cursor-pointer bg-ni2-orange-500"
>
  <ng-container [ngSwitch]="message">
    <span *ngSwitchCase="'days-remaining'"
      >{{ trialDaysRemaining }} trial
      {{ trialDaysRemaining === 1 ? "day" : "days" }} remaining</span
    >
    <span *ngSwitchCase="'payment-failed'">Error collecting payment</span>
    <span *ngSwitchCase="'cancellation-pending'"
      >Account cancellation pending</span
    >
    <span *ngSwitchDefault>Account issue</span>
  </ng-container>
</div>
