import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { map } from "rxjs/operators";

type TargetModes = "text" | "text-pattern" | "css";

type TargetSelector =
  | {
      text: { contains: string; before?: string; after?: string };
    }
  | {
      css: string;
    };

@Component({
  selector: "app-field-target",
  templateUrl: "./field-target.component.html",
})
export class FieldTargetComponent implements OnChanges, OnInit {
  mode: TargetModes = "text";

  formGroup = new FormGroup({
    textContains: new FormControl(""),
    textPatternBefore: new FormControl(""),
    textPatternContains: new FormControl(""),
    textPatternAfter: new FormControl(""),
    cssSelector: new FormControl(""),
  });

  outputValue$ = this.formGroup.valueChanges
    .pipe(
      map((value): TargetSelector => {
        if (this.mode === "css") {
          return {
            text: undefined,
            css: value.cssSelector,
          };
        } else if (this.mode === "text-pattern") {
          return {
            text: {
              contains: value.textPatternContains,
              before: value.textPatternBefore,
              after: value.textPatternAfter,
            },
            css: undefined,
          };
        }

        return {
          text: {
            contains: value.textContains,
          },
          css: undefined,
        };
      })
    )
    .subscribe((value) => this.update.emit(value));

  @Input() value: TargetSelector | null = null;
  @Output() update = new EventEmitter<any>();

  ngOnInit() {
    this.updateControls(this.value);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["value"]) {
      this.updateControls(changes["value"].currentValue);
    }
  }

  getInputStyles(valid = true) {
    return valid
      ? "border-gray-300 focus:ring-ni-blue-600 focus:border-ni-blue-600"
      : "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500";
  }

  updateControls(value: any) {
    const text = value?.text ?? { contains: "" };
    const css = value?.css ?? "";

    this.formGroup.setValue(
      {
        textContains: text.contains ?? "",
        textPatternBefore: text.before ?? "",
        textPatternContains: text.contains ?? "",
        textPatternAfter: text.after ?? "",
        cssSelector: css ?? "",
      },
      { emitEvent: false }
    );

    if (text.before || text.after) {
      this.mode = "text-pattern";
    } else if (css) {
      this.mode = "css";
    }
  }

  public onChange: any = () => {};
  public onTouch: any = () => {};
}
