import { createAction, props } from "@ngrx/store";

export const checkStatus = createAction("[Extension] Check Status");

export const checkStatusSuccess = createAction(
  "[Extension] Check Status Success",
  props<{ version: string }>()
);

export const checkStatusTimeout = createAction(
  "[Extension] Check Status Timeout"
);

export const forceSync = createAction("[Extension] Force Sync");

export const forceSyncSuccess = createAction("[Extension] Force Sync Success");

export const forceSyncTimeout = createAction("[Extension] Force Sync Timeout");

export const launchGuide = createAction(
  "[Extension] Launch Guide",
  props<{ id: number; edit: boolean }>()
);

export const launchGuideSuccess = createAction(
  "[Extension] Launch Guide Success"
);

export const launchGuideTimeout = createAction(
  "[Extension] Launch Guide Timeout"
);
