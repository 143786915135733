<div class="grid gap-4" [class]="'grid-cols-' + cols">
  <label
    *ngFor="let option of options"
    (click)="handleChange(option.id)"
    class="relative grid items-center overflow-hidden bg-white border border-gray-300 rounded-lg shadow-sm cursor-pointer hover:border-gray-400"
  >
    <div
      *ngIf="option.imageUrl"
      class="grid h-full p-4 bg-gray-50 place-items-center"
    >
      <img class="mx-auto" [src]="option.imageUrl" alt="" />
    </div>
    <div>
      <div class="flex items-center mx-4 my-3">
        <input
          *ngIf="showRadioControls && !option.hideRadioControl"
          type="radio"
          [name]="name"
          [value]="option.id"
          [checked]="option.id === value"
          class="w-4 h-4 mr-2 border-gray-300 cursor-pointer text-ni2-orange-500 focus:ring-ni2-orange-400"
        />
        <h3 class="mr-auto text-base font-medium text-gray-900">
          {{ option.label }}
        </h3>
        <span
          *ngIf="option.badge"
          class="flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800"
        >
          {{ option.badge }}
        </span>
      </div>
      <div *ngIf="option.description" class="mx-4 my-3">
        <p class="text-sm text-gray-500">{{ option.description }}</p>
      </div>
    </div>
  </label>
</div>
