import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-field-zoom",
  templateUrl: "./field-zoom.component.html",
  styleUrls: ["./field-zoom.component.scss"],
})
export class FieldZoomComponent implements OnInit {
  @Input() maxMin: [number, number] = [1, -3];
  @Input() value = 0;
  @Output() update = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}

  adjustNumber(diff: number) {
    const [max, min] = this.maxMin;
    const newValue = this.value + diff;

    if (newValue >= min && newValue <= max) {
      this.value = newValue;
      this.update.emit(newValue);
    }
  }
}
