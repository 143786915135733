<div class="overflow-hidden">
  <div class="p-12 text-center">
    <svg
      class="inline-block w-48 mb-4"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 145 128"
      id="guide-no-views-new"
    >
      <g fill="none" fill-rule="evenodd">
        <use fill="#E7F1F4" xlink:href="#fa"></use>
        <path
          stroke="#91B3C2"
          stroke-width="2"
          d="M66.586 17L74 9.586 81.414 17H114c9.389 0 17 7.611 17 17v64c0 9.389-7.611 17-17 17H34c-9.389 0-17-7.611-17-17V34c0-9.389 7.611-17 17-17h32.586z"
        ></path>
        <path
          d="M130 64.5V98c0 8.837-7.163 16-16 16H34c-8.837 0-16-7.163-16-16V64.5 95c0 8.837 7.163 16 16 16h80c8.837 0 16-7.163 16-16V64.5z"
          fill="#FFF"
        ></path>
        <use fill="#91B3C2" xlink:href="#fb"></use>
        <path
          stroke="#91B3C2"
          stroke-width="2"
          d="M74 81c12.703 0 23-10.297 23-23S86.703 35 74 35 51 45.297 51 58s10.297 23 23 23zm8.985-23L68 66.741V49.259L82.985 58z"
        ></path>
        <path
          d="M106.337 64.278L104 63l2.337-1.278a6 6 0 0 0 2.385-2.385L110 57l1.278 2.337a6 6 0 0 0 2.385 2.385L116 63l-2.337 1.278a6 6 0 0 0-2.385 2.385L110 69l-1.278-2.337a6 6 0 0 0-2.385-2.385zM92.668 34.022l-1.425-.78 1.425-.779a2 2 0 0 0 .795-.795l.78-1.425.78 1.425a2 2 0 0 0 .794.795l1.426.78-1.426.78a2 2 0 0 0-.795.794l-.78 1.426-.779-1.426a2 2 0 0 0-.795-.795zM40.314 43.376l-2.071-1.133 2.071-1.133a2 2 0 0 0 .796-.796l1.133-2.071 1.133 2.071a2 2 0 0 0 .795.796l2.072 1.133-2.072 1.133a2 2 0 0 0-.795.795l-1.133 2.072-1.133-2.072a2 2 0 0 0-.796-.795z"
          fill="#91B3C2"
        ></path>
        <rect fill="#91B3C2" x="44" y="91" width="60" height="2" rx="1"></rect>
        <rect fill="#91B3C2" x="54" y="96" width="40" height="2" rx="1"></rect>
        <circle fill="#E9EDEF" cx="4" cy="104" r="4"></circle>
        <circle fill="#E9EDEF" cx="130" cy="124" r="4"></circle>
        <circle fill="#E9EDEF" cx="143" cy="102" r="2"></circle>
        <circle fill="#E9EDEF" cx="24" cy="126" r="2"></circle>
        <circle fill="#E9EDEF" cx="14" cy="15" r="2"></circle>
        <circle fill="#E9EDEF" cx="80" cy="2" r="2"></circle>
      </g>
    </svg>
    <div class="flex flex-col mb-4 space-y-2">
      <ng-content></ng-content>
    </div>
  </div>
</div>
