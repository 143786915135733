import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class TeamService {
  constructor(
    private userService: UserService,
    private httpService: HttpService
  ) {}

  defaultHttpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true,
  };

  sendInvite(email: string) {
    return this.httpService.post(
      `/invitations.json`,
      JSON.stringify({ email }),
      this.defaultHttpOptions
    );
  }

  getPendingInvitations() {
    return this.httpService.get(`/invitations.json`, this.defaultHttpOptions);
  }

  getTeamMembers() {
    return this.httpService.get(
      `/company/${this.userService.user.CompanyId}/users.json`,
      {
        withCredentials: true,
      }
    );
  }

  resendInvitation(invitationId: number) {
    return this.httpService.post(
      `/invitations/${invitationId}/resend.json`,
      {},
      this.defaultHttpOptions
    );
  }

  deleteInvitation(invitationId: number) {
    return this.httpService.del(`/invitations/${invitationId}`, {
      ...this.defaultHttpOptions,
      responseType: "text",
    });
  }

  removeTeamMember(teamMemberId: number) {
    return this.httpService.del(
      `/company/${this.userService.user.CompanyId}/users/${teamMemberId}.json`,
      this.defaultHttpOptions
    );
  }
}
