<div class="relative mt-1 rounded-md">
  <input
    [type]="inputType"
    [value]="value"
    [formControl]="control"
    [placeholder]="placeholder || ''"
    class="block rounded-md sm:text-sm focus:outline-none"
    [ngClass]="{
      'border-gray-300 focus:ring-ni2-orange-400 focus:border-ni2-orange-400':
        valid,
      'border-red-300 text-red-300 placeholder-red-300 focus:ring-red-400 focus:border-red-400':
        !valid,
      'w-32': inputType === 'number',
      'w-full': inputType !== 'number'
    }"
  />
  <div
    *ngIf="!valid"
    class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
  >
    <!-- Heroicon name: solid/exclamation-circle -->
    <svg
      class="w-5 h-5 text-red-500"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        fill-rule="evenodd"
        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
        clip-rule="evenodd"
      />
    </svg>
  </div>
  <div *ngIf="help" class="mt-2 text-sm text-ni-blue-600">
    <span [innerHTML]="help"></span>
    <span *ngIf="helpLink">
      (<a [href]="helpLink" target="ni-help" class="underline">info</a>)</span
    >
  </div>
</div>
