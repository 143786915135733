<div
  class="p-3 rounded-lg bg-gradient-to-br from-ni2-orange-300 to-ni2-orange-500"
>
  <div class="flex flex-shrink space-x-3 text-sm font-medium text-white">
    <hero-icon name="exclamation-circle" size="1.25rem"></hero-icon>
    <div>
      <p>
        Before this guide will run on your site, you will need to install the
        JavaScript snippet.
      </p>
      <button
        *ngIf="siteId$ | async as siteId"
        [routerLink]="['/settings/sites/', siteId]"
        class="mt-2 underline"
      >
        View installation instructions
      </button>
    </div>
  </div>
</div>
