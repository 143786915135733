<div *ngIf="panel$ | async as panel">
  <div
    [@fadeInOut]
    class="fixed inset-0 z-20 bg-ni-blue-600 bg-opacity-90"
  ></div>

  <div [@fadeInOut] class="fixed inset-0 z-30 flex h-full">
    <div class="relative flex-grow" [ngSwitch]="panel.previewMode">
      <div class="h-full" *ngSwitchCase="'site'">
        <div
          class="absolute top-0 z-10 w-24 px-4 py-2 -ml-12 text-sm font-medium text-center text-white rounded-b-lg left-1/2 bg-ni-blue-700"
        >
          Preview
        </div>
        <app-portal></app-portal>
      </div>
      <div class="h-full" *ngSwitchCase="'step'">
        <div
          class="absolute top-0 z-10 w-24 px-4 py-2 -ml-12 text-sm font-medium text-center text-white rounded-b-lg left-1/2 bg-ni-blue-700"
        >
          Preview
        </div>
        <div class="h-full preview-pattern-dark">
          <app-preview-flow-step></app-preview-flow-step>
        </div>
      </div>
    </div>

    <div
      class="z-40 flex flex-col h-screen overflow-y-auto border-l shadow bg-ni-blue-400 w-96 border-ni-blue-500"
    >
      <div
        class="flex items-center justify-between p-5 border-b border-ni-blue-500"
      >
        <h2
          class="flex items-center space-x-1 text-lg font-medium text-ni-blue-700"
        >
          <span>{{ panel.title }}</span>
          <hero-icon
            *ngIf="stepNumber$ | async"
            name="chevron-right"
            class="text-opacity-50 text-ni-blue-700"
          >
          </hero-icon>
          <span *ngIf="stepNumber$ | async as stepNumber"
            >Step {{ stepNumber }}</span
          >
        </h2>
        <ng-container [ngSwitch]="controls$ | async">
          <ng-container *ngSwitchCase="'first-step'"></ng-container>
          <button
            *ngSwitchCase="'step'"
            class="grid text-ni-blue-600 hover:text-ni-blue-700 place-items-center"
            (click)="deleteStep()"
          >
            <hero-icon name="trash-solid"></hero-icon>
          </button>
          <button
            *ngSwitchDefault
            class="grid text-ni-blue-600 hover:text-ni-blue-700 place-items-center"
            (click)="closeFlow()"
          >
            <hero-icon name="x-solid"></hero-icon>
          </button>
        </ng-container>
      </div>
      <div class="flex-grow overflow-y-auto">
        <app-schema-form
          [formName]="panel.formName"
          [value]="value$ | async"
          [schema]="schema$ | async"
          (update)="handleUpdate($event)"
          (action)="handleAction($event)"
          (valid)="handleValid($event)"
        >
        </app-schema-form>
      </div>
      <div class="p-4" [ngSwitch]="controls$ | async">
        <app-button
          *ngSwitchCase="'first-step'"
          title="Return to steps"
          [disabled]="!formIsValid"
          variant="secondary"
          (click)="returnToRoot()"
        >
        </app-button>
        <app-button
          *ngSwitchCase="'step'"
          title="Return to steps"
          [disabled]="!formIsValid"
          variant="secondary"
          (click)="returnToRoot()"
        >
        </app-button>
        <app-button
          *ngSwitchCase="'next'"
          title="Next"
          [disabled]="!formIsValid"
          (click)="nextPanel()"
        >
        </app-button>
        <app-button
          *ngSwitchCase="'done'"
          title="Done"
          [disabled]="!formIsValid"
          (click)="closeFlow()"
        >
        </app-button>
        <app-button
          *ngSwitchDefault
          title="Save changes"
          [disabled]="!formIsValid"
          (click)="saveFlow()"
        >
        </app-button>
      </div>
    </div>
  </div>
</div>
