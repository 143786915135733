import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ExtensionSetupComponent } from "./pages/extension-setup/extension-setup.component";
import { LaunchComponent } from "./pages/launch/launch.component";
import { DefaultLayoutComponent } from "./shared/components/default-layout/default-layout.component";
import { HeaderLayoutComponent } from "./shared/components/header-layout/header-layout.component";
import { ResourceLayoutComponent } from "./shared/components/resource-layout/resource-layout.component";
import { AuthGuard } from "./user/auth.guard";
import { InvitationComponent } from "./user/invitation/invitation.component";
import { TrialOrCustomerGuardService } from "./user/trial-or-customer-guard.service";

const routes: Routes = [
  {
    path: "setup",
    component: HeaderLayoutComponent,
    children: [
      {
        path: "",
        component: ExtensionSetupComponent,
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: "launch/:id",
    component: HeaderLayoutComponent,
    children: [
      {
        path: "",
        component: LaunchComponent,
      },
      {
        path: ":mode",
        component: LaunchComponent,
      },
    ],
    canActivate: [AuthGuard],
  },
  { path: "invitation", component: InvitationComponent },
  {
    path: "settings",
    component: DefaultLayoutComponent,
    loadChildren: () =>
      import("./settings/settings.module").then((m) => m.SettingsModule),
    canActivate: [AuthGuard],
  },
  {
    path: "admin",
    component: DefaultLayoutComponent,
    loadChildren: () =>
      import("./admin/admin.module").then((m) => m.AdminModule),
    canActivate: [AuthGuard],
    data: {
      title: "Admin",
    },
  },
  {
    path: "distribution",
    component: DefaultLayoutComponent,
    loadChildren: () =>
      import("./configuration/configuration.module").then(
        (m) => m.ConfigurationModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "theme",
    component: DefaultLayoutComponent,
    loadChildren: () =>
      import("./themes/themes.module").then((m) => m.ThemesModule),
    canActivate: [AuthGuard],
  },
  {
    path: "account",
    loadChildren: () => import("./user/user.module").then((m) => m.UserModule),
  },
  {
    path: "analytics",
    component: DefaultLayoutComponent,
    loadChildren: () =>
      import("./analytics/analytics.module").then((m) => m.AnalyticsModule),
    canActivate: [AuthGuard, TrialOrCustomerGuardService],
  },
  {
    path: "editor",
    loadChildren: () =>
      import("./editor/editor.module").then((m) => m.EditorModule),
    canActivate: [AuthGuard, TrialOrCustomerGuardService],
  },
  {
    path: "academies",
    component: DefaultLayoutComponent,
    loadChildren: () =>
      import("./academies/academies.module").then((m) => m.AcademiesModule),
    canActivate: [AuthGuard, TrialOrCustomerGuardService],
  },
  {
    path: "launchers",
    component: DefaultLayoutComponent,
    loadChildren: () =>
      import("./launchers/launchers.module").then((m) => m.LaunchersModule),
    canActivate: [AuthGuard, TrialOrCustomerGuardService],
  },
  { path: "login", redirectTo: "account/login" },
  { path: "signin", redirectTo: "account/login" },
  { path: "signup", redirectTo: "account/signup" },
  { path: "subscribe", redirectTo: "settings/plan" },
  { path: "subscription", redirectTo: "settings/plan" },
  { path: "cancellation", redirectTo: "settings/cancellation" },
  {
    path: "",
    component: ResourceLayoutComponent,
    loadChildren: () =>
      import("./flows/flows.module").then((m) => m.FlowsModule),
    canActivate: [AuthGuard, TrialOrCustomerGuardService],
  },
  { path: "**", redirectTo: "/" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
