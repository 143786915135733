<div
  class="block w-full overflow-hidden border border-gray-300 rounded-md focus:ring-ni2-orange-400 focus:border-ni2-orange-400 sm:text-sm"
>
  <quill-editor
    [modules]="quillModules"
    [style]="{ 'min-height': '250px', 'background-color': 'white' }"
    [formControl]="control"
  >
  </quill-editor>
</div>
