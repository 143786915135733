import * as FlowsApiActions from "@actions/flows-api.actions";
import * as FlowsIndexPageActions from "@actions/flows-index-page.actions";
import * as NavBarActions from "@actions/nav-bar.actions";
import { createReducer, on } from "@ngrx/store";
import { Category } from "src/app/shared/service/resource-categories.service";
import { ResourceSummary } from "src/app/shared/service/resource.service";

export type State = {
  data: ResourceSummary[];
  loading: boolean;
  error: Error | null;
  category: Category | null;
  searchQuery: string;
  startingIndex: number;
  pageSize: number;
};

export const initialState: State = {
  data: [],
  loading: true,
  error: null,
  category: null,
  searchQuery: "",
  startingIndex: 0,
  pageSize: 8,
};

const _flowsReducer = createReducer(
  initialState,
  on(
    NavBarActions.selectSite,
    (state): State => ({
      ...state,
      loading: true,
    })
  ),
  on(
    FlowsApiActions.flowsLoadedSuccess,
    (state, { flows = [], category = null }): State => ({
      ...state,
      category,
      data: flows,
      loading: false,
      error: null,
    })
  ),
  on(FlowsIndexPageActions.setFlowsSearchQuery, (state, { searchQuery }) => {
    return {
      ...state,
      searchQuery,
      startingIndex: 0,
    };
  }),
  on(FlowsIndexPageActions.changeFlowsPage, (state, { offset }) => {
    const { startingIndex, pageSize } = state;

    let newStartingIndex = startingIndex + offset * pageSize;
    newStartingIndex = Math.max(0, newStartingIndex);
    newStartingIndex = Math.min(newStartingIndex, state.data.length - pageSize);

    return {
      ...state,
      startingIndex: newStartingIndex,
    };
  })
);

export function reducer(state: any, action: any) {
  return _flowsReducer(state, action);
}
