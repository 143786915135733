import * as NavBarActions from "@actions/nav-bar.actions";
import * as SiteIntegrationPageActions from "@actions/site-integration-page.actions";
import * as SitesApiActions from "@actions/sites-api.actions";
import { Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { selectActiveSiteId } from "@selectors/sites.selectors";
import { of } from "rxjs";
import {
  catchError,
  distinctUntilChanged,
  exhaustMap,
  filter,
  map,
  tap,
} from "rxjs/operators";
import { isNonNull } from "src/app/shared/helpers/type-guards";
import { SiteService } from "src/app/shared/service/site.service";
import { UserPreferenceService } from "src/app/shared/service/user-preference.service";
import { AppState } from "src/app/store/reducers";

@Injectable()
export class SitesEffects {
  updateSitePreference$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavBarActions.selectSite),
        map(({ siteId }) => siteId),
        distinctUntilChanged(),
        filter(isNonNull),
        tap((siteId) => this.userPreferenceService.setSiteId(siteId))
      ),
    { dispatch: false }
  );

  loadSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        NavBarActions.loadSites,
        SitesApiActions.siteCreatedSuccess,
        SitesApiActions.siteUpdatedSuccess,
        SitesApiActions.siteDeletedSuccess
      ),
      concatLatestFrom(() => this.store.select(selectActiveSiteId)),
      exhaustMap(([, activeSiteId]) => {
        const siteId = activeSiteId ?? this.userPreferenceService.getSiteId();

        return this.siteService.list().pipe(
          map((sites) => SitesApiActions.sitesLoadedSuccess({ sites, siteId })),
          catchError((error) =>
            of(SitesApiActions.sitesLoadedFailure({ error }))
          )
        );
      })
    )
  );

  updateCurrentSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteIntegrationPageActions.updateCurrentSite),
      concatLatestFrom(() => this.store.select(selectActiveSiteId)),
      exhaustMap(([{ name, url, themeResourceId }, siteId]) =>
        this.siteService.update(siteId, { name, url, themeResourceId }).pipe(
          map((site) => SitesApiActions.siteUpdatedSuccess({ site })),
          catchError((error) =>
            of(SitesApiActions.siteUpdatedFailure({ error }))
          )
        )
      )
    )
  );

  createSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteIntegrationPageActions.createSite),
      exhaustMap(({ name }) =>
        this.siteService.create({ name, useBasicAuth: false, url: "" }).pipe(
          map((site) => SitesApiActions.siteCreatedSuccess({ site })),
          catchError((error) =>
            of(SitesApiActions.siteCreatedFailure({ error }))
          )
        )
      )
    )
  );

  updateSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteIntegrationPageActions.updateSite),
      exhaustMap(({ id, name, url, themeResourceId }) =>
        this.siteService.update(id, { name, url, themeResourceId }).pipe(
          map((site) => SitesApiActions.siteUpdatedSuccess({ site })),
          catchError((error) =>
            of(SitesApiActions.siteUpdatedFailure({ error }))
          )
        )
      )
    )
  );

  deleteSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteIntegrationPageActions.deleteSite),
      exhaustMap(({ id }) =>
        this.siteService.delete(id).pipe(
          map((site) => SitesApiActions.siteDeletedSuccess({ siteId: id })),
          catchError((error) =>
            of(SitesApiActions.siteDeletedFailure({ error }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private siteService: SiteService,
    private store: Store<AppState>,
    private userPreferenceService: UserPreferenceService
  ) {}
}
