<span class="relative z-0 inline-flex rounded-md shadow-sm">
  <button
    type="button"
    (click)="adjustNumber(-1)"
    class="relative inline-flex items-center p-1 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ni2-orange-500 focus:border-ni2-orange-500"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="w-5 h-5"
      [class.text-gray-300]="value === maxMin[1]"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fill-rule="evenodd"
        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M5 8a1 1 0 011-1h4a1 1 0 110 2H6a1 1 0 01-1-1z"
        clip-rule="evenodd"
      />
    </svg>
  </button>
  <button
    type="button"
    (click)="adjustNumber(1)"
    class="relative inline-flex items-center p-1 -ml-px text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-md hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ni2-orange-500 focus:border-ni2-orange-500"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="w-5 h-5"
      [class.text-gray-300]="value === maxMin[0]"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        d="M5 8a1 1 0 011-1h1V6a1 1 0 012 0v1h1a1 1 0 110 2H9v1a1 1 0 11-2 0V9H6a1 1 0 01-1-1z"
      />
      <path
        fill-rule="evenodd"
        d="M2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8zm6-4a4 4 0 100 8 4 4 0 000-8z"
        clip-rule="evenodd"
      />
    </svg>
  </button>
</span>
