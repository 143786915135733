import * as ConfigurationActions from "@actions/configuration.actions";
import * as FlowsApiActions from "@actions/flows-api.actions";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, exhaustMap, map } from "rxjs/operators";
import { ThemeService } from "src/app/shared/service/theme.service";

@Injectable()
export class ConfigurationEffects {
  loadThemes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ConfigurationActions.loadThemes,
        FlowsApiActions.flowSavedSuccess,
        FlowsApiActions.flowDeletedSuccess
      ),
      exhaustMap(() => {
        return this.themeService.list().pipe(
          map((data) =>
            ConfigurationActions.loadThemesSuccess({
              data,
            })
          ),
          catchError(() => of(ConfigurationActions.loadThemesFailure()))
        );
      })
    )
  );

  constructor(private actions$: Actions, private themeService: ThemeService) {}
}
