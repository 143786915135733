import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { selectCurrentUrl } from "@selectors/flow.selectors";
import { AppState } from "src/app/store/reducers";

@Component({
  selector: "app-field-page-selector",
  templateUrl: "./field-page-selector.component.html",
  styleUrls: ["./field-page-selector.component.scss"],
})
export class FieldPageSelectorComponent implements OnInit {
  @Input() value = "";
  @Input() help = "";
  @Output() update = new EventEmitter<string>();
  currentUrl = "";
  editMode = false;

  constructor(private store: Store<AppState>) {
    this.store
      .select(selectCurrentUrl)
      .subscribe((url) => (this.currentUrl = url ?? ""));
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if ("value" in changes && changes["value"].currentValue) {
      this.editMode = true;
    }
  }

  useCurrentPage() {
    this.setValue(this.currentUrl);
  }

  setValue(value: string) {
    this.value = value;
    if (this.value) this.editMode = true;
    this.update.emit(value);
  }

  resetValue() {
    this.setValue("");
    this.editMode = false;
  }
}
