import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";

export const selectFlows = (state: AppState) => state.flows;

export const selectAllFlows = createSelector(
  selectFlows,
  (state) => state.data
);

export const selectFlowsLoading = createSelector(
  selectFlows,
  (state) => state.loading
);

export const selectCategory = createSelector(
  (state: AppState) => state,
  (state) => state.flows?.category
);

export const selectCategoryHasMultipleResources = createSelector(
  (state: AppState) => state,
  (state) =>
    !(state.flows?.category?.hasSingleResource && state.flows.data.length <= 1)
);

export const selectFlowResults = createSelector(
  selectFlows,
  selectCategory,
  (state, category) => {
    const { searchQuery = "", data, startingIndex, pageSize } = state;
    const lowercaseSearchQuery = searchQuery?.toLowerCase();

    const allRows = data.filter(
      (flow) =>
        !lowercaseSearchQuery ||
        flow.name?.toLowerCase().indexOf(lowercaseSearchQuery) !== -1
    );

    return allRows;
  }
);
