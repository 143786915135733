import { loadSites } from "@actions/nav-bar.actions";
import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { selectFlag } from "@selectors/flags.selectors";
import { selectSearchQuery } from "@selectors/router.selectors";
import { selectUserHasRole } from "@selectors/user.selectors";
import { AppState } from "../../../store/reducers";
import { LogoService } from "../../service/logo.service";

@Component({
  selector: "app-side-nav",
  templateUrl: "./side-nav.component.html",
  styleUrls: ["./side-nav.component.scss"],
})
export class SideNavComponent implements OnInit {
  isOmniplexGuide = this.logoService.isOmniplexGuide();
  isOnSearchPage$ = this.store.select(selectSearchQuery);
  hasWidgetsBeta$ = this.store.select(selectFlag("widgets-beta"));
  hasThemeBeta$ = this.store.select(selectFlag("theme-beta"));
  hasAcademiesTab$ = this.store.select(selectFlag("academies-tab"));
  hasLaunchersTab$ = this.store.select(selectFlag("launchers-tab"));
  isAdmin$ = this.store.select(selectUserHasRole(["ACCOUNT_OWNER"]));

  constructor(
    private store: Store<AppState>,
    private logoService: LogoService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(loadSites());
  }
}
