import {
  abandonFlow,
  nextPanel,
  removeCurrentItem,
  saveFlow,
  updateEditorPosition,
  updateValue,
} from "@actions/editor.actions";
import { animate, style, transition, trigger } from "@angular/animations";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  selectActivePanel,
  selectActiveTemplateData,
  selectActiveTemplatePath,
  selectActiveTemplateSchema,
  selectFlow,
} from "@selectors/flow.selectors";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { EventTrackingService } from "../shared/service/event-tracking.service";
import { AppState } from "../store/reducers";

@Component({
  selector: "app-editor",
  templateUrl: "./editor.component.html",
  animations: [
    trigger("fadeInOut", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate("200ms ease-in", style({ opacity: 1 })),
      ]),
      transition(":leave", [animate("200ms ease-in", style({ opacity: 0 }))]),
    ]),
    trigger("slideInOut", [
      transition(":enter", [
        style({ transform: "translateX(100%)" }),
        animate("400ms ease-in", style({ transform: "translateX(0%)" })),
      ]),
      transition(":leave", [
        animate("400ms ease-in", style({ transform: "translateX(100%)" })),
      ]),
    ]),
  ],
})
export class EditorComponent implements OnInit, OnDestroy {
  formIsValid = false;
  flow$ = this.store.select(selectFlow);
  panel$ = this.store.select(selectActivePanel);
  path$ = this.store.select(selectActiveTemplatePath);
  controls$ = combineLatest([this.flow$, this.panel$, this.path$]).pipe(
    map(([flow, panel, path]) => {
      if (path === "steps.0") return "first-step";
      if (path) return "step";
      if (!flow.data?.id && panel?.nextPanelId) return "next";
      if (!flow.isDirty) return "done";
      return "save";
    })
  );
  stepNumber$ = this.path$.pipe(
    map((path) =>
      path.startsWith("steps.") ? Number(path.split(".").pop()) + 1 : null
    )
  );
  value$ = this.store.select(selectActiveTemplateData);
  schema$ = this.store
    .select(selectActiveTemplateSchema)
    .pipe(map((tpl) => tpl?.schema ?? []));

  constructor(
    private eventTrackingService: EventTrackingService,
    private store: Store<AppState>
  ) {}

  handleUpdate(value: any) {
    this.store.dispatch(updateValue({ path: "", value, extend: false }));
  }

  handleAction(action: { key: string; type: string }) {
    if (!this.formIsValid) return;
    if (action.type !== "edit") return;
    this.store.dispatch(updateEditorPosition({ path: action.key }));
  }

  handleValid(value: boolean) {
    this.formIsValid = value;
  }

  ngOnInit() {
    this.eventTrackingService.setChatWidgetVisibility(false);
  }

  ngOnDestroy() {
    this.eventTrackingService.setChatWidgetVisibility(true);
  }

  returnToRoot() {
    this.store.dispatch(updateEditorPosition({ path: "" }));
  }

  nextPanel() {
    this.store.dispatch(nextPanel());
  }

  saveFlow() {
    this.store.dispatch(saveFlow());
  }

  closeFlow() {
    this.store.dispatch(abandonFlow());
  }

  deleteStep() {
    this.store.dispatch(removeCurrentItem());
  }
}
