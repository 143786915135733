import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import * as moment from "moment";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { UserService } from "../shared/service/user.service";

@Injectable({
  providedIn: "root",
})
export class TrialOrCustomerGuardService implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let isChurned = false;
    let isTrial = false;

    return this.userService.getUser().pipe(
      map((user) => {
        if (user) {
          isChurned = !!user.Company.churnedDate;
          isTrial = user.Company.trial;
        }
        if ((user && isTrial && this.trialIsOver()) || isChurned) {
          this.router.navigate(["/subscribe"]);
          return false;
        } else {
          return true;
        }
      }),
      catchError((err, caught) => {
        return of(false);
      })
    );
  }

  trialIsOver() {
    const trialEndDate = this.userService.user?.Company?.trialEndDate;
    if (trialEndDate === null) return false;
    const endDate = moment(trialEndDate);
    const now = moment();
    return now.isAfter(endDate);
  }
}
