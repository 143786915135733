import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { FlashMessage } from "../components/flash/flash.component";

@Injectable({
  providedIn: "root",
})
export class FlashService {
  private flashMessageSource = new Subject<FlashMessage>();

  flashMessage$ = this.flashMessageSource.asObservable();

  set(message: FlashMessage | string) {
    if (typeof message === "string") {
      this.flashMessageSource.next({
        title: message,
        description: null,
        status: null,
      });

      return;
    }

    this.flashMessageSource.next(message);
  }
}
