<div class="flex justify-center w-full min-h-screen bg-ni-blue-400">
  <div class="flex justify-center vh-100">
    <section class="flex-auto max-w-2xl ma-1 lg:mt-5">
      <header class="mx-auto my-8 text-center max-w-prose">
        <img
          src="https://cdn.nickelled.com/colours-and-shapes/0.5.2/images/logo.svg"
          alt="Logo"
          width="120px"
          class="mx-auto mb-3"
        />
        <h1 class="my-4 text-2xl font-bold text-center text-navy">
          Join Nickelled...
        </h1>
      </header>
      <div class="p-3 rounded-md bg-ni-blue-450">
        <form #signupForm="ngForm" (ngSubmit)="signup(signupForm)" ngNativeValidate>
          <div class="mb-3">
            <label class="block mb-1 text-sm font-bold"
              >What's your name?</label
            >
            <div class="flex justify-between -m-4">
              <input
                class="flex-auto w-full mx-4 my-4 border rounded-md pa-2 border-ni-blue-400"
                ngModel
                name="firstName"
                type="text"
                placeholder="First name"
                autofocus="true"
                required
                minlength="2"
              />
              <input
                class="flex-auto w-full mx-4 my-4 border rounded-md pa-2 border-ni-blue-400"
                ngModel
                name="secondName"
                type="text"
                placeholder="Last name"
                required
                minlength="2"
              />
            </div>
          </div>

          <div class="mb-3">
            <label class="block mb-1 text-sm font-bold"
              >What's your email address?</label
            >
            <input
              class="w-full border rounded-md pa-2 border-ni-blue-400"
              ngModel
              name="email"
              type="email"
              placeholder="name@company.com"
            />
            <span class="block mt-1 text-xs"
              >Must match the invited email address.</span
            >
          </div>

          <div class="mb-3">
            <label class="block mb-1 text-sm font-bold"
              >Choose a password</label
            >
            <input
              class="w-full border rounded-md pa-2 border-ni-blue-400"
              ngModel
              name="password"
              type="password"
              placeholder="Password"
              required
              minlength="8"
            />
            <span class="block mt-1 text-xs"
              >Must be 8 characters or longer.</span
            >
          </div>

          <div class="mb-3">
            <input
              type="submit"
              class="w-full py-4 font-bold text-center text-white rounded-md bg-ni-orange pointer hover:bg-ni-orange-dark"
              value="Signup"
            />
          </div>

          <aside
            class="flex flex-col items-center justify-center p-4 text-sm bg-white border rounded-sm border-ni-blue-475"
          >
            <div>
              <span class="mr-1">Already have an account?</span>
              <a href="/login" class="font-bold text-ni-orange">Login</a>
            </div>
          </aside>
        </form>
      </div>
    </section>
  </div>
  <app-loading-indicator *ngIf="loading"></app-loading-indicator>
</div>
