<div
  class="overflow-hidden border border-transparent select-none rounded-xl"
  [class]="expanded ? 'bg-ni-blue-450 shadow-sm border-ni-blue-500' : ''"
>
  <div
    (click)="toggleExpanded()"
    class="flex px-4 pt-4 font-medium text-gray-700 cursor-pointer group"
    [class.pb-4]="expanded"
  >
    <span
      *ngIf="!(loading$ | async)"
      class="text-base font-medium text-gray-900 truncate"
    >
      {{ (site$ | async)?.name || "Select a site" }}
    </span>

    <hero-icon
      [name]="expanded ? 'chevron-up-solid' : 'chevron-down-solid'"
      size="1.25rem"
      class="ml-auto text-gray-400 group-hover:text-gray-500"
    >
    </hero-icon>
  </div>

  <div *ngIf="expanded" class="p-4 space-y-4 bg-ni-blue-400">
    <div
      class="pb-4 space-y-4 border-ni-blue-475"
      [class.border-b]="!isOmniplexGuide"
      [class.pb-4]="!isOmniplexGuide"
      *ngIf="(sites$ | async)?.length"
    >
      <div
        *ngFor="let site of sites$ | async"
        (click)="selectSite(site.id)"
        class="flex items-center text-sm font-medium text-gray-700 cursor-pointer group"
      >
        <hero-icon
          name="globe-alt"
          size="1.25rem"
          class="mr-2 text-gray-400 group-hover:text-gray-500"
        ></hero-icon>
        {{ site.name }}
      </div>
    </div>
    <div *ngIf="!isOmniplexGuide">
      <div
        class="flex items-center text-sm font-medium text-gray-700 cursor-pointer group"
        [routerLink]="['/settings/sites']"
      >
        <hero-icon
          name="cog"
          size="1.25rem"
          class="mr-2 text-gray-400 group-hover:text-gray-500"
        ></hero-icon>
        Manage sites
      </div>
    </div>
  </div>
</div>
