import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import {
  adjustmentsSolid,
  annotation,
  arrowDownSolid,
  arrowsExpand,
  badgeCheckSolid,
  check,
  chevronDoubleLeft,
  chevronDoubleLeftSolid,
  chevronDown,
  chevronUp,
  clipboardListSolid,
  cursorClick,
  documentText,
  documentTextSolid,
  dotsHorizontal,
  dotsHorizontalSolid,
  dotsVertical,
  dotsVerticalSolid,
  exclamation,
  globeAlt,
  HeroIconsModule,
  informationCircle,
  lightBulbSolid,
  lightningBolt,
  pencil,
  play,
  playSolid,
  plus,
  plusSolid,
  questionMarkCircle,
  questionMarkCircleSolid,
  speakerphoneSolid,
  starSolid,
  supportSolid,
  trashSolid,
  userGroupSolid,
  variable,
  x,
  xCircleSolid,
  xSolid,
} from "ng-heroicons";
import { QuillModule } from "ngx-quill";
import * as Quill from "quill";
import { PreviewModule } from "../preview/preview.module";
import { SchemaFormModule } from "../schema-form/schema-form.module";
import { ComponentsModule } from "../shared/components/components.module";
import { SharedModule } from "../shared/shared.module";
import { PortalAuthComponent } from "./components/portal-auth/portal-auth.component";
import { PortalComponent } from "./components/portal/portal.component";
import { EditorComponent } from "./editor.component";

const AlignStyle = Quill.import("attributors/style/align");
Quill.register(AlignStyle, false);

@NgModule({
  declarations: [PortalComponent, EditorComponent, PortalAuthComponent],
  imports: [
    CommonModule,
    SharedModule,
    ComponentsModule,
    QuillModule.forRoot(),
    DragDropModule,
    SchemaFormModule,
    PreviewModule,
    HeroIconsModule.withIcons({
      annotation,
      arrowsExpand,
      check,
      chevronDown,
      chevronDoubleLeft,
      chevronUp,
      cursorClick,
      globeAlt,
      informationCircle,
      lightningBolt,
      pencil,
      play,
      plus,
      questionMarkCircle,
      x,
      dotsVerticalSolid,
      dotsHorizontalSolid,
      dotsVertical,
      dotsHorizontal,
      xCircleSolid,
      chevronDoubleLeftSolid,
      trashSolid,
      documentText,
      arrowDownSolid,
      userGroupSolid,
      adjustmentsSolid,
      starSolid,
      variable,
      playSolid,
      documentTextSolid,
      clipboardListSolid,
      speakerphoneSolid,
      lightBulbSolid,
      supportSolid,
      badgeCheckSolid,
      questionMarkCircleSolid,
      plusSolid,
      exclamation,
      xSolid,
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [EditorComponent],
})
export class EditorModule {}
