import { getSelectors, RouterReducerState } from "@ngrx/router-store";
import { createFeatureSelector, createSelector } from "@ngrx/store";

export const selectRouter = createFeatureSelector<RouterReducerState>("router");

export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
} = getSelectors(selectRouter);

export const selectRouteFlowTypeParam = createSelector(
  selectRouteParam("flowType"),
  (flowType) => flowType ?? "guides"
);

export const selectRouteIdParam = createSelector(
  selectRouteParam("id"),
  (id) => id
);

export const selectRouteCategoryIdParam = createSelector(
  selectRouteParam("categoryId"),
  (categoryId) => categoryId
);

export const selectRouteResourceIdParam = createSelector(
  selectRouteParam("resourceId"),
  (resourceId) => resourceId
);

export const selectRoutePanelIdParam = createSelector(
  selectRouteParam("panelId"),
  (panelId) => panelId
);

export const selectRouteCollectionIdParam = createSelector(
  selectRouteParam("collectionId"),
  (id) => id
);

export const selectNewFlowParams = createSelector(
  selectQueryParams,
  (params) => {
    if ("type" in params && "siteId" in params) {
      const flowType = params.type;
      const siteId = Number(params.siteId);
      return flowType && Number.isInteger(siteId) ? { siteId, flowType } : null;
    }

    return null;
  }
);

export const selectSearchQuery = createSelector(
  selectCurrentRoute,
  selectQueryParam("q"),
  (route, searchQuery) => {
    if (route?.routeConfig?.path !== "search") return null;
    if (!searchQuery) return null;
    return searchQuery;
  }
);

export const selectRouteFlowIdParam = selectRouteParam("flowId");
