import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from "@angular/core";
import { BehaviorSubject, combineLatest, Subject, timer } from "rxjs";

@Component({
  selector: "app-preview-step",
  templateUrl: "./preview-step.component.html",
})
export class PreviewStepComponent implements AfterViewInit {
  @ViewChild("iframe") iframe: ElementRef<HTMLIFrameElement>;
  step$ = new BehaviorSubject<any>(null);
  refresh$ = new Subject();

  @Input() set data(value: any) {
    this.step$.next(value);
  }

  triggerInjectFlow$ = combineLatest([
    this.step$,
    this.refresh$,
    timer(2000),
  ]).subscribe(([step]) => {
    if (step === null) {
      this.injectFlow([], -1);
      return;
    }

    this.injectFlow([{ ...step, target: undefined }], 0);
  });

  constructor() {}

  ngAfterViewInit(): void {
    this.refresh$.next();
  }

  @HostListener("window:message", ["$event"])
  onMessage(event: MessageEvent) {
    if (event.data?.event === "NICKELLED_READY") {
      this.refresh$.next();
    }
  }

  injectFlow(steps: any[] = [], position = -1) {
    const iframeWindow: any = this.iframe?.nativeElement?.contentWindow;
    const launchers = iframeWindow?.NickelledLaunchers;
    if (!launchers || typeof launchers.loadForDebugging !== "function") return;
    launchers.loadForDebugging({
      themes: {},
      userData: {},
      flows: { myFlow: { steps: steps } },
      runState: position < steps.length ? { myFlow: { position } } : {},
      clear: true,
    });
  }
}
