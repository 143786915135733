<li>
  <div
    class="text-gray-500 cursor-pointer hover:bg-ni-blue-400"
    [routerLink]="link"
    routerLinkActive
    #rla="routerLinkActive"
    [class.bg-ni-blue-400]="rla.isActive"
  >
    <div class="flex items-center px-4 py-4 sm:px-6">
      <div class="flex-1 min-w-0 sm:flex sm:items-center sm:justify-between">
        <div class="truncate">
          <div class="flex text-sm">
            <p
              class="font-medium truncate"
              class="font-medium text-ni-blue-600"
            >
              {{ name || "Untitled" }}
            </p>
          </div>
          <div class="flex mt-2">
            <!-- <app-enabled-label [enabled]="autoStart"></app-enabled-label> -->
            <div class="flex items-center text-sm">
              <p>{{ type }}</p>
            </div>
          </div>
        </div>
        <div class="flex-shrink-0 mt-4 sm:mt-0 sm:ml-5">
          <div class="flex space-x-1">
            <!-- <div class="inline-grid w-6 h-6 text-white rounded-full ring-2 ring-white place-items-center"
              [class]="rla.isActive ? 'bg-ni2-orange-400' : 'bg-ni-blue-500'">
              <hero-icon name="link"></hero-icon>
            </div> -->
            <!-- <div
              class="inline-grid w-6 h-6 text-white rounded-full bg-ni-blue-500 ring-2 ring-white place-items-center">
              <hero-icon name="play"></hero-icon>
            </div>
            <div
              class="inline-grid w-6 h-6 text-white rounded-full bg-ni-blue-500 ring-2 ring-white place-items-center">
              <hero-icon name="academic-cap-solid"></hero-icon>
            </div> -->
          </div>
        </div>
      </div>
      <div class="flex-shrink-0 ml-5">
        <div
          class="grid w-5 h-5 rounded-full place-items-center"
          [class]="rla.isActive ? 'text-ni-blue-600' : 'text-ni-blue-500'"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
            class="block"
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</li>
