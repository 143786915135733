import { animate, style, transition, trigger } from "@angular/animations";
import { Component, OnDestroy } from "@angular/core";
import { debounceTime, map, tap } from "rxjs/operators";
import { FlashService } from "../../service/flash.service";

export type FlashMessage = {
  title: string;
  description: string;
  status: "success" | "failure" | null;
};

@Component({
  selector: "app-flash",
  templateUrl: "./flash.component.html",
  styleUrls: ["./flash.component.scss"],
  animations: [
    trigger("inOut", [
      transition(":enter", [
        style({ opacity: 0, transform: "translateX(8px)" }),
        animate("300ms ease-out", style({ opacity: 1, transform: "none" })),
      ]),
      transition(":leave", [animate("100ms ease-in", style({ opacity: 0 }))]),
    ]),
  ],
})
export class FlashComponent implements OnDestroy {
  flashMessage: FlashMessage | null = null;

  flashMessageSubscription = this.flashService.flashMessage$
    .pipe(
      map((message) => {
        if (typeof message === "string") {
          return {
            title: message,
            description: null,
            status: null,
          };
        }

        return message;
      }),
      tap((message) => {
        this.flashMessage = message;
      }),
      debounceTime(5000)
    )
    .subscribe(() => {
      this.flashMessage = null;
    });

  constructor(private flashService: FlashService) {}

  clearFlash() {
    this.flashMessage = null;
  }

  ngOnDestroy() {
    this.flashMessageSubscription.unsubscribe();
  }
}
