import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-field-info",
  templateUrl: "./field-info.component.html",
  styleUrls: ["./field-info.component.scss"],
})
export class FieldInfoComponent implements OnInit {
  @Input() message = "";

  constructor() {}

  ngOnInit(): void {}
}
