import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";

type TargetRule = { id: TargetSelectorType; label: string; group: string };

type TargetSelectorType =
  | "title"
  | "pathname"
  | "url"
  | "textContains"
  | "textBefore"
  | "textAfter"
  | "css"
  | "tagName";

type TargetSelector = {
  enabledSelectors?: TargetSelectorType[];
  title?: string;
  pathname?: string;
  url?: string;
  css?: string;
  text?: {
    contains: string;
    before?: string;
    after?: string;
  };
  textContains?: string;
  textBefore?: string;
  textAfter?: string;
  zoom?: number;
  tagName?: string;
};

type NullableTargetSelector = TargetSelector | null;

@Component({
  selector: "app-field-target-v2",
  templateUrl: "./field-target-v2.component.html",
  styleUrls: ["./field-target-v2.component.scss"],
})
export class FieldTargetV2Component implements OnInit {
  private RULES: TargetRule[] = [
    { id: "title", label: "Title matches", group: "page" },
    { id: "pathname", label: "Path matches", group: "page" },
    { id: "url", label: "URL matches", group: "page" },
    { id: "textContains", label: "Text contains", group: "element" },
    { id: "textBefore", label: "Has text before", group: "element" },
    { id: "textAfter", label: "Has text after", group: "element" },
    { id: "css", label: "CSS selector matches", group: "element" },
    { id: "tagName", label: "Element type is", group: "element" },
  ];

  @Input() set value(value: NullableTargetSelector) {
    const transformedValue = this.transformSelectorToNewFormat(value);
    this.value$.next(transformedValue);
  }
  @Output() update = new EventEmitter<TargetSelector>();

  value$ = new BehaviorSubject<TargetSelector>(null);

  rules$ = this.value$.pipe(
    map((value) =>
      this.RULES.map((rule) => ({
        ...rule,
        enabled: value?.enabledSelectors?.includes(rule.id),
        value: value?.[rule.id] ?? "",
      }))
    )
  );

  activeRules$ = this.rules$.pipe(
    map((rules) => rules.filter((rule) => rule.enabled))
  );

  inactiveRules$ = this.rules$.pipe(
    map((rules) =>
      rules.reduce(
        (acc, cur) => {
          const index = cur.group === "page" ? 0 : 1;
          acc[index].rules.push(cur);
          return acc;
        },
        [
          { title: "Page criteria", rules: [] },
          { title: "Element criteria", rules: [] },
        ]
      )
    )
  );

  constructor() {}

  ngOnInit(): void {}

  activateElementSelection() {}

  toggleRule(id: TargetSelectorType, enable: boolean) {
    const oldValue = this.value$.value;
    const set = new Set(oldValue.enabledSelectors);

    if (enable) {
      set.add(id);
    } else {
      set.delete(id);
    }

    this.update.emit({
      ...oldValue,
      enabledSelectors: Array.from(set),
    });
  }

  handleElementSelection() {}

  updateValue(id: string, value: string) {
    const oldValue = this.value$.value;
    this.update.emit({
      ...oldValue,
      [id]: value,
    });
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  private transformSelectorToNewFormat(
    value: NullableTargetSelector
  ): TargetSelector {
    if (!value) {
      value = {
        enabledSelectors: [],
      };
    }

    if (value.text) {
      value = {
        ...value,
        textContains: value.text.contains,
        textBefore: value.text.before,
        textAfter: value.text.after,
        text: undefined,
      };
    }

    if (!value.enabledSelectors) {
      value.enabledSelectors = this.getAllDefinedSelectors(value);
    }

    return value;
  }

  private getAllDefinedSelectors(
    selector: TargetSelector
  ): TargetSelectorType[] {
    return this.RULES.map((rule) => rule.id).filter((id) => !!selector[id]);
  }
}
