import * as NavBarActions from "@actions/nav-bar.actions";
import * as SitesApiActions from "@actions/sites-api.actions";
import { createReducer, on } from "@ngrx/store";
import { SiteEntity } from "src/app/shared/service/site.service";

export type State = {
  selectedSiteId: number | null;
  data: SiteEntity[];
  loading: boolean;
  error: Error | null;
};

export const initialState: State = {
  selectedSiteId: null,
  data: [],
  loading: true,
  error: null,
};

const _sitesReducer = createReducer(
  initialState,
  on(
    NavBarActions.selectSite,
    (state, { siteId }): State => ({
      ...state,
      selectedSiteId: siteId,
    })
  ),
  on(
    SitesApiActions.sitesLoadedSuccess,
    (state, { sites, siteId = null }): State => {
      let selectedSiteId = state.selectedSiteId;

      if (siteId !== null && sites.some((site) => site.id === siteId)) {
        selectedSiteId = siteId;
      } else if (selectedSiteId === null && sites.length > 0) {
        selectedSiteId = sites[sites.length - 1].id;
      }

      return {
        ...state,
        data: sites,
        selectedSiteId,
        loading: false,
      };
    }
  )
);

export function reducer(state: any, action: any) {
  return _sitesReducer(state, action);
}
