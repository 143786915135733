<ul
  class="mb-4 overflow-hidden bg-gray-200 border border-gray-300 divide-y divide-gray-300 rounded-md shadow-sm"
>
  <li
    *ngFor="let item of items; index as idx; trackBy: trackById"
    (click)="handleClick(item.id)"
    class="px-4 py-3 list-none bg-white cursor-pointer hover:bg-gray-50 group"
  >
    <div class="flex items-center space-x-5">
      <div
        class="grid w-4 text-base font-medium text-center text-ni2-orange-500 place-items-center"
      >
        <span>{{ idx + 1 }}</span>
      </div>
      <div class="flex-grow text-sm font-medium truncate text-ni-blue-700">
        {{ item.title }}
      </div>
    </div>
  </li>
</ul>

<div *ngIf="help" class="my-1 mb-4">
  <label class="block text-sm font-medium text-ni-blue-600">{{ help }}</label>
</div>
