import {
  AfterContentInit,
  Component,
  ContentChildren,
  OnInit,
  QueryList,
} from "@angular/core";
import { filter, startWith } from "rxjs/operators";
import { AccordionGroupComponent } from "../accordion-group/accordion-group.component";

@Component({
  selector: "app-accordion",
  templateUrl: "./accordion.component.html",
  styleUrls: ["./accordion.component.scss"],
})
export class AccordionComponent implements OnInit, AfterContentInit {
  @ContentChildren(AccordionGroupComponent)
  panels: QueryList<AccordionGroupComponent>;

  constructor() {}

  ngOnInit(): void {}

  ngAfterContentInit() {
    this.panels.changes
      .pipe(
        startWith(this.panels.toArray()),
        filter((panels) => panels.length)
      )
      .subscribe(
        (
          queryList:
            | AccordionGroupComponent[]
            | QueryList<AccordionGroupComponent>
        ) => {
          const panels = Array.isArray(queryList)
            ? queryList
            : queryList.toArray();

          panels.forEach((panel) => {
            panel.toggle.subscribe(() => {
              this.togglePanel(panel);
            });
          });
        }
      );
  }

  togglePanel(panel: AccordionGroupComponent) {
    const opened = panel.opened;
    this.panels.toArray().forEach((p) => (p.opened = false));
    panel.opened = !opened;
  }

  isExpanded(index: number) {
    // return this.expandedSection === index;
  }

  shouldShowSection(sectionIndex: number) {
    return true;
    // const expanded = this.isExpanded(sectionIndex);

    // const valid = this.fieldsetArray
    //   .filter((_, idx) => idx < Math.max(0, sectionIndex))
    //   .every((_, idx) => this.sectionIsValid(idx));

    // return expanded || valid;
  }

  toggleExpanded(index: number) {
    // this.expandedSection = index;
  }

  sectionIsValid(index: number) {
    return true;
    // const valid = this.fieldsetArray[index].controls
    //   .filter((control) => !!control.key)
    //   .every((control) => this.formGroup.controls[control.key]?.valid);

    // return valid;
  }
}
