<ng-container *ngIf="(list$ | async)?.length">
  <ul
    cdkDropList
    class="mb-4 bg-gray-200 border border-gray-300 divide-y divide-gray-300 shadow-sm"
    (cdkDropListDropped)="handleDrop($event)"
  >
    <li
      cdkDrag
      *ngFor="let item of list$ | async; index as idx; trackBy: trackByIndex"
      class="list-none bg-white divide-y divide-gray-200 cursor-pointer group"
      [class.hover:bg-gray-50]="expanded !== idx"
    >
      <div
        class="flex items-center px-4 py-3 space-x-5"
        (click)="handleClick(idx)"
      >
        <div
          class="grid w-4 text-base font-medium text-center text-ni2-orange-500 place-items-center"
        >
          <span>{{ idx + 1 }}</span>
        </div>
        <div
          class="flex-grow text-sm font-medium truncate"
          [class.text-ni-blue-700]="item.valid"
          [class.text-gray-400]="!item.valid"
        >
          {{ item.summary }}
        </div>
        <div class="grid w-4 text-base font-medium place-items-center">
          <hero-icon
            *ngIf="item.valid"
            [name]="item.icon"
            class="text-ni-blue-500"
            size="1.4rem"
          >
          </hero-icon>
          <hero-icon
            *ngIf="!item.valid"
            name="exclamation"
            class="text-red-500"
            size="1.4rem"
          >
          </hero-icon>
        </div>
      </div>
      <div
        [hidden]="expanded !== idx"
        [class.grid]="expanded === idx"
        class="gap-2 px-4 py-3 bg-gray-50"
      >
        <app-schema-field
          class="block"
          *ngFor="let def of item.schema; trackBy: trackByKey"
          [def]="def"
          [value]="getValue(idx, def.key)"
          (update)="handleUpdate(idx, def.key, $event)"
        ></app-schema-field>
        <button
          (click)="handleRemove(idx)"
          class="flex items-center mt-1 space-x-1 text-sm font-medium text-gray-500 hover:text-gray-600"
        >
          <hero-icon name="trash" size="1.1rem"></hero-icon>
          <span>Remove</span>
        </button>
      </div>
    </li>
  </ul>
</ng-container>

<div *ngIf="help" class="my-1 mb-4">
  <label class="block text-sm font-medium text-ni-blue-600">{{ help }}</label>
</div>

<div
  class="grid grid-cols-2 gap-2"
  *ngIf="templates && (list$ | async)?.length < maxItems"
>
  <app-button
    *ngFor="let tpl of templates"
    [title]="tpl.title"
    [tooltip]="tpl.help"
    class="block"
    [variant]="tpl.default ? 'primary' : 'plain'"
    [iconName]="tpl.icon"
    class="block h-full"
    (click)="handleAdd(tpl.id)"
    [class.col-span-2]="tpl.colSpan === 2"
  >
  </app-button>
</div>
