import { Injectable } from "@angular/core";

type PageDetails = {
  url: string;
  path: string;
  title: string;
  pageId: number | null;
};

@Injectable({
  providedIn: "root",
})
export class PortalService {
  private portalInstance: any = null;
  private currentPage: PageDetails = null;

  constructor() {}

  init(portalInstance: any) {
    this.portalInstance = portalInstance;
  }

  destroy() {
    try {
      this.portalInstance?.close();
    } catch (err) {
      console.warn("Failed to cleanly close portal");
    }
  }

  async snapshotPage(): Promise<PageDetails> {
    if (this.currentPage && !this.currentPage.pageId) {
      this.currentPage.pageId = await new Promise((resolve, reject) => {
        if (!this.portalInstance) return resolve(null);

        this.portalInstance.save(
          (page) => resolve(page?.page),
          (err) => resolve(null)
        );
      });
    }

    return this.currentPage;
  }

  handlePageChange({ url, title }) {
    const urlInstance = new URL(url);

    this.currentPage = {
      url: url,
      title: title,
      path: urlInstance.pathname,
      pageId: null,
    };
  }
}
