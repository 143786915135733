import { createAction, props } from "@ngrx/store";

export const portalInteraction = createAction(
  "[Portal] Interaction",
  props<{
    interaction?: string;
    el?: HTMLElement;
    event?: any;
  }>()
);

export const portalPageChanged = createAction(
  "[Portal] Page Changed",
  props<{
    url: string;
    title?: string;
  }>()
);

export const portalError = createAction(
  "[Portal] Error",
  props<{
    error: any[];
  }>()
);

export const portalPositionChanged = createAction(
  "[Portal] Position Changed",
  props<{
    position: number;
  }>()
);
