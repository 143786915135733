import { newFlow } from "@actions/editor.actions";
import {
  changeFlowsPage,
  setFlowsSearchQuery,
} from "@actions/flows-index-page.actions";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "@reducers/.";
import { selectCategory, selectFlowResults } from "@selectors/flows.selectors";
import { selectRouteFlowIdParam } from "@selectors/router.selectors";
import { selectActiveSiteId } from "@selectors/sites.selectors";
import { Subject } from "rxjs";
import { map, withLatestFrom } from "rxjs/operators";
import { LogoService } from "../../service/logo.service";

@Component({
  selector: "app-resource-list",
  templateUrl: "./resource-list.component.html",
  styleUrls: ["./resource-list.component.scss"],
})
export class ResourceListComponent implements OnInit {
  triggerNew$ = new Subject<string>();
  category$ = this.store.select(selectCategory);
  items$ = this.store.select(selectFlowResults);
  showSearchBar = false;
  flowIsOpen$ = this.store
    .select(selectRouteFlowIdParam)
    .pipe(map((id) => !!id && id !== "new"));
  siteId$ = this.store.select(selectActiveSiteId);

  isOmniplexGuide = this.logoService.isOmniplexGuide();

  launchNew$ = this.triggerNew$
    .pipe(withLatestFrom(this.siteId$, this.category$))
    .subscribe(([type, siteId, category]) => {
      if (type === "customerService") {
        window.open(
          `https://make.nickelled.com/?siteId=${siteId}&redirectTo=app`
        );
        return;
      }

      const panelId =
        category.types?.find((flowType) => flowType.id === type)
          ?.initialPanelId ?? "wizard";

      this.store.dispatch(newFlow({ siteId, flowType: type, panelId }));
    });

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private logoService: LogoService
  ) {}

  ngOnInit() {}

  toggleSearchBar(show: boolean = null) {
    this.showSearchBar = show ?? !this.showSearchBar;

    if (!this.showSearchBar) {
      this.store.dispatch(setFlowsSearchQuery({ searchQuery: null }));
    }
  }

  setSearchQuery(value: string) {
    this.store.dispatch(setFlowsSearchQuery({ searchQuery: value }));
  }

  handleChangePage(offset: number) {
    this.store.dispatch(changeFlowsPage({ offset }));
  }

  triggerNew(typeId: string) {
    this.triggerNew$.next(typeId);
  }
}
