import { createSelector } from "@ngrx/store";
import { SiteEntity } from "src/app/shared/service/site.service";
import { AppState } from "../reducers";

export const selectSites = (state: AppState) => state.sites;

export const selectActiveSiteId = createSelector(
  selectSites,
  (state) => state.selectedSiteId
);

export const selectAllSites = createSelector(
  selectSites,
  (state) => state.data
);

export const selectActiveSite = createSelector(
  selectSites,
  (state): SiteEntity | null => {
    if (!state.data || !state.selectedSiteId) return null;
    const site = state.data.find((site) => site.id === state.selectedSiteId);
    return site ?? null;
  }
);

export const selectSitesLoading = createSelector(
  selectSites,
  (state) => state.loading
);
