import * as AuthApiActions from "@actions/auth-api.actions";
import * as AuthActions from "@actions/auth.actions";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, exhaustMap, map, tap } from "rxjs/operators";
import { FlashService } from "../../shared/service/flash.service";
import { UserService } from "../../shared/service/user.service";

@Injectable()
export class AuthEffects {
  logOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.userLoggedOut),
      exhaustMap((data) =>
        this.userService.logOut().pipe(
          map(() => AuthApiActions.userLoggedOutSuccess()),
          catchError((error) =>
            of(AuthApiActions.userLoggedOutFailure({ error: error.toString() }))
          )
        )
      )
    )
  );

  logoutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthApiActions.userLoggedOutSuccess),
        tap(() => {
          this.userService.user = undefined;
          this.flashService.set("You have been logged out.");
          this.router.navigateByUrl(`/account/login`);
        })
      ),
    { dispatch: false }
  );

  postLoginRedirectUrlSet$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.setPostLoginRedirectUrl),
        tap(({ url }) => {
          sessionStorage.setItem("postLoginRedirectUrl", url);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private flashService: FlashService,
    private router: Router
  ) {}
}
