import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
  academicCap,
  adjustments,
  adjustmentsSolid,
  annotation,
  arrowCircleLeft,
  arrowCircleRight,
  arrowDown,
  arrowDownSolid,
  arrowLeft,
  arrowRight,
  arrowsExpand,
  badgeCheck,
  bookmark,
  calendar,
  calendarSolid,
  chartPie,
  check,
  checkCircle,
  checkCircleSolid,
  chevronDoubleLeft,
  chevronDoubleLeftSolid,
  chevronDoubleRightSolid,
  chevronDown,
  chevronDownSolid,
  chevronLeft,
  chevronRight,
  chevronUp,
  chevronUpSolid,
  clipboardCheck,
  clipboardList,
  clock,
  code,
  codeSolid,
  cog,
  cogSolid,
  collection,
  colorSwatch,
  creditCard,
  creditCardSolid,
  cube,
  cursorClick,
  document,
  documentSearch,
  documentText,
  dotsVertical,
  dotsVerticalSolid,
  download,
  duplicate,
  emojiHappy,
  exclamation,
  exclamationSolid,
  globeAlt,
  HeroIconsModule,
  home,
  inbox,
  informationCircle,
  library,
  lightBulb,
  lightningBolt,
  link,
  logout,
  officeBuilding,
  pause,
  pencil,
  pencilAlt,
  pencilSolid,
  play,
  playSolid,
  plus,
  plusCircle,
  plusSolid,
  presentationChartLineSolid,
  questionMarkCircle,
  questionMarkCircleSolid,
  refresh,
  search,
  selectorSolid,
  share,
  sparkles,
  speakerphone,
  star,
  starSolid,
  support,
  switchHorizontal,
  thumbDown,
  thumbUp,
  trash,
  trashSolid,
  user,
  userCircle,
  userCircleSolid,
  userGroup,
  userGroupSolid,
  users,
  variable,
  viewGrid,
  x,
  xCircle,
  xCircleSolid,
} from "ng-heroicons";
import { NgxPaginationModule } from "ngx-pagination";
import { CardAttributeComponent } from "./card/card-attribute/card-attribute.component";
import { CardComponent } from "./card/card.component";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { DatePickerComponent } from "./date-picker/date-picker.component";
import { DefaultLayoutComponent } from "./default-layout/default-layout.component";
import { DropdownComponent } from "./dropdown/dropdown.component";
import { EditableTitleComponent } from "./editable-title/editable-title.component";
import { EmptySimpleComponent } from "./empty-simple/empty-simple.component";
import { EnabledLabelComponent } from "./enabled-label/enabled-label.component";
import { FlashComponent } from "./flash/flash.component";
import { FlowSelectComponent } from "./flow-select/flow-select.component";
import { GuideSelectComponent } from "./guide-select/guide-select.component";
import { HeaderLayoutComponent } from "./header-layout/header-layout.component";
import { HorizontalTabsComponent } from "./horizontal-tabs/horizontal-tabs.component";
import { LauncherAttrOptionsComponent } from "./launcher-attr-options/launcher-attr-options.component";
import { LoadingIndicatorComponent } from "./loading-indicator/loading-indicator.component";
import { LoadingSpinnerComponent } from "./loading-spinner/loading-spinner.component";
import { MessageChannelComponent } from "./message-channel/message-channel.component";
import { NavBarComponent } from "./nav-bar/nav-bar.component";
import { PageHeaderComponent } from "./page-header/page-header.component";
import { PagePaddingComponent } from "./page-padding/page-padding.component";
import { PaginationBarComponent } from "./pagination-bar/pagination-bar.component";
import { PanelGenericComponent } from "./panel-generic/panel-generic.component";
import { PanelHeaderComponent } from "./panel-header/panel-header.component";
import { PanelWellComponent } from "./panel-well/panel-well.component";
import { PaymentPromptComponent } from "./payment-prompt/payment-prompt.component";
import { PlaceholderComponent } from "./placeholder/placeholder.component";
import { ResourceLayoutComponent } from "./resource-layout/resource-layout.component";
import { ResourceListItemComponent } from "./resource-list-item/resource-list-item.component";
import { ResourceListComponent } from "./resource-list/resource-list.component";
import { SelectMenuComponent } from "./select-menu/select-menu.component";
import { SideNavComponent } from "./side-nav/side-nav.component";
import { SiteHeaderComponent } from "./site-header/site-header.component";
import { SiteSelectComponent } from "./site-select/site-select.component";
import { SlideOverPanelComponent } from "./slide-over-panel/slide-over-panel.component";
import { SnippetDialogComponent } from "./snippet-dialog/snippet-dialog.component";
import { StatsDividerComponent } from "./stats-divider/stats-divider.component";
import { TagsComponent } from "./tags/tags.component";
import { ToolbarComponent } from "./toolbar/toolbar.component";
import { ButtonComponent } from "./ui/button/button.component";
import { InputComponent } from "./ui/input/input.component";
import { TextareaComponent } from "./ui/textarea/textarea.component";
import { ToggleComponent } from "./ui/toggle/toggle.component";
import { VerticalNavLinkComponent } from "./vertical-nav-link/vertical-nav-link.component";

@NgModule({
  declarations: [
    LoadingIndicatorComponent,
    FlashComponent,
    ConfirmationDialogComponent,
    PaymentPromptComponent,
    DatePickerComponent,
    DropdownComponent,
    ToggleComponent,
    ButtonComponent,
    InputComponent,
    TextareaComponent,
    VerticalNavLinkComponent,
    NavBarComponent,
    PlaceholderComponent,
    SiteSelectComponent,
    SelectMenuComponent,
    SnippetDialogComponent,
    PlaceholderComponent,
    EnabledLabelComponent,
    SideNavComponent,
    DefaultLayoutComponent,
    SlideOverPanelComponent,
    FlowSelectComponent,
    LoadingSpinnerComponent,
    EmptySimpleComponent,
    HorizontalTabsComponent,
    PaginationBarComponent,
    PageHeaderComponent,
    PanelGenericComponent,
    ResourceLayoutComponent,
    ResourceListComponent,
    ResourceListItemComponent,
    TagsComponent,
    StatsDividerComponent,
    SiteHeaderComponent,
    EditableTitleComponent,
    MessageChannelComponent,
    ToolbarComponent,
    HeaderLayoutComponent,
    PanelHeaderComponent,
    PagePaddingComponent,
    CardComponent,
    CardAttributeComponent,
    LauncherAttrOptionsComponent,
    GuideSelectComponent,
    PanelWellComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxPaginationModule,
    RouterModule,
    FontAwesomeModule,
    HeroIconsModule.withIcons({
      annotation,
      arrowsExpand,
      checkCircle,
      viewGrid,
      bookmark,
      emojiHappy,
      arrowRight,
      documentSearch,
      check,
      chevronDown,
      chevronDoubleLeft,
      chevronDoubleRightSolid,
      chevronUp,
      cursorClick,
      globeAlt,
      informationCircle,
      lightningBolt,
      pencil,
      play,
      pencilAlt,
      plus,
      questionMarkCircle,
      x,
      dotsVerticalSolid,
      xCircleSolid,
      chevronDoubleLeftSolid,
      trashSolid,
      documentText,
      arrowDownSolid,
      userGroupSolid,
      adjustmentsSolid,
      starSolid,
      variable,
      playSolid,
      clipboardList,
      speakerphone,
      lightBulb,
      support,
      badgeCheck,
      calendarSolid,
      creditCardSolid,
      exclamationSolid,
      plusSolid,
      plusCircle,
      pencilSolid,
      chevronRight,
      code,
      trash,
      user,
      users,
      creditCard,
      inbox,
      calendar,
      cogSolid,
      presentationChartLineSolid,
      duplicate,
      userGroup,
      cog,
      chartPie,
      academicCap,
      clipboardCheck,
      home,
      userCircleSolid,
      xCircle,
      adjustments,
      dotsVertical,
      thumbUp,
      thumbDown,
      arrowDown,
      refresh,
      colorSwatch,
      link,
      collection,
      sparkles,
      star,
      codeSolid,
      userCircle,
      logout,
      selectorSolid,
      questionMarkCircleSolid,
      checkCircleSolid,
      exclamation,
      officeBuilding,
      pause,
      arrowCircleLeft,
      arrowCircleRight,
      cube,
      search,
      chevronDownSolid,
      chevronUpSolid,
      share,
      arrowLeft,
      clock,
      document,
      chevronLeft,
      download,
      switchHorizontal,
      library,
    }),
  ],
  exports: [
    LoadingIndicatorComponent,
    FlashComponent,
    ConfirmationDialogComponent,
    PaymentPromptComponent,
    NgxPaginationModule,
    DatePickerComponent,
    DropdownComponent,
    ToggleComponent,
    ButtonComponent,
    InputComponent,
    TextareaComponent,
    VerticalNavLinkComponent,
    NavBarComponent,
    SelectMenuComponent,
    SnippetDialogComponent,
    PlaceholderComponent,
    EnabledLabelComponent,
    SideNavComponent,
    SlideOverPanelComponent,
    FlowSelectComponent,
    LoadingSpinnerComponent,
    EmptySimpleComponent,
    HorizontalTabsComponent,
    PaginationBarComponent,
    PageHeaderComponent,
    DefaultLayoutComponent,
    PanelGenericComponent,
    TagsComponent,
    StatsDividerComponent,
    SiteHeaderComponent,
    EditableTitleComponent,
    MessageChannelComponent,
    ToolbarComponent,
    PanelHeaderComponent,
    PagePaddingComponent,
    CardComponent,
    CardAttributeComponent,
    LauncherAttrOptionsComponent,
    GuideSelectComponent,
    PanelWellComponent,
  ],
})
export class ComponentsModule {}
