import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-resource-list-item",
  templateUrl: "./resource-list-item.component.html",
  styleUrls: ["./resource-list-item.component.scss"],
})
export class ResourceListItemComponent implements OnInit {
  @Input() name = "";
  @Input() type = "";
  @Input() updatedAt: Date = null;
  @Input() autoStart = false;
  @Input() link = [];

  constructor() {}

  ngOnInit(): void {}
}
