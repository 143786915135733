import { Injectable } from "@angular/core";
import { forkJoin, Observable, of } from "rxjs";
import { concatMap, map, mergeMap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { StepTemplate } from "./flow-template.service";
import { HttpService } from "./http.service";
import { SiteEntity, SiteService } from "./site.service";
import { StepTemplateService } from "./step-template.service";
import { StepService } from "./step.service";

export type FlowEntity = {
  id: number | string | null;
  type: string;
  createdAt: string | null;
  createdBy: { fullName: string } | null;
  isPublished: boolean;
  name: string;
  screenshot: string | null;
  siteId: number | null;
  steps: any[];
  site: SiteEntity | null;
  language: string;
  themeId: string;
  $templateId: string;
  startingUrl: string;
  slug?: string;
  title?: string;
  companyId?: null | number;
};

@Injectable({
  providedIn: "root",
})
export class FlowService {
  constructor(
    private httpService: HttpService,
    private stepService: StepService,
    private siteService: SiteService,
    private stepTemplateService: StepTemplateService
  ) {}

  list(siteId: number, type: string | null = null): Observable<FlowEntity[]> {
    const url = `${environment.apiHost}/flows`;
    return this.httpService.get(url, { withCredentials: true }).pipe(
      map((flows) =>
        flows.filter((flow: any) => {
          if (flow.siteId !== siteId) return false;
          if (flow.type !== type && type !== null) return false;
          return true;
        })
      )
    );
  }

  get(flowId: number): Observable<Partial<FlowEntity>> {
    const url = `${environment.apiHost}/flows/${flowId}`;
    return this.httpService.get(url, { withCredentials: true });
  }

  getFull(
    flowId: number
  ): Observable<{ flow: FlowEntity; template: StepTemplate }> {
    return forkJoin([this.get(flowId), this.stepService.list(flowId)]).pipe(
      concatMap(([flow, steps]) =>
        forkJoin([
          this.siteService.get(flow.siteId as any),
          this.stepTemplateService.get(flow.type as any),
        ]).pipe(
          map(
            ([site, template]) =>
              [
                {
                  ...flow,
                  $templateId: flow.type,
                  steps,
                },
                site,
                template,
              ] as [FlowEntity, SiteEntity, StepTemplate]
          )
        )
      ),
      map(([flow, site, template]) => ({ flow: { ...flow, site }, template }))
    );
  }

  create(flow: any) {
    const url = `${environment.apiHost}/flows`;
    return this.httpService.post(url, flow, { withCredentials: true });
  }

  createBlank(data: Partial<FlowEntity>) {
    const flow = { ...this.getBlankFlow(), ...data };
    return this.create(flow);
  }

  update(flowId: number, flow: any) {
    const url = `${environment.apiHost}/flows/${flowId}`;
    return this.httpService.put(url, flow, { withCredentials: true });
  }

  saveOrUpdate(flow: any) {
    const res = flow.id ? this.update(flow.id, flow) : this.create(flow);

    return res.pipe(
      concatMap(({ id }) => this.stepService.updateAll(id, flow.steps))
    );
  }

  getBlankFlow(): FlowEntity {
    return {
      id: null,
      type: "tour",
      createdAt: null,
      createdBy: null,
      isPublished: false,
      name: "Untitled flow",
      screenshot: null,
      siteId: null,
      language: "en",
      themeId: "0d55007d-7ac6-4342-bddb-d733fbb9f895",
      steps: [
        {
          $templateId: "initial-step-1",
        },
      ],
      site: null,
      $templateId: null,
      startingUrl: null,
    };
  }

  public copy(guideId: number) {
    const url = `${environment.apiHost}/users/guides/${guideId}.json`;

    return this.httpService.get(url, { withCredentials: true }).pipe(
      mergeMap((guide) => {
        const newGuide = { ...guide };

        delete newGuide.id;
        delete newGuide.url;
        newGuide.name = "Duplicate: " + newGuide.name;
        newGuide.createdAt = new Date().toString();
        newGuide.updatedAt = newGuide.createdAt;

        if (newGuide.slug) {
          newGuide.slug = "copy-" + newGuide.slug;
        }

        return this.httpService.post(`/users/guides.json`, newGuide, {
          withCredentials: true,
        });
      })
    );
  }

  public delete(guideId: number) {
    return this.httpService.del(`/users/guides/${guideId}.json`, {
      withCredentials: true,
    });
  }

  public getStats(
    startDate: Date,
    endDate: Date,
    guideId?: number | string
  ): Observable<any> {
    if (!Number.isInteger(guideId)) {
      return of(null);
    }

    const params = {
      startDate: startDate.toString(),
      endDate: endDate.toString(),
    };

    return this.httpService.get(`/guides/${guideId}/stats`, {
      params,
      withCredentials: true,
    });
  }
}
