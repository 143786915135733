import * as SettingsApiActions from "@actions/settings-api.actions";
import * as SettingsActions from "@actions/settings.actions";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { forkJoin } from "rxjs";
import { exhaustMap, map, tap } from "rxjs/operators";
import { BillingService } from "src/app/shared/service/billing.service";
import { TeamService } from "src/app/shared/service/team.service";
import { FlashService } from "../../shared/service/flash.service";
import { UserService } from "../../shared/service/user.service";

@Injectable()
export class SettingsEffects {
  loadTeamMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SettingsActions.loadTeamMembers,
        SettingsApiActions.addTeamMemberSuccess,
        SettingsApiActions.removeTeamMemberSuccess,
        SettingsApiActions.createInvitationSuccess,
        SettingsApiActions.cancelInvitationSuccess
      ),
      exhaustMap(() =>
        forkJoin([
          this.teamService.getTeamMembers(),
          this.teamService.getPendingInvitations(),
        ]).pipe(
          map(([teamMembers, invitations]) =>
            SettingsApiActions.teamMembersLoadedSuccess({
              teamMembers,
              invitations,
            })
          )
        )
      )
    )
  );

  updateContactDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.updateContactDetails),
      exhaustMap(({ firstName, secondName, email }) =>
        this.userService.updateUser({ firstName, secondName, email }).pipe(
          map(({ firstName, secondName, email }) =>
            SettingsApiActions.updateContactDetailsSuccess({
              firstName,
              secondName,
              email,
            })
          )
        )
      ),
      tap(() => this.flashService.set("Contact details updated"))
    )
  );

  // updatePassword$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(SettingsActions.updatePassword),
  //     exhaustMap(({ oldPassword, newPassword }) => {
  //       throw new Error('Not implemented');
  //     }),
  //     tap(() => this.flashService.set('Password updated')),
  //     map(({ oldPassword, newPassword })) => SettingsApiActions.updatePasswordSuccess({ oldPassword, newPassword }) ))
  //   )
  // );

  updateEmailSubscriptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.updateEmailSubscriptions),
      exhaustMap(({ weeklyEmail }) =>
        this.userService.updateUser({ weeklyEmail }).pipe(
          map(({ weeklyEmail }) =>
            SettingsApiActions.updateEmailSubscriptionsSuccess({
              weeklyEmail,
            })
          )
        )
      ),
      tap(() => this.flashService.set("Email preferences updated"))
    )
  );

  removeTeamMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.removeTeamMember),
      exhaustMap(({ id }) =>
        this.teamService
          .removeTeamMember(id)
          .pipe(map(() => SettingsApiActions.removeTeamMemberSuccess({ id })))
      ),
      tap(() => this.flashService.set("Team member removed"))
    )
  );

  createInvitation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.createInvitation),
      exhaustMap(({ email }) =>
        this.teamService
          .sendInvite(email)
          .pipe(map(() => SettingsApiActions.addTeamMemberSuccess({ email })))
      ),
      tap(() => this.flashService.set("Invitation sent"))
    )
  );

  cancelInvitation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.cancelInvitation),
      exhaustMap(({ id }) =>
        this.teamService
          .deleteInvitation(id)
          .pipe(map(() => SettingsApiActions.cancelInvitationSuccess({ id })))
      ),
      tap(() => this.flashService.set("Invitation cancelled"))
    )
  );

  resendInvitation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.resendInvitation),
      exhaustMap(({ id }) =>
        this.teamService
          .resendInvitation(id)
          .pipe(map(() => SettingsApiActions.resendInvitationSuccess({ id })))
      ),
      tap(() => this.flashService.set("Invitation resent"))
    )
  );

  updateInvoiceNotificationEmails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.updateInvoiceNotificationEmails),
      exhaustMap(({ emails }) =>
        this.billingService.updateInvoiceNotificationAddress(emails).pipe(
          map(() =>
            SettingsApiActions.updateInvoiceNotificationEmailsSuccess({
              emails,
            })
          )
        )
      ),
      tap(() => this.flashService.set("Invoice notification settings updated"))
    )
  );

  loadPaymentSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.loadPaymentSettings),
      exhaustMap(() =>
        this.userService.getUser().pipe(
          map((user) => {
            const { ccLast4, ccExpiryMonth, ccExpiryYear } = user.Company;
            return SettingsApiActions.loadPaymentSettingsSuccess({
              ccLast4,
              ccExpiryMonth,
              ccExpiryYear,
            });
          })
        )
      )
    )
  );

  updatePaymentSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.updatePaymentSettings),
      exhaustMap(({ stripeToken, stripeEmail }) =>
        this.billingService.updateCardDetails(stripeToken, stripeEmail).pipe(
          map(({ ccLast4, ccExpiryMonth, ccExpiryYear }) =>
            SettingsApiActions.updatePaymentSettingsSuccess({
              ccLast4,
              ccExpiryMonth,
              ccExpiryYear,
            })
          )
        )
      ),
      tap(() => this.flashService.set("Card details updated"))
    )
  );

  loadInvoiceList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.loadInvoiceList),
      exhaustMap(() =>
        this.billingService.getInvoices().pipe(
          map((invoices) => {
            return SettingsApiActions.loadInvoiceListSuccess({
              invoices,
            });
          })
        )
      )
    )
  );

  setRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.setRoles),
      exhaustMap(({ id, roleNames }) =>
        this.userService.setRoles(id, roleNames).pipe(
          map(() => {
            return SettingsApiActions.setRolesSuccess({ id, roleNames });
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private flashService: FlashService,
    private teamService: TeamService,
    private billingService: BillingService
  ) {}
}
