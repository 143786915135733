import { createAction, props } from "@ngrx/store";

export const newFlow = createAction(
  "[Editor] New Flow",
  props<{ siteId: number; flowType: string; panelId: string }>()
);

export const loadFlow = createAction(
  "[Editor] Load Flow",
  props<{ id: any }>()
);

export const clearFlow = createAction("[Editor] Clear Flow");

export const loadSingletonFlow = createAction(
  "[Editor] Load Singleton Flow",
  props<{ flowType: string; siteId: number }>()
);

export const saveFlow = createAction("[Editor] Save Flow");

export const submitChanges = createAction("[Editor] Submit Changes");

export const abandonFlow = createAction("[Editor] Abandon Flow");

export const updateFlow = createAction(
  "[Editor] Update Flow",
  props<{
    flow: { name: string; isPublished: boolean; steps?: any[] };
  }>()
);

export const setBasicAuthCredentials = createAction(
  "[Editor] Set Basic Auth Credentials",
  props<{ username: string; password: string }>()
);

export const updateEditorPosition = createAction(
  "[Editor] Update Editor Position",
  props<{ path: string }>()
);

export const removeCurrentItem = createAction("[Editor] Remove Current Item");

export const updateValue = createAction(
  "[Editor] Update Value",
  props<{ path: string; value: any; extend?: boolean }>()
);

export const previewLoadedSuccess = createAction(
  "[Editor] Load Preview Success",
  props<{
    preview: any;
  }>()
);

export const previewLoadedFailure = createAction(
  "[Editor] Load Preview Failure",
  props<{
    error: any;
  }>()
);

export const launchPanel = createAction(
  "[Editor] Launch Panel",
  props<{
    panelId: string;
  }>()
);

export const nextPanel = createAction("[Editor] Next Panel");

export const duplicateFlow = createAction("[Editor] Duplicate Flow");

export const deleteFlow = createAction("[Editor] Delete Flow");

export const copyLink = createAction("[Editor] Copy Link");

export const previewFlow = createAction("[Editor] Preview Flow");
