import { launcherEvent } from "@actions/launchers.actions";
import { loadSites } from "@actions/nav-bar.actions";
import { finishSiteWizard } from "@actions/wizard.actions";
import { Injectable } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Store } from "@ngrx/store";
import { selectActiveSite } from "@selectors/sites.selectors";
import { Subject } from "rxjs";
import { AppState } from "src/app/store/reducers";
import { environment } from "../../../environments/environment";
import { User } from "./user";

declare var analytics: any;
declare var Intercom: any;
declare var NickelledLaunchers: any;

declare global {
  interface Window {
    dataLayer: any;
  }
}

@Injectable({
  providedIn: "root",
})
export class EventTrackingService {
  constructor(private store: Store<AppState>) {}

  events = new Subject<[string, any]>();

  init(): void {
    if (!environment.offlineMode) {
      analytics.load(`${environment.segmentAPIKey}`);
      Intercom("shutdown");
      Intercom("boot", {
        app_id: environment.intercomAppId,
        widget: { activator: "#IntercomDefaultWidget" },
      });
    }

    this.loadLaunchersJs();
  }

  unixDate(date: any) {
    if (date) {
      return Date.parse(date) / 1000;
    } else {
      return false;
    }
  }

  identify(user: User): void {
    const segmentUser = {
      id: user.id,
      firstName: user.firstName,
      lastName: user.secondName,
      email: user.email,
      createdAt: user.createdAt,
    };

    const company = user.Company;

    const intercomUser = {
      user_id: user.id,
      name: `${user.firstName} ${user.secondName}`,
      email: user.email,
      phone: user.phone,
      created_at: this.unixDate(user.createdAt),
      weekly_update_email: user.weeklyEmail,
      guides_count: user.totalGuidesCount,
      company: {
        company_id: company.id,
        name: company.name,
        paid: !!company.paid,
        trial: company.trial,
        trial_end_at: this.unixDate(company.trialEndDate),
        plan: company.packageId,
        package_id: company.packageId,
        plan_name: company.packageName,
        card_expiry_month: company.ccExpiryMonth || 0,
        card_expiry_year: company.ccExpiryYear || 0,
        card_last_4: company.ccLast4,
        // companyGuideCount: #{companyGuideCount}, NOT PROVIDED BY AUTH
        theme_created: !!company.styling,
        signup_reason: company.signupReason,
        created_at: this.unixDate(company.createdAt),
      },
    };

    const nickelledUser = {
      appId: environment.launchers.appId,
      apiUrl: environment.launchers.apiUrl,
      trackingUrl: environment.launchers.trackingUrl,
      userId: user.id,
      email: user.email,
      companyId: company.Id,
      guideCount: user.totalGuidesCount,
      createdAt: user.createdAt,
      paid: !!company.paid,
    };

    if (!environment.offlineMode) {
      analytics.identify(user.id, segmentUser);
      Intercom("update", intercomUser);
    }

    NickelledLaunchers.setUser(nickelledUser);

    this.store.select(selectActiveSite).subscribe((site) => {
      NickelledLaunchers.setUser({
        siteAppId: site?.appId,
        siteLiveAt: site?.liveAt,
        siteUrl: site?.url,
      });
    });

    if (NickelledLaunchers.addEventListener) {
      NickelledLaunchers.addEventListener(
        "createFlow",
        this.handleCreateFlow.bind(this)
      );
      NickelledLaunchers.addEventListener(
        "liveChat",
        this.handleLiveChat.bind(this)
      );
      NickelledLaunchers.addEventListener(
        "reloadSites",
        this.handleReloadSites.bind(this)
      );
      NickelledLaunchers.addEventListener(
        "update",
        this.handleLauncherEvent.bind(this)
      );
    }
  }

  setChatWidgetVisibility(enable: boolean) {
    Intercom("update", {
      hide_default_launcher: !enable,
    });
  }

  handleLiveChat() {
    Intercom("showNewMessage");
  }

  handleCreateFlow(event) {
    this.store.dispatch(finishSiteWizard({ url: event.detail.data }));
  }

  handleLauncherEvent({ detail }) {
    if (!detail?.step) return;

    this.store.dispatch(
      launcherEvent({
        event: detail.event,
        data: detail.event === "track" ? detail.data : null,
        stepId: detail.step.id,
        flowId: detail.step.flowId,
      })
    );
  }

  handleReloadSites() {
    this.store.dispatch(loadSites());
  }

  action(event: string, data?: any): void {
    if (!environment.offlineMode) {
      analytics.track(event, data);
      Intercom("trackEvent", event, data);
      const dataLayer = (window.dataLayer = window.dataLayer || []);
      dataLayer.push({
        event,
        params: data,
      });
    }

    this.events.next([event, data]);
  }

  page(name: string, params?: {}): void {
    if (!environment.offlineMode) {
      analytics.page(name, params);
      Intercom("trackEvent", name, params);
      const dataLayer = (window.dataLayer = window.dataLayer || []);
      dataLayer.push({
        event: "pageView",
        params,
      });
    }
  }

  formChanges(event: string, ngForm: NgForm): void {
    if (!environment.offlineMode) {
      const changedFields: { [key: string]: any } = {};

      Object.keys(ngForm.controls).forEach((key) => {
        const currentControl = ngForm.controls[key];

        if (currentControl.dirty) {
          let fieldValue = true; // Mark fieldValue as true when not string/number

          if (
            typeof currentControl.value === "string" ||
            typeof currentControl.value === "number" ||
            typeof currentControl.value === "boolean"
          ) {
            fieldValue = currentControl.value as any;
          }

          changedFields[key] = fieldValue;
        }

        currentControl.markAsPristine();
      });

      this.action(event, changedFields);
    }
  }

  private loadLaunchersJs() {
    (<any>window).NickelledLaunchers = (<any>window).NickelledLaunchers || {
      setUser: function (u: any) {
        this.userData = u;
      },
    };
    const s = document.createElement("script");
    s.async = true;
    s.src = environment.launchers.js;
    const f = document.getElementsByTagName("script")[0];
    // @ts-ignore
    f.parentNode.insertBefore(s, f);
  }
}
