import { Component, Input, OnInit } from "@angular/core";

type ButtonSize = "md" | "lg";
type ButtonVariant = "primary" | "secondary" | "plain" | "darkSecondary";
type ButtonType = "button" | "submit";
type ButtonIconPlacement = "before" | "after" | "above";
type BackgroundType = "light" | "dark";
type ClassList<T extends string> = { [k in T]: string };

const GENERIC_CLASSES =
  "w-full inline-flex justify-center items-center px-4 py-2 font-medium border rounded-md focus:outline-none focus:ring-2 focus:border-transparent h-full select-none";
const SPECIFIC_CLASSES: ClassList<
  ButtonSize | ButtonVariant | ButtonIconPlacement | BackgroundType | "disabled"
> = {
  md: "text-sm",
  lg: "text-base",
  primary:
    "text-white border-transparent bg-ni2-orange-500 hover:bg-ni2-orange-600 focus:ring-ni2-orange-400",
  secondary:
    "text-white border-transparent bg-ni-blue-600 hover:bg-ni-blue-700 focus:ring-ni-blue-500",
  darkSecondary:
    "text-white border-transparent bg-ni-blue-700 hover:bg-ni-blue-800 focus:ring-ni-blue-600",
  plain:
    "text-gray-700 bg-white border-gray-300 hover:bg-gray-50 focus:ring-ni2-orange-400",
  before: "flex-row space-x-2",
  after: "flex-row-reverse",
  above: "flex-col space-y-2",
  disabled: "opacity-50 cursor-not-allowed",
  light: "shadow-sm",
  dark: "",
};

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
})
export class ButtonComponent implements OnInit {
  @Input() title = "";
  @Input() tooltip = "";
  @Input() size: ButtonSize = "md";
  @Input() type: ButtonType = "button";
  @Input() variant: ButtonVariant = "primary";
  @Input() icon: ButtonIconPlacement = "before";
  @Input() iconName = "";
  @Input() background: BackgroundType = "light";
  @Input() disabled = false;
  @Input() status: "enabled" | "disabled" | null = null;

  get buttonCssClasses() {
    return [
      this.size,
      this.variant,
      this.icon,
      this.background,
      this.disabled && "disabled",
    ].reduce(
      (acc, cur) =>
        cur in SPECIFIC_CLASSES ? `${acc} ${SPECIFIC_CLASSES[cur]}` : acc,
      GENERIC_CLASSES
    );
  }

  constructor() {}

  handleClick(event: MouseEvent) {
    if (this.disabled) event.stopImmediatePropagation();
  }

  ngOnInit(): void {}
}
