import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "@reducers/.";
import { selectActiveFlowSiteId } from "@selectors/flow.selectors";

@Component({
  selector: "app-field-install-prompt",
  templateUrl: "./field-install-prompt.component.html",
  styleUrls: ["./field-install-prompt.component.scss"],
})
export class FieldInstallPromptComponent implements OnInit {
  siteId$ = this.store.select(selectActiveFlowSiteId);

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {}
}
