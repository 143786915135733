import { ErrorHandler } from "@angular/core";
import { BugsnagErrorHandler } from "@bugsnag/plugin-angular";
import { environment } from "src/environments/environment";

export function errorHandlerFactory() {
  if (!environment.offlineMode) {
    return new BugsnagErrorHandler();
  } else {
    return new BasicErrorHandler();
  }
}

class BasicErrorHandler implements ErrorHandler {
  handleError(error) {
    console.error(error);
  }
}
