import * as AdminActions from "@actions/admin.actions";
import { createReducer, on } from "@ngrx/store";

export type State = {
  companies: {
    loading: boolean;
    error: string | null;
    paginatedRows: any[];
    totalResults: number;
    fromPosition: number;
    toPosition: number;
    searchQuery: string;
    offset: number;
  };
  users: {
    loading: boolean;
    error: string | null;
    paginatedRows: any[];
    totalResults: number;
    fromPosition: number;
    toPosition: number;
    searchQuery: string;
    offset: number;
  };
  company: {
    loading: boolean;
    error: string | null;
    data: any;
  };
  user: {
    loading: boolean;
    error: string | null;
    data: any;
  };
  guide: {
    loading: boolean;
    error: string | null;
    data: any;
  };
  site: {
    loading: boolean;
    error: string | null;
    data: any;
  };
};

export const initialState: State = {
  companies: {
    searchQuery: "",
    offset: 0,
    loading: true,
    error: null,
    paginatedRows: [],
    totalResults: 0,
    fromPosition: 0,
    toPosition: 0,
  },
  users: {
    searchQuery: "",
    offset: 0,
    loading: true,
    error: null,
    paginatedRows: [],
    totalResults: 0,
    fromPosition: 0,
    toPosition: 0,
  },
  company: {
    loading: true,
    error: null,
    data: null,
  },
  user: {
    loading: true,
    error: null,
    data: null,
  },
  guide: {
    loading: true,
    error: null,
    data: null,
  },
  site: {
    loading: true,
    error: null,
    data: null,
  },
};

const _authReducer = createReducer(
  initialState,
  on(AdminActions.loadCompanies, (state, { searchQuery, offset }): State => {
    return {
      ...state,
      companies: {
        ...state.companies,
        searchQuery: searchQuery ?? state.companies.searchQuery,
        offset: offset ?? state.companies.offset,
      },
    };
  }),
  on(AdminActions.loadUsers, (state, { searchQuery, offset }): State => {
    return {
      ...state,
      users: {
        ...state.users,
        searchQuery: searchQuery ?? state.users.searchQuery,
        offset: offset ?? state.users.offset,
      },
    };
  }),
  on(
    AdminActions.loadCompaniesSuccess,
    (
      state,
      { paginatedRows, totalResults, fromPosition, toPosition }
    ): State => {
      return {
        ...state,
        companies: {
          ...state.companies,
          loading: false,
          error: null,
          paginatedRows,
          totalResults,
          fromPosition,
          toPosition,
        },
      };
    }
  ),
  on(
    AdminActions.loadUsersSuccess,
    (
      state,
      { paginatedRows, totalResults, fromPosition, toPosition }
    ): State => {
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          error: null,
          paginatedRows,
          totalResults,
          fromPosition,
          toPosition,
        },
      };
    }
  ),
  on(AdminActions.loadCompanySuccess, (state, { data }): State => {
    return {
      ...state,
      company: {
        loading: false,
        error: null,
        data: data,
      },
    };
  }),
  on(AdminActions.loadUserSuccess, (state, { data }): State => {
    return {
      ...state,
      user: {
        loading: false,
        error: null,
        data: data,
      },
    };
  }),
  on(AdminActions.loadGuideSuccess, (state, { data }): State => {
    return {
      ...state,
      guide: {
        loading: false,
        error: null,
        data: data,
      },
    };
  }),
  on(AdminActions.loadSiteSuccess, (state, { data }): State => {
    return {
      ...state,
      site: {
        loading: false,
        error: null,
        data: data,
      },
    };
  }),
  on(AdminActions.loadSiteSuccess, (state, { data }): State => {
    return {
      ...state,
      site: {
        loading: false,
        error: null,
        data: data,
      },
    };
  }),
  on(AdminActions.loadSiteSuccess, (state, { data }): State => {
    return {
      ...state,
      site: {
        loading: false,
        error: null,
        data: data,
      },
    };
  })
);

export function reducer(state: any, action: any) {
  return _authReducer(state, action);
}
