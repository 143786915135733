import { InjectionToken } from "@angular/core";
import { routerReducer, RouterReducerState } from "@ngrx/router-store";
import {
  Action,
  ActionReducer,
  ActionReducerMap,
  MetaReducer,
} from "@ngrx/store";
import { environment } from "src/environments/environment";
import { eventsMetaReducer } from "../events/reducer";
import * as fromAdmin from "./admin.reducer";
import * as fromAuth from "./auth.reducer";
import * as fromCollection from "./collection.reducer";
import * as fromCollections from "./collections.reducer";
import * as fromConfiguration from "./configuration.reducer";
import * as fromExtension from "./extension.reducers";
import * as fromFlags from "./flags.reducer";
import * as fromFlow from "./flow.reducer";
import * as fromFlows from "./flows.reducer";
import * as fromSettings from "./settings.reducer";
import * as fromSites from "./sites.reducer";

export interface AppState {
  flows: fromFlows.State;
  sites: fromSites.State;
  flow: fromFlow.State;
  auth: fromAuth.State;
  router: RouterReducerState;
  settings: fromSettings.State;
  extension: fromExtension.State;
  collection: fromCollection.State;
  collections: fromCollections.State;
  flags: fromFlags.State;
  configuration: fromConfiguration.State;
  admin: fromAdmin.State;
}

export const ROOT_REDUCERS = new InjectionToken<
  ActionReducerMap<AppState, Action>
>("Root reducers token", {
  factory: () => ({
    router: routerReducer,
    flow: fromFlow.reducer,
    flows: fromFlows.reducer,
    sites: fromSites.reducer,
    auth: fromAuth.reducer,
    settings: fromSettings.reducer,
    extension: fromExtension.reducer,
    collection: fromCollection.reducer,
    collections: fromCollections.reducer,
    flags: fromFlags.reducer,
    configuration: fromConfiguration.reducer,
    admin: fromAdmin.reducer,
  }),
});

export function logger(
  reducer: ActionReducer<AppState>
): ActionReducer<AppState> {
  return (state, action) => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log("prev state", state);
    console.log("action", action);
    console.log("next state", result);
    console.groupEnd();

    return result;
  };
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? [eventsMetaReducer, logger]
  : [eventsMetaReducer];
