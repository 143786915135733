import {
  abandonFlow,
  saveFlow,
  setBasicAuthCredentials,
  updateEditorPosition,
} from "@actions/editor.actions";
import { flowLoadedSuccess } from "@actions/flows-api.actions";
import { launcherEvent } from "@actions/launchers.actions";
import { portalPageChanged } from "@actions/portal.actions";
import { finishSiteWizard } from "@actions/wizard.actions";
import { trackEvent } from "@redux-beacon/segment";
import { EventsMap } from "redux-beacon";
import { AppState } from "../reducers";

export const EVENTS_MAP: EventsMap = {
  [flowLoadedSuccess.type]: trackEvent((action) => ({
    name: "editor_open",
    properties: {
      new: action.flow?.id === null,
      template: action.flow?.$templateId,
    },
  })),
  [updateEditorPosition.type]: trackEvent(({ path }) => ({
    name: "editor_change_panel",
    properies: { path },
  })),
  [setBasicAuthCredentials.type]: trackEvent(() => ({
    name: "editor_set_credentials",
  })),
  [saveFlow.type]: trackEvent((_, state: AppState) => ({
    name: "editor_save_flow",
    properties: {
      new: state.flow.data?.id === null,
      enabled: state.flow.data?.isPublished,
    },
  })),
  [abandonFlow.type]: trackEvent((_, state: AppState) => ({
    name: "editor_abandon_flow",
    properties: { new: state.flow.data?.id === null },
  })),
  [portalPageChanged.type]: trackEvent(() => ({
    name: "editor_portal_page_changed",
  })),
  [finishSiteWizard.type]: trackEvent(() => ({
    name: "site_wizard_finish",
  })),
  [launcherEvent.type]: trackEvent(({ event, flowId, stepId, data }) => ({
    name: "launcher_event",
    properties: { event, flowId, stepId, data },
  })),
};
