export const environment = {
  production: true,
  offlineMode: false,
  apiHost: "https://api.nickelled-staging.com",
  frontHost: "https://front.nickelled-staging.com",
  notificationServiceHost: "https://notify.nickelled-staging.com",
  makeHost: "https://make2.nickelled-staging.com",
  segmentAPIKey: "Ks9cTns9A8LNVtbPif3ylKd84zhqT1Va",
  intercomAppId: "bkicoury",
  bugsnagAPIKey: "c419537c9bafac98f14a589305210e82",
  stripe: {
    publishableKey: "pk_test_TMtRoKb3cKLDy3wJaEeBhZoC",
    monthlyPlan: "v6_useronboarding_monthly_usd",
    annualPlan: "v6_useronboarding_annual_usd"
  },
  portal: {
    launchersJs:
      "https://s3-eu-west-1.amazonaws.com/cdn.nickelled-staging.com/launchers-2.min.js",
    appId: "dummy",
  },
  launchers: {
    js: "https://cdn.nickelled.com/launchers-2.min.js",
    appId: "nickelled-test-sf.com-306456",
    apiUrl: "https://api.nickelled-staging.com",
    trackingUrl: "https://tracking.nickelled-staging.com/tracking",
  },
  extensionId: "cdpclbklgmofgohfeabojcokbmeophlh",
  launchDarklyClientId: "6203b58bb7700a159747c5a6",
};
