import { Injectable, NgZone } from "@angular/core";
import { interval, Observable } from "rxjs";
import { concatMap, map, take } from "rxjs/operators";
import { environment } from "src/environments/environment";

const EXTENSION_ID = environment.extensionId;

declare var chrome: any;

@Injectable({
  providedIn: "root",
})
export class ExtensionBusService {
  constructor(private zone: NgZone) {}

  ready$ = interval(3000).pipe(
    concatMap(() => this.sendMessage("getStatus")),
    map(() => true),
    take(1)
  );

  getStatus(): Observable<{
    success: boolean;
    version: string;
  }> {
    return this.sendMessage("getStatus");
  }

  forceSync(): Observable<void> {
    return this.sendMessage("forceSync");
  }

  launchGuide(opts: { guideId: number; edit: boolean }) {
    return this.sendMessage("launchGuide", opts);
  }

  private sendMessage(message: string, payload: any = {}): Observable<any> {
    return Observable.create((observer: any) => {
      if (!chrome?.runtime?.sendMessage) {
        return;
      }

      chrome.runtime.sendMessage(
        EXTENSION_ID,
        { message, payload },
        (reply: any) => {
          this.zone.run(() => observer.next(reply));
        }
      );
    });
  }
}
