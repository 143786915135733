import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpService } from "./http.service";

@Injectable({
  providedIn: "root",
})
export class AttributeService {
  constructor(private httpService: HttpService) {}

  list(siteId: number) {
    const url = `${environment.apiHost}/attributes?siteId=${siteId}`;
    return this.httpService.get(url, { withCredentials: true });
  }
}
