<div class="relative h-full select-none">
  <div #trigger (click)="toggle()" class="h-full">
    <ng-content select="[trigger]"></ng-content>
  </div>
  <div
    *ngIf="expanded"
    [@openClose]
    class="absolute z-50 w-56 mt-2 overflow-hidden bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
    [ngClass]="{
      'left-0 origin-top-left': expandDirection === 'right',
      'right-0 origin-top-right': expandDirection === 'left'
    }"
    role="menu"
  >
    <ng-content select="[menu]"></ng-content>
  </div>
</div>
