import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";

export const selectAuth = (state: AppState) => state.auth;

export const selectActiveUser = createSelector(
  selectAuth,
  (state) => state.data
);

export const selectIsSuperUser = createSelector(
  selectAuth,
  (state) => !!state.data?.isSuperUser
);

export const selectExtensionId = createSelector(
  selectActiveUser,
  (state) => state?.Company?.extensionId
);

export const selectUserHasRole = (roleNames: string[]) => {
  return (state: AppState) => {
    return state.auth.data.UserRoles.some(
      (role) => roleNames.indexOf(role.roleName) !== -1
    );
  };
};
