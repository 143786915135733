import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { set } from "object-path-immutable";
import { BehaviorSubject, Subject } from "rxjs";
import { concatMap, filter, withLatestFrom } from "rxjs/operators";
import { TemplateService } from "../../service/template.service";

@Component({
  selector: "app-field-array",
  templateUrl: "./field-array.component.html",
  styleUrls: ["./field-array.component.scss"],
})
export class FieldArrayComponent implements OnInit {
  @Input() value = [];
  @Input() templates: any[] = [];
  @Input() maxItems = 100;
  @Input() help = "Add a step...";
  @Input() path = "";
  @Output() update = new EventEmitter<any[]>();
  @Output() action = new EventEmitter<{ type: string; key: string }>();

  expanded: number | null = null;

  drop$ = new Subject<[number, number]>();
  value$ = new Subject<any[]>();
  list$ = new BehaviorSubject<any[]>([]);

  updateList$ = this.value$
    .pipe(concatMap((items: any[]) => this.templateService.runAll(items ?? [])))
    .subscribe((list: any[]) => {
      this.list$.next(list);
    });

  fireDroppedEvent$ = this.drop$
    .pipe(
      withLatestFrom(this.list$),
      filter(([[fromIndex, toIndex]]) => fromIndex !== toIndex)
    )
    .subscribe(([[fromIndex, toIndex], list]: [[number, number], any[]]) => {
      const indexOfLastFixedItem = list
        .map((item) => item.isFixedStep)
        .lastIndexOf(true);

      if (
        fromIndex <= indexOfLastFixedItem ||
        toIndex <= indexOfLastFixedItem
      ) {
        alert(
          `The first ${indexOfLastFixedItem + 1} item(s) can not be moved.`
        );
      } else if (Array.isArray(this.value)) {
        const array = [...this.value];
        moveItemInArray(array, fromIndex, toIndex);
        this.update.emit(array);
      }
    });

  constructor(private templateService: TemplateService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["value"]) {
      this.value$.next(changes["value"].currentValue);
    }
  }

  getValue(index: number, key: string) {
    return this.value?.[index]?.[key];
  }

  handleUpdate(index: number, key: string, value: any) {
    const changedValue = [...this.value] ?? [];
    if (index < changedValue.length) {
      changedValue[index] = set(changedValue[index], key, value);
    }
    this.update.emit(changedValue);
  }

  handleClick(index: number) {
    this.expanded = this.expanded === index ? null : index;
  }

  handleDrop(event: CdkDragDrop<string[]>) {
    this.drop$.next([event.previousIndex, event.currentIndex]);
  }

  handleAdd(templateId: string) {
    const value = this.value ?? [];
    this.update.emit([...value, { $templateId: templateId }]);
  }

  handleRemove(index: number) {
    this.expanded = null;
    const changedValue = this.value ?? [];
    if (index < changedValue.length) changedValue.splice(index, 1);
    this.update.emit(changedValue);
  }

  trackByKey(idx, item) {
    return item.key;
  }

  trackByIndex(idx) {
    return idx;
  }
}
