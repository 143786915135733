import * as ConfigurationActions from "@actions/configuration.actions";
import { createReducer, on } from "@ngrx/store";

export type State = {
  themes: {
    data: any[];
    loading: boolean;
    error: Error | null;
  };
};

export const initialState: State = {
  themes: {
    data: [],
    loading: true,
    error: null,
  },
};

const _configurationReducer = createReducer(
  initialState,
  on(
    ConfigurationActions.loadThemesSuccess,
    (state, { data }): State => ({
      ...state,
      themes: {
        data,
        loading: false,
        error: null,
      },
    })
  )
);

export function reducer(state: any, action: any) {
  return _configurationReducer(state, action);
}
