import { userLoadedSuccess } from "@actions/auth-api.actions";
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { AppState } from "../../store/reducers";
import { HttpService } from "./http.service";
import { User } from "./user";

@Injectable({
  providedIn: "root",
})
export class UserService {
  public user: User | null = null;
  public user$ = new BehaviorSubject<User | null>(null);

  constructor(
    private httpService: HttpService,
    private store: Store<AppState>,
    private router: Router
  ) {}

  logIn(email: string, password: string): Observable<User> {
    const authenticateUrl = `${environment.apiHost}/authenticate`;

    return this.httpService
      .post(authenticateUrl, { email, password }, { withCredentials: true })
      .pipe(
        map(
          (res) => {
            this.httpService.authFail = false;
            return res;
          },
          (err: any) => {
            this.httpService.authFail = true;
          }
        )
      );
  }

  getUser(): Observable<User> {
    const authenticateUrl = `${environment.apiHost}/authenticate`;
    return this.httpService
      .get(authenticateUrl, { withCredentials: true })
      .pipe(
        map(
          (res) => {
            this.user = res;

            this.httpService.authFail = false;
            return res;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  setUser(user: User) {
    this.user = user;
    this.user$.next(user);
    this.store.dispatch(userLoadedSuccess({ data: user }));
  }

  setToTemporarilyPaid() {
    // I needed to deep copy the user object to avoid issues updating the referenced Company object.
    const updateUser = JSON.parse(JSON.stringify(this.user));

    updateUser.Company.trial = false;
    updateUser.Company.churnedDate = null;
    updateUser.Company.paymentDue = false;

    this.setUser(updateUser);
  }

  setUserAndRedirect(user: User) {
    this.setUser(user);
    const postLoginRedirectUrl = sessionStorage.getItem("postLoginRedirectUrl");
    if (postLoginRedirectUrl) {
      sessionStorage.setItem("postLoginRedirectUrl", null);
      this.router.navigateByUrl(postLoginRedirectUrl);
    }
  }

  getSsoConnectionIdForDomain(domain: string): Observable<string | null> {
    const url = `${environment.apiHost}/sso-auth/check`;
    return this.httpService
      .post(url, { domain }, { withCredentials: true })
      .pipe(map((res) => res.connectionId ?? null));
  }

  getDomainFromEmail(email: string) {
    const parts = email.split("@");
    if (parts.length !== 2) return "";
    return parts[1];
  }

  getSsoAuthorizationURLForConnectionId(
    connectionId: string
  ): Observable<string | null> {
    const url = `${environment.apiHost}/sso-auth/init`;
    return this.httpService
      .post(url, { connectionId }, { withCredentials: true })
      .pipe(map((res) => res.authorizationURL ?? null));
  }

  public getByUseID(userId: number): Observable<User> {
    const getUserUrl = `${environment.apiHost}/users/${userId}.json`;
    return this.httpService.get(getUserUrl, { withCredentials: true }).pipe(
      map((res) => {
        return res;
      })
    );
  }

  public getOrgCompany(orgId: number): Observable<any> {
    const url = `${environment.apiHost}/organisations/${orgId}/companies`;

    return this.httpService.get(url, { withCredentials: true }).pipe(
      map((res) => {
        return res;
      })
    );
  }

  register(accountDetails: any): Observable<any> {
    const registerUrl = `${environment.apiHost}/register`;
    return this.httpService
      .post(registerUrl, accountDetails, { withCredentials: true })
      .pipe(
        map((res: Response) => {
          return res;
        }),
        catchError((errors: Response) => {
          return of(errors);
        })
      );
  }

  public sendResetLink(credentials: any): Observable<any> {
    const resetUrl = environment.apiHost + `/password_reset`;
    return this.httpService
      .post(resetUrl, credentials, { withCredentials: true })
      .pipe(
        map((res: Response) => {
          return res;
        }),
        catchError((errors: Response) => {
          return of(errors);
        })
      );
  }

  public updatePassword(newCredentials: any, token: string): Observable<any> {
    const resetUrl = environment.apiHost + `/password_reset/${token}`;
    return this.httpService
      .post(resetUrl, newCredentials, { withCredentials: true })
      .pipe(
        map((res: Response) => {
          return res;
        }),
        catchError((errors: Response) => {
          return of(errors);
        })
      );
  }

  public cancelEmailSubscription(
    subscription: string,
    email: string,
    token: string
  ): Observable<boolean> {
    const encodedSubscription = encodeURIComponent(subscription);
    const unsubscribeUrl =
      environment.apiHost + `/emails/${encodedSubscription}/unsubscribe`;

    let params = new HttpParams().set("email", email).set("token", token);

    return this.httpService
      .get(unsubscribeUrl, {
        observe: "response",
        responseType: "text",
        params,
      })
      .pipe(
        map((res: Response) => {
          return res.status === 200;
        }),
        catchError((errors: Response) => {
          return of(false);
        })
      );
  }

  updateUser(user: any) {
    const body = JSON.stringify(user);

    return this.httpService.post(`/users/${this.user.id}.json`, body, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      withCredentials: true,
    });
  }

  updateCardDetails(token: any) {
    const subscription = {
      stripeToken: token.id,
      stripeEmail: token.email,
    };
    const body = JSON.stringify(subscription);

    return this.httpService.post(`/update-card`, body, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      withCredentials: true,
    });
    // .subscribe(
    //   (card) => {
    //     this.paymentFailure = false;
    //     this.ccExpiry = new Date(card.ccExpiryYear, card.ccExpiryMonth - 1);
    //     this.ccLast4 = card.ccLast4;

    //     this.flashSevie.set(
    //       'Your card was updated, thanks for keeping it up-to-date!'
    //     );
    //     this.loading = false;
    //   },
    //   (err) => {
    //     this.errorMessage = err;
    //     this.paymentFailure = true;
    //     this.loading = false;
    //   }
    // );
  }

  setRoles(id: number, roleNames: string[]) {
    const body = JSON.stringify({ roleNames });

    return this.httpService.put(`/users/${id}/roles`, body, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      withCredentials: true,
    });
  }

  logOut() {
    return this.httpService
      .get("/logout", { responseType: "Text", withCredentials: true })
      .pipe(
        map((res) => {
          this.user = undefined;
        })
      );
  }
}
