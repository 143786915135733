<div
  class="flex rounded-md"
  [class.shadow-sm]="background === 'light'"
  [class.border]="background === 'light'"
  [class.border-gray-300]="background === 'light'"
  [class.bg-gray-300]="disabled"
  [class.bg-white]="!disabled"
>
  <div class="flex-grow w-full" #menu>
    <div class="relative">
      <button
        type="button"
        class="relative w-full h-10 py-2 pl-3 pr-10 text-left cursor-default focus:outline-none focus:ring-2 focus:ring-ni2-orange-400 focus:border-ni2-orange-400 sm:text-sm"
        [class]="{ 'rounded-l-md': buttonIcon, 'rounded-md': !buttonIcon }"
        aria-haspopup="listbox"
        aria-expanded="true"
        aria-labelledby="listbox-label"
        (click)="toggleExpanded()"
      >
        <span class="flex items-center">
          <span
            class="grid flex-shrink-0 text-gray-400 place-items-center"
            *ngIf="selectedIcon"
          >
            <hero-icon [name]="selectedIcon" size="1.2rem"></hero-icon>
          </span>
          <span
            class="block ml-3 truncate"
            [class.text-gray-500]="selectedItem === null"
          >
            {{ selectedItem?.title || placeholder }}
          </span>
        </span>
        <span
          class="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none"
        >
          <svg
            class="w-5 h-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
      </button>
      <ul
        *ngIf="expanded"
        [@openClose]
        class="absolute z-10 w-full py-1 mt-1 overflow-auto text-base origin-top bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        tabindex="-1"
        role="listbox"
        aria-labelledby="listbox-label"
        aria-activedescendant="listbox-option-3"
      >
        <li
          *ngFor="let item of items"
          (click)="selectItem(item)"
          class="relative py-2 pl-3 text-gray-900 cursor-pointer select-none pr-9 hover:bg-yellow-50"
          id="listbox-option-0"
          role="option"
        >
          <div class="flex items-center">
            <span class="grid flex-shrink-0 text-gray-400 place-items-center">
              <hero-icon
                *ngIf="item?.icon"
                [name]="item.icon"
                size="1.2rem"
              ></hero-icon>
            </span>
            <span class="block ml-3 truncate">
              {{ item?.title }}
            </span>
          </div>

          <span
            class="absolute inset-y-0 right-0 flex items-center pr-4 text-ni2-orange-500"
            [class.hidden]="!isSelected(item)"
          >
            <svg
              class="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
        </li>
      </ul>
    </div>
  </div>
  <button
    type="button"
    *ngIf="buttonIcon"
    (click)="handleButtonClick()"
    class="relative inline-flex items-center px-4 py-2 -ml-px space-x-3 text-sm font-medium text-white rounded-r-md bg-ni2-orange-500 hover:bg-ni2-orange-600 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ni2-orange-400 focus:border-ni2-orange-400"
  >
    <hero-icon [name]="buttonIcon" size="1.2rem"></hero-icon>
  </button>
</div>
