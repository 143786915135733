<ng-container *ngIf="def?.type">
  <div class="mb-2" *ngIf="def.label">
    <label class="block text-sm font-semibold text-ni-blue-600">{{
      def.label
    }}</label>
  </div>
  <div class="mb-5" [ngSwitch]="def.type">
    <app-field-page-selector
      *ngSwitchCase="'page-url'"
      [help]="def.help || ''"
      [value]="value"
      (update)="handleUpdate($event)"
    >
    </app-field-page-selector>
    <app-field-child-items
      *ngSwitchCase="'child-items'"
      [value]="value"
      [templates]="def.templates || []"
      [help]="def.help"
      (update)="handleUpdate($event)"
      (action)="handleAction($event)"
    >
    </app-field-child-items>
    <app-field-array
      *ngSwitchCase="'array'"
      [path]="fullPath"
      [value]="value"
      [templates]="def.templates || []"
      [help]="def.help"
      (update)="handleUpdate($event)"
      (action)="handleAction($event)"
    >
    </app-field-array>
    <app-field-list
      *ngSwitchCase="'list'"
      [value]="value"
      [help]="def.help"
      (update)="handleUpdate($event)"
      (action)="handleAction($event)"
    >
    </app-field-list>
    <app-field-boolean
      *ngSwitchCase="'boolean'"
      [value]="value"
      (update)="handleUpdate($event)"
    >
    </app-field-boolean>
    <app-field-input
      *ngSwitchCase="'text'"
      [value]="value"
      [help]="def.help"
      [placeholder]="def.placeholder"
      [helpLink]="def.helpLink"
      (update)="handleUpdate($event)"
    >
    </app-field-input>
    <app-field-input
      *ngSwitchCase="'number'"
      inputType="number"
      [value]="value"
      [help]="def.help"
      (update)="handleUpdate($event)"
    >
    </app-field-input>
    <app-field-color
      *ngSwitchCase="'color'"
      [value]="value"
      (update)="handleUpdate($event)"
    >
    </app-field-color>
    <app-field-target
      *ngSwitchCase="'target'"
      [value]="value"
      (update)="handleUpdate($event)"
    >
    </app-field-target>
    <app-field-target-v2
      *ngSwitchCase="'target-v2'"
      [value]="value"
      (update)="handleUpdate($event)"
    >
    </app-field-target-v2>
    <app-field-zoom
      *ngSwitchCase="'zoom'"
      [value]="value"
      (update)="handleUpdate($event)"
    ></app-field-zoom>
    <app-field-info
      *ngSwitchCase="'info'"
      [message]="def.help"
    ></app-field-info>
    <app-field-enum
      *ngSwitchCase="'enum'"
      [options]="def.options"
      [value]="value || def.default"
      (update)="handleUpdate($event)"
    >
    </app-field-enum>
    <app-field-html
      *ngSwitchCase="'html'"
      [value]="value"
      (update)="handleUpdate($event)"
    ></app-field-html>
    <app-field-criteria-builder
      *ngSwitchCase="'criteria'"
      [value]="value"
      (update)="handleUpdate($event)"
    >
    </app-field-criteria-builder>
    <app-field-textarea
      *ngSwitchCase="'textarea'"
      (update)="handleUpdate($event)"
    ></app-field-textarea>
    <app-field-relative-position
      *ngSwitchCase="'relative-position'"
      [value]="value"
      (update)="handleUpdate($event)"
    >
    </app-field-relative-position>
    <app-field-resource-select
      *ngSwitchCase="'flow-select'"
      resourceType="guides"
      placeholder="Select a guide"
      [value]="value"
      (update)="handleUpdate($event)"
    >
    </app-field-resource-select>
    <app-field-resource-select
      *ngSwitchCase="'theme-select'"
      resourceType="theme"
      placeholder="Select a theme"
      [value]="value"
      (update)="handleUpdate($event)"
    >
    </app-field-resource-select>
    <app-field-gallery
      *ngSwitchCase="'gallery'"
      [value]="value"
      (update)="handleUpdate($event)"
      [options]="def.options"
      [cols]="1"
      [name]="def.key"
    >
    </app-field-gallery>
    <app-field-site-select
      *ngSwitchCase="'site-select'"
      [value]="value"
      (update)="handleUpdate($event)"
    >
    </app-field-site-select>
    <app-field-tags
      *ngSwitchCase="'tags'"
      [value]="value"
      (update)="handleUpdate($event)"
    >
    </app-field-tags>
    <app-field-install-prompt *ngSwitchCase="'install-prompt'">
    </app-field-install-prompt>
  </div>
</ng-container>
