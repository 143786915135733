import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpService } from "./http.service";

type ThemeItem = {
  id: string;
  name: string;
  system: boolean;
  imageUrl: string;
};

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  constructor(private httpService: HttpService) {}

  list(): Observable<ThemeItem[]> {
    const url = `${environment.apiHost}/v1/theme`;
    return this.httpService.get(url, { withCredentials: true });
  }
}
