import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-field-color",
  templateUrl: "./field-color.component.html",
  styleUrls: ["./field-color.component.scss"],
})
export class FieldColorComponent implements OnInit, OnChanges {
  @Input() value = "";
  @Input() inputType = "text";
  @Input() placeholder = "";
  @Input() valid = true;
  @Output() update = new EventEmitter<string>();
  control = new FormControl(this.value);

  emitUpdates$ = this.control.valueChanges.subscribe((value) =>
    this.update.emit(value)
  );

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.control && changes["value"]) {
      this.control.setValue(changes["value"].currentValue, {
        emitEvent: false,
      });
    }
  }
}
