<app-accordion>
  <app-accordion-group
    *ngFor="let group of groups$ | async; let first = first"
    [label]="group.label"
    [opened]="first"
  >
    <div
      *ngFor="let def of group.controls"
      [class.col-span-2]="def.colSpan !== 1"
    >
      <app-schema-field
        [def]="def"
        [value]="getValue$(def.key) | async"
        (update)="handleUpdate(def.key, $event)"
        (action)="handleAction(def.key, $event)"
      >
      </app-schema-field>
    </div>
  </app-accordion-group>
</app-accordion>
