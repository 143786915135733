import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-field-relative-position",
  templateUrl: "./field-relative-position.component.html",
  styleUrls: ["./field-relative-position.component.scss"],
})
export class FieldRelativePositionComponent implements OnInit {
  @Input() value = "right";
  @Output() update = new EventEmitter<string>();
  control = new FormControl(this.value);

  get auto() {
    return !this.value || this.value === "auto";
  }

  emitUpdates$ = this.control.valueChanges.subscribe((value) =>
    this.update.emit(value)
  );

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.control && changes["value"]) {
      this.control.setValue(changes["value"].currentValue, {
        emitEvent: false,
      });
    }
  }

  toggleAuto() {
    const newValue = this.auto ? "right" : "auto";
    this.control.setValue(newValue);
  }
}
