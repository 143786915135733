import { Injectable } from "@angular/core";
import { forkJoin, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "src/app/shared/service/http.service";

@Injectable({
  providedIn: "root",
})
export class TemplateService {
  constructor(private httpService: HttpService) {}

  get(id: string) {
    return this.httpService.get(`/templates/${id}`, {
      withCredentials: true,
    });
  }

  run(data: any): Observable<{ preview: any; summary: string }> {
    const id = data.$templateId;

    return this.httpService
      .post(`/templates/${id}`, data, {
        withCredentials: true,
      })
      .pipe(map((output) => ({ ...output, data })));
  }

  runAll(data: any[]) {
    if (data.length === 0) {
      return of([]);
    }

    const requests = data.map((data) => this.run(data));
    return forkJoin(requests);
  }
}
