import { createAction, props } from "@ngrx/store";

export const loadCompanies = createAction(
  "[Admin] Load Companies",
  props<{ searchQuery: string; offset: number }>()
);

export const loadCompaniesSuccess = createAction(
  "[Admin API] Company List Loaded Success",
  props<{
    paginatedRows: any[];
    totalResults: number;
    fromPosition: number;
    toPosition: number;
  }>()
);

export const loadCompaniesFailure = createAction(
  "[Admin API] Company List Loaded Failure"
);

export const loadUsers = createAction(
  "[Admin] Load Users",
  props<{ searchQuery: string; offset: number }>()
);

export const loadUsersSuccess = createAction(
  "[Admin API] User List Loaded Success",
  props<{
    paginatedRows: any[];
    totalResults: number;
    fromPosition: number;
    toPosition: number;
  }>()
);

export const loadUsersFailure = createAction(
  "[Admin API] User List Loaded Failure"
);

export const loadCompany = createAction(
  "[Admin] Load Company",
  props<{ companyId: number }>()
);

export const saveCompany = createAction(
  "[Admin] Save Company",
  props<{ companyId: number; data: any }>()
);

export const loadUser = createAction(
  "[Admin] Load User",
  props<{ userId: number }>()
);

export const saveUser = createAction(
  "[Admin] Save User",
  props<{ userId: number; data: any }>()
);

export const loadGuide = createAction(
  "[Admin] Load Guide",
  props<{ guideId: number }>()
);

export const saveGuide = createAction(
  "[Admin] Save Guide",
  props<{ guideId: number; data: any }>()
);

export const loadSite = createAction(
  "[Admin] Load Site",
  props<{ siteId: number }>()
);

export const saveSite = createAction(
  "[Admin] Save Site",
  props<{ siteId: number; data: any }>()
);

export const impersonateCompany = createAction(
  "[Admin] Impersonate Company",
  props<{ companyId: number }>()
);

export const cancelSubscription = createAction(
  "[Admin] Cancel Subscription",
  props<{ companyId: number }>()
);

export const impersonateCompanySuccess = createAction(
  "[Admin API] Impersonate Company Success"
);

export const impersonateCompanyFailure = createAction(
  "[Admin API] Impersonate Company Failure"
);

export const cancelSubscriptionSuccess = createAction(
  "[Admin API] Cancel Subscription Success"
);

export const cancelSubscriptionFailure = createAction(
  "[Admin API] Cancel Subscription Failure"
);

export const loadCompanySuccess = createAction(
  "[Admin API] Load Company Success",
  props<{ data: any }>()
);

export const loadCompanyFailure = createAction(
  "[Admin API] Load Company Failure"
);

export const loadUserSuccess = createAction(
  "[Admin API] Load User Success",
  props<{ data: any }>()
);

export const loadUserFailure = createAction("[Admin API] Load User Failure");

export const loadGuideSuccess = createAction(
  "[Admin API] Load Guide Success",
  props<{ data: any }>()
);

export const loadGuideFailure = createAction("[Admin API] Load Guide Failure");

export const loadSiteSuccess = createAction(
  "[Admin API] Load Site Success",
  props<{ data: any }>()
);

export const loadSiteFailure = createAction("[Admin API] Load Site Failure");

export const saveCompanySuccess = createAction(
  "[Admin] Save Company Success",
  props<{ companyId: number; data: any }>()
);

export const saveCompanyFailure = createAction("[Admin] Save Company Failure");

export const saveUserSuccess = createAction(
  "[Admin] Save User Success",
  props<{ userId: number; data: any }>()
);

export const saveUserFailure = createAction("[Admin] Save User Failure");

export const saveGuideSuccess = createAction(
  "[Admin] Save Guide Success",
  props<{ guideId: number; data: any }>()
);

export const saveGuideFailure = createAction("[Admin] Save Guide Failure");

export const saveSiteSuccess = createAction(
  "[Admin] Save Site Success",
  props<{ siteId: number; data: any }>()
);

export const saveSiteFailure = createAction("[Admin] Save Site Failure");
