import { createAction, props } from "@ngrx/store";
import { User } from "../../shared/models/user";

export const userLoadedSuccess = createAction(
  "[User API] User Loaded Success",
  props<{ data: User }>()
);

export const userLoadedFailure = createAction(
  "[User API] User Loaded Failure",
  props<{ error: any }>()
);

export const userLoggedOutSuccess = createAction(
  "[User API] User Logged Out Success"
);

export const userLoggedOutFailure = createAction(
  "[User API] User Logged Out Failure",
  props<{ error: any }>()
);
